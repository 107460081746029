import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

export enum Size {
    small = 'sm',
    normal = '',
    large = 'lg'
}

const noop = () => { };

@Component({
    selector: 'app-checkbox',
    templateUrl: 'checkbox.component.html',
    styleUrls: ['checkbox.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: CheckboxComponent, multi: true }
    ]
})
export class CheckboxComponent implements OnInit, ControlValueAccessor {
    @Input() size: Size = Size.normal;
    @Input() color = 'black';
    @Input() disabled = false;
    @Input() label;

    private _value = false;
    private _disabled = false;
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    get value(): any {
        return this._value;
    }

    set value(v: any) {
        if (v !== this._value) {
            this._value = v;
            this.onChangeCallback(v);
        }
    }

    constructor() {

    }

    ngOnInit() {

    }

    onBlur() {
        this.onTouchedCallback();
    }

    writeValue(value: boolean) {
        if (value !== this._value) {
            this._value = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    setDisabledState(isDisabled: boolean) {
        this._disabled = isDisabled;
    }
}
