import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { catchError, takeUntil, filter } from "rxjs/operators";
import {
    currentUserSubject,
    UserService,
} from "../common/services/user.service";

export const subjects = {
    PortAndCountrySubject: new BehaviorSubject<any[]>(null),
    PortAndCountryCountryOnlySubject: new BehaviorSubject<any[]>(null),
    PortSubject: new BehaviorSubject<any[]>(null),
};

@Injectable({
    providedIn: "root",
})
export class CommonService {
    public userSubject: any = {};
    private queriesTriggered$ = new Subject<boolean>();
    public cachedCodelists: Map<string, Observable<any>> = new Map<
        string,
        Observable<any>
    >();

    constructor(private http: HttpClient, private userService: UserService) {
        currentUserSubject
            .pipe(
                filter((user) => user != null),
                takeUntil(this.queriesTriggered$)
            )
            .subscribe((user) => {
                this.queriesTriggered$.next(true);
            });
    }

    public getDropdownOptions(entity: string, forTOSSystem?: string): Observable<any> {
        entity = this.toPascal(entity);

        if (this.cachedCodelists.has(entity)) {
            return this.cachedCodelists.get(entity)!;
        }

        let entityObservable: Observable<any>;

        if (entity === 'CustomPort') {
            entityObservable = this.http.post<any>('api:///codelist/GetCodeListOptions', {
                codelistName: 'Port',
                id: '',
                filterId: '',
                filterIds: ['BGBOJ', 'BGVAR'],
                filterName: ''
            });
        } else if (entity === 'TerminalOperator') {
            entityObservable = this.http.post<any>('api:///codelist/GetTerminalOperator', {});
        } else if (entity === 'OrganizationShippingLine') {
            entityObservable = this.http.post<any>('api:///codelist/GetShippingLineOptions', {});
        } else if (entity === 'Port') {
            entityObservable = this.http.post<any>('api:///codelist/GetCodeListOptions', {
                codelistName: 'Port',
                id: '',
                filterId: '',
                filterName: ''
            });
        } else if (entity === 'ShipmentPool') {
            entityObservable = this.http.post<any>('api:///codelist/GetShipmentPoolOptions', {});
        } else if (entity === 'OrganizationsForUserCreate') {
            entityObservable = this.http.post<any>('api:///codelist/GetOrganizationsForUserCreateOptions', {});
        } else if (entity === 'CodeListCargoTypePerSystemForManifest') {
            entityObservable = this.http.get<any>('api:///codelist/CodeListCargoTypePerSystemForManifest', {});
        } else if (entity === 'CodeListCargoPackagingTypePerSystemForManifest') {
            entityObservable = this.http.get<any>('api:///codelist/CodeListCargoPackagingTypePerSystemForManifest', {});
        } else if (entity === 'OrganizationsForDeliveryOrder') {
            entityObservable = this.http.get<any>('api:///codelist/GetOrganizationsForDeliveryOrder', {});
        } else if (entity === 'AdditionalAgentsForNomination') {
            entityObservable = this.http.get<any>('api:///codelist/GetAdditionalAgentsForNomination', {});
        } else if (entity === 'CodeListBGCustomsOffice') {
            entityObservable = this.http.get<any>('api:///codelist/CodeListBGCustomsOffice', {});
        } else if (entity === 'OrganizationsForTerminalOperator') {
            entityObservable = this.http.get<any>('api:///codelist/GetOrganizationsForTerminalOperator', {});
        } else if (entity === 'LBOOrganization') {
            entityObservable = this.http.get<any>('api:///codelist/GetLaunchBoatOperators', {});
        } else if (entity.endsWith('PerSystem')) {
            entityObservable = this.http.get<any>(`api:///codeList/${entity}/${forTOSSystem}`).pipe(
                catchError((err) => {
                    console.error(err);
                    return throwError(err);
                })
            );
        } else {
            entityObservable = this.http.post<any>('api:///codelist/GetCodeListOptions', {
                codelistName: entity,
                filterId: '',
                filterName: '',
                id: ''
            });
        }

        const cachedCodelistSubject = this.addToCache(entityObservable, entity);
        return cachedCodelistSubject;
    }

    addEmailSubscription(model: any): Observable<any> {
        return this.http.post<any>('api:///common/AddEmailSubscription', model);
    }

    private addToCache(entityObservable: Observable<any>, entity: string): Observable<any> {
        const newSubject = new BehaviorSubject<any>(null);

        entityObservable.subscribe((x) => {
            newSubject.next(x);
        });

        this.cachedCodelists.set(entity, newSubject);
        return newSubject;
    }

    private toPascal(entity: string): string {
        return entity.charAt(0).toUpperCase() + entity.substring(1);
    }
}
