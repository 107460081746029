import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { catchError, first } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from '@common/services/user.service';
import { isMobile } from '@common/utils/isMobile';
import { TranslateService } from '@ngx-translate/core';
import { requiredValidator } from '@common/validators/required-validator';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    userEmail: '';
    errors: any = [];
    isBusy = false;
    showBackgroundImage = false;
    isMobile = isMobile();

    form = new UntypedFormGroup({
        email: new UntypedFormControl({ value: null, disabled: false }, requiredValidator()),
    });

    constructor(
        private userService: UserService,
        private toastyService: ToastrService,
        private router: Router,
        public translateService: TranslateService
    ) {
        
    }

    ngOnInit() {
        this.getLoadedImage();
    }

    reset() {
        this.isBusy = true;

        const userData = {
            email: this.form.controls.email.value,
        };

        this.userService
            .forgotPassword(userData)
            .pipe(
                first(),
                catchError((err) => {
                    this.isBusy = false;
                    return throwError(err);
                })
            )
            .subscribe((data) => {
                this.isBusy = false;
                this.toastyService.success(this.translateService.instant(marker('Email with recovery link sent')));
                this.router.navigate(['/login']);
            });
    }

    private getLoadedImage() {
        var img = new Image();
        var self = this;

        img.onload = function () {
            imageDiv.style.background = "url('../../../../assets/img/back.jpg') no-repeat center center fixed";
            self.showBackgroundImage = true;
        };

        var imageDiv = document.getElementById('imageDiv');
        img.src = '../../../../assets/img/back.jpg';
    }
}
