import { Injectable, ErrorHandler, Injector, isDevMode } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorService } from "./services/error.service";
import { environment } from "environments/environment";
import { EnvironmentNames } from "@common/knownTypes/EnvironmentNames";
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error: Error | HttpErrorResponse) {
        const errorService = this.injector.get(ErrorService);

        let errorMessages: string[];

        if (isDevMode()) {
            console.error(error);
        }
        if (error instanceof HttpErrorResponse) {
            //  Server errors
            errorMessages = errorService.getServerMessage(error);
            errorService.displayErrorInToasty(errorMessages);
        } else {
            //  Client errors

            errorMessages = errorService.getClientMessage(error);
            errorService.displayErrorInToasty(errorMessages);
        }
    }
}
