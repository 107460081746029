import { Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { VesselDetailsDisplayModel } from "app/vessel/models/VesselDetails.display.model";

@Injectable({
    providedIn: "root",
})
export class VesselService {
    constructor(private http: HttpClient) {}

    getVesselList(filter: any) {
        return this.http.post("api:///Vessel/getVesselList", filter);
    }

    getVesselDetails(id: number) {
        return this.http.get<VesselDetailsDisplayModel>(`api:///Vessel/getVesselDetails/${id}`);
    }

    addTemporaryVessel(body: any): Observable<any> {
        return this.http.post<any>("api:///Vessel/CreateTemporaryVessel", body);
    }

}
