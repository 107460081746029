export class BaseFullHeightComponent{

    height: any;
    minimumHeight: any;
    frameElement: any;

    get iframeHeight() {
        if (this.height) return this.height + 'px';

        // display height
        let vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        // iframe position from the top
        var viewportOffset = this.frameElement?.nativeElement?.getBoundingClientRect();
        var top = viewportOffset?.top;

        // router body is wrapped with pb-3 class that adds padding to bottom of the page. this needs to be nullified
        var ph = 1 * parseFloat(getComputedStyle(document.documentElement).fontSize);

        if (!top || !vh) return;

        // display height reduced by position offset from top and added bottom margin
        let size = vh - top - ph;

        if (this.minimumHeight) {
            size = size < this.minimumHeight ? this.minimumHeight : size;
        }

        return size + 'px';
    }

}