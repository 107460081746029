<nav class="navbar fixed-top navbar-toggleable-md navbar-dark nav-shadow nav-background row"
    [ngClass]="{ 'navbar-mobile-height': isMobile }">
    <div class="col">
        <span class="toggle-menu mr-3" (click)="onToggleSidebar()">
            <i aria-hidden="true" class="fa fa-bars" [ngClass]="{ 'small-bar-icon-size': isMobile }"></i>
        </span>
        <a class="navbar-brand" routerLink="/home">
            <h5 [ngClass]="{ 'title-mobile-size': isMobile }">
                BulPCS&nbsp;&nbsp;<span class="badge badge-light" [ngClass]="buildInfo" *ngIf="displayBuildInfo" >{{ buildInfo | lowercase | translate }}</span>
            </h5>
        </a>
    </div>
    <div kendoTooltip title="{{ 'Toggle Dark Mode' | translate }}" class="theme-switcher mx-3" (click)="toggleDarkMode()">
        <fa-icon *ngIf="!dark" [icon]="['fas', 'moon']"></fa-icon>
        <fa-icon *ngIf="dark" [icon]="['fas', 'sun']"></fa-icon>
    </div>
    <div class="pull-right">
        <button kendoTooltip class="btn btn-sm text-white" (click)="changeLanguage()" title="{{ 'Change Language' | translate }}">
            <fa-icon [icon]="['fas','language']"></fa-icon>
        </button>
    </div>
    <ng-container *ngIf="user">
        <kendo-dropdownbutton class="header-dropdown-button" [data]="profileMenuItems">
            <span class="name" *ngIf="!isMobile">
                <i class="fa fa-user-circle"></i>&nbsp;&nbsp;{{ user.fullName ? user.fullName : user.userName }}
            </span>
            <span class="name mx-1" *ngIf="isMobile">
                <i class="fa fa-user"></i>
            </span>
            <ng-template kendoDropDownButtonItemTemplate let-dataItem>
                <a class="dropdown-item w-100" [routerLink]="['/profile']" *ngIf="dataItem == 'account'" translate>
                    <i class="fa fa-user mr-2"></i>Account
                </a>
                <a class="dropdown-item w-100" (click)="logout()" *ngIf="dataItem == 'sign-out'" translate>
                    <i class="fa fa-sign-out mr-2" aria-hidden="true"></i>Sign Out
                </a>
            </ng-template>
        </kendo-dropdownbutton>
    </ng-container>
</nav>