import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getUrlQueryParams } from '@common/utils/uri';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'app/singleton-services/common.service';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class StartupService {

    private _isBusy = true;

    private _status = false;

    constructor(
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private commonService: CommonService, // required for initialization
        private router: Router
    ) { }

    get isBusy() {
        return this._isBusy;
    }

    get status() {
        return this._status;
    }

    async init(): Promise<void> {

        try {

            // configure oidc
            // await this.authenticationService.configureOidc();

            // init authentication (will propagate user subject)
            await this.authenticationService.chekcAuthenticationData();

            // load current user
            await this.authenticationService.loadCurrentUser();

            this._status = true;

        } catch(e) {

        } finally {
            this._isBusy = false;
        }

        // app should not start if metadata not loaded
        if(!this._status) {
            this.router.navigate(["/error/500"]);
            return;
        }

        // route navigate, disabled by config, therefore must be triggered here
        let route = window.location.pathname;
        let queryParams = getUrlQueryParams();
        if(queryParams) {
            this.router.navigate([route], { queryParams: queryParams });
        } else {
            this.router.navigate([route]);
        }

    }
}
