import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TitleService {
    constructor(private title: Title) {

    }

    setTitle(title: string) {
        if (title) {
            this.title.setTitle(`${title} · ${environment.title}`);
        } else {
            this.title.setTitle(environment.title);
        }
    }
}
