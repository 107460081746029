<ngx-loading-bar [height]="'4px'" [includeSpinner]="false" color="#fff" ref="router"></ngx-loading-bar>
<ngx-loading-bar [height]="'4px'" [includeSpinner]="false" color="#fff" ref="http"></ngx-loading-bar>
<div kendoDialogContainer></div>


<app-header *ngIf="setupStatus" (toggleSidebar)="sidebar.toggle()"></app-header>

<app-sidebar #sidebar [ngStyle]="{'display': !isAuthenticated ? 'none' : 'block'}"></app-sidebar>

<main [ngStyle]="{'margin-left': getMainContentMargin(sidebar.isCollapsed)}">
    <div class="container-fluid pb-3">
        <router-outlet></router-outlet>
    </div>
    <router-outlet name="print"></router-outlet> <!-- Auxilliary outlet for printing HTML documents -->
</main>
