import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { pickBy } from 'lodash-es';

export class BaseDisplayModel {
    public id = null;
    public tosNumber = '';
    public version = 0;

    //todo: onece I figure out property annotations do something similar to https://github.com/martinmcwhorter/modelmetadata and auto generate form
    public createFormGroup() {
        let group = this.generateGroup();

        // TODO:  group.patchValue(pickBy(this)); will only take non null values into account
        group.addControl('version', new UntypedFormControl({ value: 0, disabled: true }));
        group.patchValue(this);
        return group;
    }

    generateGroup(): UntypedFormGroup {
        throw new Error('Method not implemented.');
    }

    protected toDate(v: Date): Date {
        if (!v) return;
        return new Date((v as unknown) as string);
    }
}
