import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable({
    providedIn: 'root',
})
export class ErrorService {
    constructor(private toastyService: ToastrService, private translateService: TranslateService) { }

    getClientMessage(error: any) {
        const errorArray: string[] = [];

        //TODO:  if custom frontend error recognize here and add to array

        var title = this.translateService.instant(marker('Unexpected error'));
        var mailto = this.translateService.instant(marker('Please report the error here'));

        var baseUrl = window.location.protocol + "//" + window.location.host;

        errorArray.push(`${title} <br>
        <a href="${baseUrl}/help/request-help">
        <i class="fa fa-envelope mailto-icon" aria-hidden="true"></i> <span class="mailto-toasty">${mailto}</span>
        </a>`);

        return errorArray;
    }

    getServerMessage(httpErrorResponse: HttpErrorResponse) {
        const errorArray: string[] = [];

        if (httpErrorResponse.url.endsWith('RefreshAccessToken')) return errorArray;

        //  Process single error, eg. NotFound or BadRequest action result
        if (httpErrorResponse.error && !httpErrorResponse.error['errors']) {
            errorArray.push(this.extractMessage(httpErrorResponse.error.errorMessage));
        }

        //  Process multiple errors, eg. BadRequest with multiple errors when creating a new user
        if (httpErrorResponse.error && httpErrorResponse.error['errors'] && Array.isArray(httpErrorResponse.error['errors'])) {
            httpErrorResponse.error['errors'].forEach((x) => errorArray.push(x));
        }

        //  Validation errors, eg. invalid DTO with required attribute.
        if (
            httpErrorResponse.error &&
            httpErrorResponse.error.status == 400 &&
            httpErrorResponse.error.title === 'One or more validation errors occurred.'
        ) {
            const errorsObject = httpErrorResponse.error['errors'];
            for (const property in errorsObject) {
                errorArray.push(errorsObject[property].toString());
            }
        }

        return errorArray;
    }
    extractMessage(errorMessage: any): string {

        if (errorMessage.indexOf('BEA') == 0) return this.backendErrorAuthMessageTranslation(errorMessage);

        return errorMessage;
    }
    backendErrorAuthMessageTranslation(errorMessage: any): string {

        if (errorMessage == 'BEA1') return this.translateService.instant(marker('Internal error in two-factor service'));// = "Greška u servisu za dvo-faktorsku autentikaciju";
        if (errorMessage == 'BEA2') return this.translateService.instant(marker('Incorrect username or password'));// = "Netočno korisničko ime ili lozinka";
        if (errorMessage == 'BEA3') return this.translateService.instant(marker('Email address field is empty'));// = "Korisnik nema unesenu email adresu";
        if (errorMessage == 'BEA4') return this.translateService.instant(marker('Cellphone is empty'));// = "Korisnik nema unesen broj mobitela";
        if (errorMessage == 'BEA5') return this.translateService.instant(marker('Two factor preference is empty'));// = "Korisnik nema odabran željeni način dvo-faktorske autentikacije";
        if (errorMessage == 'BEA6') return this.translateService.instant(marker('Incorrect two-factor code'));// = "Pogrešan dvo-faktorski kod";
        if (errorMessage == 'BEA7') return this.translateService.instant(marker('User with selected email was not found'));// = "Korisnik sa unesenom email adresom nije pronađen";
        if (errorMessage == 'BEA8') return this.translateService.instant(marker('User account is inactive'));// = "Korisnički račun nije aktivan";
        if (errorMessage == 'BEA9') return this.translateService.instant(marker('User is disabled'));// = "Korisnik je izbačen iz aplikacije";
        if (errorMessage == 'BEA10') return this.translateService.instant(marker('User does not match Certificate'));// = "Uneseni korisnik se ne poklapa s certifikatom";
        if (errorMessage == 'BEA11') return this.translateService.instant(marker('Your password has expired'));

        return errorMessage;
    }

    displayErrorInToasty(messages: string[]) {
        messages.forEach(m => {
            if (m === 'Your password has expired') return;
            else this.toastyService.error(m, null, { enableHtml: true });
        });
    }
}
