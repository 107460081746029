<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title">{{'FAQ' | translate}}</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.close(null)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <app-loader [isBusy]="isBusy">
                <ng-template>
                    <form [formGroup]="form" class="p-3">
                        <div class="row">
                            <div class="col-4">
                                <form-dropdown formControlName="parentCategoryId" [displayValue]="'name'"
                                    labelText="{{ 'Category' | translate }}" [codeListValues]="categories">
                                </form-dropdown>
                            </div>
                            <div class="col-4">
                                <form-dropdown formControlName="helpCategoryId" [displayValue]="'name'"
                                    labelText="{{ 'Sub Category' | translate }}" [codeListValues]="subCategories">
                                </form-dropdown>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <form-textbox formControlName="question"
                                    labelText="{{ 'Question' | translate }}"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <form-textbox formControlName="questionEN"
                                    labelText="{{ 'Question EN' | translate }}"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <form-textbox formControlName="answer"
                                    labelText="{{ 'Answer' | translate }}"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <form-textbox formControlName="answerEN"
                                    labelText="{{ 'Answer EN' | translate }}"></form-textbox>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="!isUnholdAction">
                                <kendo-fileselect [restrictions]="attachmentFileUploadRestrictions" [multiple]="true"
                                    (select)="fileSelected($event)" (remove)="fileRemoved($event)">
                                    <kendo-upload-messages select="{{ 'Select files' | translate }}"
                                        dropFilesHere="{{ 'Drop files here to select' | translate }}"></kendo-upload-messages>
                                </kendo-fileselect>
                            </div>

                        </div>
                    </form>
                </ng-template>
            </app-loader>
        </div>

        <div class="modal-footer">
            <button class="btn btn-sm btn-danger" (click)="activeModal.close(false)" translate>Cancel</button>
            <button class="btn btn-sm btn-primary" [disabled]="!form.valid" (click)="addQuestion()"
                translate>Save</button>
        </div>
    </ng-template>
</app-loader>
