import { Component, Input, isDevMode, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DtsService } from '@common/services/dts.service';
import { CodelistService } from '@common/services/codelist.service';
import { UserModel } from '@common/models/User.model';
import { currentUserSubject } from '@common/services/user.service';
import { Subscription } from 'rxjs';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-add-dts',
  templateUrl: './add-dts.component.html',
  styleUrls: ['./add-dts.component.scss']
})
export class AddDtsComponent implements OnInit {

  user: UserModel;
  currentUser$: Subscription;
  form: UntypedFormGroup;
  systemId: string;
  customPortCodelist: any = [];

  constructor(
    public activeModal: DialogRef,
    private translateService: TranslateService,
    private toastyService: ToastrService,
    public dtsService: DtsService,
    public codelistService: CodelistService
  ) {
    this.currentUser$ = currentUserSubject.subscribe((user) => {
      this.user = user;
    });
    this.systemId = this.user.systemId;
  }

  ngOnInit() {
    this.createFormGroup();
  }

  createFormGroup() {
    this.form = new UntypedFormGroup({
      mrn: new UntypedFormControl({ value: null, disabled: false }),
      acceptanceDate: new UntypedFormControl({ value: null, disabled: false }),
      lodgingOfficeId: new UntypedFormControl({ value: null, disabled: false }),
      warehouse: new UntypedFormControl({ value: null, disabled: false }),
      goodsDescription: new UntypedFormControl({ value: null, disabled: false }),
      netWeight: new UntypedFormControl({ value: null, disabled: false }),
      grossWeight: new UntypedFormControl({ value: null, disabled: false }),
      quantity: new UntypedFormControl({ value: null, disabled: false }),
      packagingTypeId: new UntypedFormControl({ value: null, disabled: false }),
      volume: new UntypedFormControl({ value: null, disabled: false }),
      declarant: new UntypedFormControl({ value: null, disabled: false }),
      portId: new UntypedFormControl({ value: null, disabled: false }),
      organizationId: new UntypedFormControl({ value: null, disabled: false }),
    });
  }

  addNewDts() {
    this.dtsService.addNewDts(this.form.value).subscribe(() => {
      this.toastyService.success(this.translateService.instant(marker('DTS Added')));
      this.activeModal.close();
      window.location.reload();
    });
  }
}
