import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { UserService } from '@common/services/user.service';
import { FileDownloadService } from '@common/services/file-download.service';

import { catchError, first } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UserRegistrationFormDownloadModel } from '@administration/models/UserRegistrationForm.model';
import { UserRegistrationText } from '@common/classes/UserRegistrationText';

@Component({
    selector: 'app-registration-download',
    templateUrl: './registration-form-download.component.html',
    styleUrls: ['./registration-form-download.component.scss'],
})
export class RegistrationFormDownloadComponent implements OnInit {
    isBusy = false;

    userRegistrationData: UserRegistrationFormDownloadModel;

    userRegistrationText = new UserRegistrationText(this.translateService);
    fileDownloadResult: any;

    constructor(
        private userService: UserService,
        private fileDownloadService: FileDownloadService,
        private router: Router,
        private toastyService: ToastrService,
        private translateService: TranslateService
    ) {
        const navigation = this.router.getCurrentNavigation();
        this.userRegistrationData = navigation.extras.state as UserRegistrationFormDownloadModel;
    }

    ngOnInit() {
    }

   

    private formatCurrentDate(date: Date): string {
        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${
            date.getMinutes() < 10 ? '0' : ''
        }${date.getMinutes()}`;
    }
}
