import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UserService } from '@common/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { requiredValidator } from '@common/validators/required-validator';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ExtendedBaseTab } from '@common/classes/extended-base-tab';
import { ModalService } from '@common/services/modal.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent extends ExtendedBaseTab implements OnInit {
    isBusy = false;
    username: string;
    code: string;
    title: string;

    breadcrumb = [
        {
            icon: 'user',
            title: this.translateService.get('Profile'),
            route: '/profile',
        },
        {
            icon: 'user-lock',
            title: this.translateService.get('Change Password'),
        },
    ];

    form = new UntypedFormGroup({
        newPassword: new UntypedFormControl({ value: null, disabled: false }, requiredValidator()),
        confirmPassword: new UntypedFormControl({ value: null, disabled: false }, requiredValidator()),
    });

    constructor(
        private userService: UserService,
        private toastyService: ToastrService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        protected translateService: TranslateService,
        dialogService: ModalService,
    ) {
        super(dialogService, translateService)
    }

    ngOnInit() {
        this.username = this.activatedRoute.snapshot.params.username;
        this.code = this.activatedRoute.snapshot.params.code;
        this.title = this.translateService.instant(marker('Change Password'));
    }

    resetPassword() {
        const body = this.form.getRawValue();

        this.userService
            .resetPassword({...body, username: this.username, code: this.code}).subscribe((data) => {
                this.toastyService.success(this.translateService.instant(marker('Password reset successfully')));
                this.router.navigate(['/logout']);
            });
    }
}
