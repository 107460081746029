import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserModel } from '../models/User.model';
import { tap } from 'rxjs/operators';
import { ActivateUserSaveModel } from '@administration/models/ActivateUser.save.model';
import { UserDisplayModel } from '@administration/models/user.display.model';
import { LanguageModel } from '@common/models/Language.model';
import { LoginCandidateResponse } from '@common/models/LoginCandidateResponse';
import { UserRegistrationFormDownloadModel } from '@administration/models/UserRegistrationForm.model';
import { CodelistDisplayModel } from '@common/models/common/CodelistDisplay.model';
import { StateService } from 'app/singleton-services/state.service';

export const currentUserSubject = new BehaviorSubject<UserModel>(null);

@Injectable()
export class UserService {
    constructor(private http: HttpClient, private stateService: StateService) {}

    private static getAccessTokenKey() {
        return environment.accessToken;
    }

    private static getRefreshTokenKey() {
        return environment.refreshToken;
    }

    public static getAccessToken() {
        return localStorage.getItem(UserService.getAccessTokenKey());
    }

    public static getRefreshToken() {
        return localStorage.getItem(UserService.getRefreshTokenKey());
    }

    public static hasAccessToken() {
        return !!UserService.getAccessToken();
    }

    private static setAccessToken(accessToken: string) {
        return localStorage.setItem(UserService.getAccessTokenKey(), accessToken);
    }

    public static setRefreshToken(refreshToken: string) {
        return localStorage.setItem(UserService.getRefreshTokenKey(), refreshToken);
    }



    public uploadAttachment(id: number, files: any[])
    {
        return this.http.post<any>(`api:///user/uploadAttachments`, {id: id, files: files});
    }

    login(username: string, password: string, iddNumber?: string): Observable<LoginCandidateResponse> {
        const data = {
            username: username,
            password: password,
            iddNumber: iddNumber
        };

        return this.http.post<any>('api:///authentication/login', data);
    }

    hijack(username: string, password: string) {
        const data = {
            username: username,
            password: password,
            shouldHijack: true,
        };
        return this.http.post<any>('api:///authentication/hijack', data);
    }

    setUser2FaPreference(username: string, password: string, twoFactorPreference: string): Observable<LoginCandidateResponse> {
        const data = {
            username: username,
            password: password,
            twoFactorPreference: twoFactorPreference,
        };

        return this.http.post<any>('api:///authentication/selectTwoFactorType', data);
    }

    checkTwoFactorCode(username: string, password: string, twoFactorCode: string): Observable<LoginCandidateResponse> {
        const data = {
            username: username,
            password: password,
            twoFactorCode: twoFactorCode,
        };

        return this.http.post<any>('api:///authentication/verifyTwoFactorCode', data);
    }

    resendTwoFactorCode(username: string): Observable<any> {
        const data = {
            username: username,
        };

        return this.http.post<any>('api:///authentication/resendTwoFactorCode', data);
    }

    forgotPassword(userData): Observable<any> {
        return this.http.post('api:///authentication/ForgotPassword', userData);
    }

    resetPassword(userData): Observable<any> {
        return this.http.post('api:///authentication/ResetPassword', userData);
    }

    setAuthenticationTokens(accessToken: string, refreshToken: string) {
        UserService.setAccessToken(accessToken);
        UserService.setRefreshToken(refreshToken);
    }

    setCurrentLanguage(lang: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            firstValueFrom(this.http
                .get(`api:///authentication/SetCurrentLanguage/${lang}`))
                .then((data) => {
                    resolve(data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    async logout(returnUrl?: string) {
        if (UserService.hasAccessToken()) {
            let logoutAction = await firstValueFrom(this.http.get('api:///authentication/LogOut'));
            this.clearLocalTokens();
        }
    }

    clearLocalTokens() {
        localStorage.removeItem(UserService.getAccessTokenKey());
        localStorage.removeItem(UserService.getRefreshTokenKey());
    }

    refreshAccessToken() {
        return this.http
            .post<any>('api:///command/RefreshAccessToken', {
                refreshToken: UserService.getRefreshToken(),
                signInType: this.stateService.getSignInType()
            })
            .pipe(
                tap((response) => {
                    UserService.setAccessToken(response.accessToken);
                    UserService.setRefreshToken(response.refreshToken);
                })
            );
    }

    /** User operations */
    searchUserList(filter): Observable<any> {
        return this.http.post<any>('api:///user/UserList', filter);
    }

    getPaperDocumentList(filter): Observable<any> {
        return this.http.post<any>('api:///user/GetPaperDocumentList', filter);
    }

    downloadPaperDocumentFile(id): Observable<any> {
        return this.http.get<any>(`api:///user/downloadPaperDocumentFile/${id}`);
    }

    activateUser(userId: ActivateUserSaveModel): Observable<any> {
        return this.http.post<any>('api:///user/activateUser', userId);
    }

    reactivateUser(userId: number): Observable<any> {
        return this.http.post<any>('api:///user/reactivateUser', userId);
    }

    removeUser(user: any): Observable<any> {
        return this.http.post<any>('api:///user/RemoveUser', user);
    }

    deleteUser(user: any): Observable<any> {
        return this.http.post(`api:///user/DeleteUser/${user}`, null);
    }

    getUserData(userId: number): Observable<UserDisplayModel> {
        return this.http.get<UserDisplayModel>(`api:///user/GetUserData/${userId}`);
    }

    changeRoleExpirationDate(saveModel: any) {
        return this.http.post(`api:///User/ChangeExpirationDate`, saveModel);
    }

    downloadUserDocument(id): Observable<any> {
        return this.http.get<any>(`api:///User/DownloadUserDocument/${id}`);
    }

    saveUser(userData): Observable<any> {
        return this.http.post<any>('api:///user/UpdateUser', userData);
    }

    addRoleToUser(roleId: number, userId: number, expirationDate: Date | undefined): Observable<any> {
        const body = {
            roleId,
            userId,
            expirationDate,
        };
        return this.http.post('api:///user/AddRoleToUser', body);
    }

    addTerminalToUser(terminalId: number, userId: number): Observable<any> {
        const body = {
            terminalId,
            userId,
        };
        return this.http.post('api:///user/AddTerminalToUser', body);
    }

    removeTerminalFromUser(terminalId: number, userId: number): Observable<any> {
        const body = {
            terminalId,
            userId,
        };
        return this.http.post('api:///user/RemoveTerminalFromUser', body);
    }

    addCustomsOfficeToUser(customsOfficeId: number, userId: number): Observable<any> {
        const body = {
            customsOfficeId,
            userId,
        };
        return this.http.post('api:///user/AddCustomsOfficeToUser', body);
    }

    removeCustomsOfficeFromUser(customsOfficeId: number, userId: number): Observable<any> {
        const body = {
            customsOfficeId,
            userId,
        };
        return this.http.post('api:///user/RemoveCustomsOfficeFromUser', body);
    }

    removeUserRole(roleId: number, userId: number): Observable<any> {
        const body = {
            roleId,
            userId,
        };
        return this.http.post('api:///user/RemoveUserRole', body);
    }

    crateUserName(firstName: string, middleName: string, lastName: string): Observable<any> {
        const queryParams = {
            params: new HttpParams().set('firstName', firstName).append('middleName', middleName).append('lastName', lastName),
        };

        return this.http.get<any>(`api:///user/CreateUserName/`, queryParams);
    }

    createUser(userData): Observable<any> {
        return this.http.post('api:///user/CreateUser', userData);
    }

    getOrganizations(): Observable<any> {
        return this.http.get('api:///user/GetOrganizations');
    }

    getOrganizationsForUserCreate(): Observable<any> {
        return this.http.get('api:///user/GetOrganizationsForUserCreate');
    }

    getRoles(): Observable<any> {
        return this.http.get('api:///user/GetRoles');
    }

    getOrganizationUserRoles(orgId: number): Observable<any> {
        return this.http.get(`api:///user/GetOrganizationUserRoles/${orgId}`);
    }

    getUsers(): Observable<any> {
        return this.http.get('api:///user/GetUsers');
    }

    getOrganizationRoles(organizationId: number): Observable<any> {
        return this.http.get(`api:///user/GetOrganizationRoles/${organizationId}`);
    }

    processRegistrationForm(model: UserRegistrationFormDownloadModel): Observable<any> {
        return this.http.post('api:///user/CreateWordForm', model);
    }

    saveUserProfile(id: number, phoneNumber: string, firstName: string, lastName: string, middleName: string, email: string, language: string, twoFactorTypeId: any): Observable<any> {
        const userData = {
            id: id,
            phoneNumber: phoneNumber,
            firstName: firstName,
            lastName: lastName,
            middleName: middleName,
            email: email,
            language: language,
            twoFactorTypeId: twoFactorTypeId
        };
        return this.http.post('api:///user/SaveUserProfile', userData);
    }

    getTwoFactorPreferences(): Observable<CodelistDisplayModel[]> {
        return this.http.get<CodelistDisplayModel[]>('api:///user/GetTwoFactorPreferences');
    }

    getLanguages(): Observable<CodelistDisplayModel[]> {
        return this.http.get<CodelistDisplayModel[]>('api:///user/GetLanguages');
    }

    deleteAccount(username: string, code: string): Observable<any> {
        return this.http.post('api:///user/DeleteAccount', { username, code });
    }

    requestDeleteAccount(id: number): Observable<any> {
        return this.http.post('api:///user/RequestDeleteAccount', { id });
    }

    resetUserLockout(userId: number) {
        return this.http.post(`api:///user/ResetUserLockout/${userId}`, null);
    }

    removeUserAttachment(userAttachment: any): Observable<any> {
        return this.http.post<any>('api:///user/RemoveUserAttachment', userAttachment);
    }

    processReceivedTokenFromSSO(token: string): Observable<any> {
        return this.http.post<any>(`api:///authentication/ProcessReceivedTokenFromSSO/`, {token: token});
    }

    getEmailSubscriptions(userId: number): Observable<any> {
        return this.http.get(`api:///user/GetEmailSubscriptions/${userId}`);
    }

    emailSubscription(model: any) : Observable<any> {
        return this.http.post<any>('api:///user/EmailSubscription', model);
    }

    getUserAttachments(userId: number) : Observable<any> {
        return this.http.get(`api:///user/GetUserAttachments/${userId}`);
    }

    getUserCustomsOffices(userId: number) : Observable<any> {
        return this.http.get(`api:///user/GetUserCustomsOffices/${userId}`);
    }

    getUserTerminals(userId: number) : Observable<any> {
        return this.http.get(`api:///user/GetUserTerminals/${userId}`);
    }

    getUserRoles(userId: number) : Observable<any> {
        return this.http.get(`api:///user/GetUserRoles/${userId}`);
    }
}
