import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { currentUserSubject } from '../services/user.service';
import { UserModel } from '@common/models/User.model';

@Injectable()
export class AuthGuard implements CanActivate {
    private currentUser: UserModel;

    constructor(private router: Router) {
        currentUserSubject.subscribe((user) => {
            this.currentUser = user;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // status - user available
        let status = !!this.currentUser;

        // user not logged in
        if (!status) {
            // redirect to login
            if (state.url) {
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            } else {
                this.router.navigate(['/login']);
            }
        }

        return status;
    }
}
