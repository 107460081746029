import { Component, Input, Self, Optional, OnInit } from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { ValidationMessageService } from '@common/services/validation-message.service';
import { AbstractFormControlComponent } from './abstract-form-control.component';

@Component({
    selector: 'form-date',
    styleUrls: ['form-controls.component.scss'],
    template: `
    <div class="form-group has-value ">
        <label class="col-form-label">
            {{labelText}}
        </label>
        <app-control
            [type]="'datetime'"
            [kendoType]="'date'"
            [isDisabled]="isDisabled"
            (ngModelChange)="changeValue($event)"
            [ngClass]="{ 'is-invalid': isInvalid }"
            [isInvalid]="isInvalid"
            [formControlValidators]="getFormControlValidators"
            [(ngModel)]="value"
            [contextual]="contextual"
        ></app-control>
    </div>
    `,
    providers: []
})
export class DateComponent extends AbstractFormControlComponent {
    @Input()
    labelText: string;
    @Input()
    contextual: string = null;

    constructor(@Self() @Optional() controlDir: NgControl, validationMessageService: ValidationMessageService) {
        super(controlDir, validationMessageService);
    }
}
