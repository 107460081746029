import { Component, Input, isDevMode, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DtsService } from '@common/services/dts.service';
import { CodelistService } from '@common/services/codelist.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
@Component({
  selector: 'app-update-dts',
  templateUrl: './update-dts.component.html',
  styleUrls: ['./update-dts.component.scss']
})
export class UpdateDtsComponent implements OnInit {

  form: UntypedFormGroup;
  dtsId: number;
  saveModel: any;

  constructor(
    public activeModal: DialogRef,
    private translateService: TranslateService,
    private toastyService: ToastrService,
    public dtsService: DtsService,
    public codelistService: CodelistService
  ) { }

  ngOnInit() {
    this.createFormGroup();
  }

  createFormGroup() {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl({ value: this.dtsId, disabled: false }),
      mrn: new UntypedFormControl({ value: null, disabled: false }),
      acceptanceDate: new UntypedFormControl({ value: null, disabled: false }),
      lodgingOfficeId: new UntypedFormControl({ value: this.saveModel.lodgingOffice?.id, disabled: false }),
      warehouse: new UntypedFormControl({ value: null, disabled: false }),
      goodsDescription: new UntypedFormControl({ value: null, disabled: false }),
      netWeight: new UntypedFormControl({ value: null, disabled: false }),
      grossWeight: new UntypedFormControl({ value: null, disabled: false }),
      quantity: new UntypedFormControl({ value: null, disabled: false }),
      packagingTypeId: new UntypedFormControl({ value: null, disabled: false }),
      volume: new UntypedFormControl({ value: null, disabled: false }),
      declarant: new UntypedFormControl({ value: null, disabled: false }),
      portId: new UntypedFormControl({ value: this.saveModel.port?.id, disabled: false }),
      organizationId: new UntypedFormControl({ value: null, disabled: false }),
    });

    this.form.patchValue(this.saveModel);
    if(this.saveModel.packagingType != null) this.form.controls['packagingTypeId'].patchValue(this.saveModel.packagingType.slice(0,2));
  }

  editDts() {
    this.dtsService.editDts(this.form.value).subscribe(() => {
      this.toastyService.success(this.translateService.instant(marker('DTS Edited')));
      this.activeModal.close();
      window.location.reload();
    });
  }

}
