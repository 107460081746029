import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RoleService {

    constructor(private http: HttpClient) { }

    getRoleList(filter: any): Observable<any> {
        return this.http.post<any>('api:///Role/RoleList', filter);
    }

    createNewRole(role: any): Observable<any> {
        return this.http.post<any>('api:///Role/CreateRole', role);
    }

    saveEditChanges(role: any): Observable<any> {
        return this.http.post<any>('api:///Role/SaveEditChanges', role);
    }

    removeRole(roleId: any): Observable<any> {
        return this.http.post<any>('api:///Role/RemoveRole', roleId);
    }

    getSingleRole(roleId: number): Observable<any> {
        return this.http.get<any>(`api:///Role/GetSingleRoleDetailed/${roleId}`);
    }

    initializeRole(): Observable<any> {
        return this.http.get<any>('api:///Role/InitializeRole');
    }

    getPermissions(): Observable<any> {
        return this.http.get<any>('api:///Role/GetPermissions');
    }

    getEmailEventDefaultSubscriptions(roleId: number): Observable<any> {
        return this.http.get(`api:///Role/GetEmailEventDefaultSubscription/${roleId}`);
    }

    emailEventDefaultSubscription(model: any) : Observable<any> {
        return this.http.post<any>('api:///Role/EmailEventDefaultSubscription', model);
    }
    
    addOrganizationToRole(organizationId: number, roleId: number): Observable<any> {
        const body = {
            organizationId, roleId
        };

        return this.http.post<any>('api:///Role/AddOrganizationToRole', body);
    }

    removeOrganizationFromRole(organizationId: number, roleId: number): Observable<any> {
        const body = {
            organizationId, roleId
        };

        return this.http.post<any>('api:///Role/RemoveOrganization', body);
    }

    addReportToRole(reportId: number, roleId: number): Observable<any> {
        const body = {
            reportId, roleId
        };

        return this.http.post<any>('api:///Role/AddReportToRole', body);
    }

    removeReportFromRole(reportId: number, roleId: number): Observable<any> {
        const body = {
            reportId, roleId
        };

        return this.http.post<any>('api:///Role/RemoveReport', body);
    }

    addPermissionToRole(permissionId: number, roleId: number): Observable<any> {
        const body = {
            permissionId, roleId
        };

        return this.http.post<any>('api:///Role/AddPermissionToRole', body);
    }

    removePermissionFromRole(permissionId: number, roleId: number): Observable<any> {
        const body = {
            permissionId, roleId
        };

        return this.http.post<any>('api:///Role/RemovePermission', body);
    }

}
