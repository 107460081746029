import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { LoginCandidateResponse } from '@common/models/LoginCandidateResponse';
import { AuthenticationService } from '@common/services/authentication.service';
import { UserService } from '@common/services/user.service';
import { isMobile } from '@common/utils/isMobile';
import { requiredValidator } from '@common/validators/required-validator';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

@Component({
  selector: 'app-sso-login',
  templateUrl: './sso-login.component.html',
  styleUrls: ['./sso-login.component.scss']
})
export class SsoLoginComponent implements OnInit {
  isBusy = false;
  hasError = false;
  showBackgroundImage = true;
  
  firstFactorForm: UntypedFormGroup;
  token: string;
  iddNumber: string;
  currentLanguage: any;

  isMobile = isMobile();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private toastyService: ToastrService,
    private translateService: TranslateService,
    private authService: AuthenticationService
  ) {
    this.firstFactorForm = new UntypedFormGroup({
      username: new UntypedFormControl('', requiredValidator()),
      password: new UntypedFormControl('', requiredValidator()),
      tac: new UntypedFormControl(false, requiredValidator())
  });
  }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.queryParams['token'];
    this.currentLanguage = this.translateService.currentLang;

    if(this.token != null){
      this.processReceivedToken();
    }
  }

  public get getIsFirstFactorDisabled(){
    return this.isBusy || this.firstFactorForm.value.tac == false || !this.firstFactorForm.valid;
  }

  processReceivedToken(){
    this.isBusy = true;
    this.userService.processReceivedTokenFromSSO(this.token)
      .pipe(
        first(),
        catchError((err) => {
          this.isBusy = false;
          this.router.navigate(['/login']);
          return throwError(err);
        })
    )
    .subscribe((response) => {
      // user with Certificate IddNumber does not exist
      if(!response.userExists){
        this.isBusy = false;
        this.toastyService.error(this.translateService.instant(marker('User does not exist.')));
        this.router.navigate(['/login']);
      }
      // single user exists with correct Certificate IddNumber 
      else if(response.userExists && response.singleUser != null){
        this.setTokens(response.accessToken, response.refreshToken);
      } 
      // multiple user exist with same Certificate IddNumber
      else {
        this.isBusy = false;
        this.iddNumber = response.iddNumber;
      }
    });
  }

  initiateLogin() {
    this.isBusy = true;
    const un = this.firstFactorForm.value.username;
    const pw = this.firstFactorForm.value.password;

    if(this.iddNumber == null){
      this.toastyService.error(this.translateService.instant(marker('User does not have a Certificate loaded.')));
      this.isBusy = false;
      this.hasError = false;
      return;
    }
   
    this.userService.login(un, pw, this.iddNumber)
      .pipe(
        first(),
        catchError((err) => {
          this.hasError = true;
          this.isBusy = false;
          this.router.navigate(['/login']);
          return throwError(err);
        })
      )
      .subscribe((response: LoginCandidateResponse) => {
        if (response.firstFactorSuccessful && response.codeSendingServiceFailed) {
          this.isBusy = false;
          this.hasError = false;
          return;
        }

        if (!response.firstFactorSuccessful || response.hasError) {
          this.isBusy = false;
          return;
        }

        if (response.secondFactorSuccessful) {
          this.setTokens(response.accessToken, response.refreshToken);
          this.isBusy = false;
          this.showBackgroundImage = false;
          return;
        }

        this.hasError = false;
        this.isBusy = false;
      });
  }

  /** Final auth stage - after successful password or two-factor login, set user authentication tokens,
   *  then get current user info
  */
  async setTokens(accessToken: string, refreshToken: string) {
    let status = await this.authService.authenticateWithTokens(accessToken, refreshToken);
    this.isBusy = true;
    if(status) {
      this.router.navigate(["/home"]);
    } else {
      this.router.navigate(["/error/500"]);
    }
  }
}