import { SelectionModel } from '@actual-dev/pdf-signer/lib/Models/selectionModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { CertificateSelectionDialog } from '@common/modals/certificate-selection/certificate-selection.component';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, Observable } from 'rxjs';
import { XmlSigner } from 'xml-signer';
import { ModalService, ModalWidth } from './modal.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable()
export class PdfSignatureService {
    signer = new XmlSigner();
    private modalRef: DialogRef;

    constructor(
        private http: HttpClient,
        private modalService: DialogService,
        private toastyService: ToastrService,
        private translateService: TranslateService,
        private dialogService: ModalService
    ) {}

    signPdfFile(signPdfDto: any): Observable<any> {
        var headers = new HttpHeaders();
        headers.append('Access-Control-Allow-Origin', '*');
        // return this.http.post<any>('http://localhost:999/Signature/SignPdf', signPdfDto, { headers: headers, responseType: 'arrayBuffer' as 'json' });
        return this.http.post<any>('http://localhost:999/Signature/SignPdf', signPdfDto, { headers: headers });
    }

    async selectCertificate() {
        try {
            await this.signer.start();
            const certificates = await this.signer.getCertificates({
                //keyUsages: [CertificateKeyUsage.DigitalSignature],
                issuerName: '',
            });

            if (!certificates.length) {
                this.toastyService.error(this.translateService.instant(marker('Missing certificate')));
                return null;
            }

            this.modalRef = this.modalService.open({content: CertificateSelectionDialog, width: ModalWidth.xl});

            this.modalRef.content.instance.availableCertificates = certificates;

            return firstValueFrom(this.modalRef.result);
        } catch (error) {

            const confirmDialogResult = await this.dialogService.confirm(
                this.translateService.instant(marker('XML SIGNER')),
                this.translateService.instant(marker('Are you sure you want to download XML SIGN component?')),
                this.translateService.instant(marker('YES')),
                this.translateService.instant(marker('Cancel'))
            );

            if (!confirmDialogResult) return;

            const link = document.createElement('a');
            this.toastyService.error(this.translateService.instant(marker(error.toString())));
            alert(error);

            link.setAttribute('href', '../assets/files/XmlSigner-1.0.0.0-Release-x64.msi');
            (link as any).download = 'XmlSigner-1.0.0.0-Release-x64.msi';
            link.click();

            return null;
        }
    }

    async signPdfImplementation(pdf, selectedCertificate, appearance: SelectionModel) {
        try {
            const result = await this.signer.signPdf({
                pdf: pdf,
                signingCertificateSerialNumber: selectedCertificate,
                signatureAppearance: {
                    page: appearance.page,
                    positionBottom: appearance.positionBottom,
                    positionLeft: appearance.positionLeft,
                    width: appearance.width,
                    height: appearance.height,
                    dateFormat: 'dd.MM.yyyy HH:mm'
                }
            });

            return result.signedPdf;
        } catch (error) {

            if (isDevMode()) {
                console.error(error);
            }
            return null;
        }
    }
}
