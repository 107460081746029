import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DtsService {
    constructor(private http: HttpClient) {}

    getDtsList(filter: any): Observable<any> {
        return this.http.post('api:///Dts/GetDtsList', filter);
    }

    addNewDts(dts: any): Observable<any> {
        return this.http.post('api:///Dts/AddNewDts', dts);
    }

    editDts(dts: any): Observable<any> {
        return this.http.post('api:///Dts/EditDts', dts);
    }
}