<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title">{{ 'Cargo Item' | translate }}</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.close(null)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <app-loader [isBusy]="false">
                <ng-template>
                    <form [formGroup]="form" class="p-3">

                        <div *ngIf="containerized" class="disabledLabels">
                            <div class="row">
                                <div class="col-3">
                                    <form-dropdown formControlName="shippingLine" labelText="{{ 'Shipping Line' | translate }}" codeListName="Organization"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="bookingNumber" labelText="{{ 'Booking Nbr.' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="billOfLadingNbr" labelText="{{ 'B/L Nbr.' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="container" labelText="{{ 'Container' | translate }}"></form-textbox>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-3">
                                    <form-dropdown formControlName="isoCode" labelText="{{ 'ISO Code' | translate }}" codeListName="ContainerIsoCode"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="tareWeight" labelText="{{ 'Tare Weight (kg)' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="usingLine" labelText="{{ 'Cont. Using Lines' | translate }}" codeListName="Organization"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="status" labelText="{{ 'Cont. Status' | translate }}" codeListName="FullOrEmpty"></form-dropdown>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-3">
                                    <form-dropdown formControlName="loadType" labelText="{{ 'Container Load Type' | translate }}" codeListName="LoadType"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="sealNumber" labelText="{{ 'Seal Number' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="sealType" labelText="{{ 'Seal Type' | translate }}" codeListName="SealType"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="taric" labelText="{{ 'HS Code' | translate }}"></form-textbox>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-3">
                                    <form-textbox class="disabledLabel" formControlName="cargoDescription" labelText="{{ 'Cargo Description' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="cargoReference" labelText="{{ 'Cargo Reference' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-number formControlName="pckNumber" labelText="{{ 'PCK. Number' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="pckType" labelText="{{ 'PCK. Type' | translate }}" codeListName="CodeListCargoPackagingTypePerSystemForManifest" [forTOSSystem]="'CUST'"></form-dropdown>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-3">
                                    <form-dropdown formControlName="cargoType" labelText="{{ 'Cargo Type' | translate }}" codeListName="CodeListCargoTypePerSystemForManifest" [forTOSSystem]="'MSW'"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="netWeight" labelText="{{ 'Net Weight (kg)' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="grossWeight" labelText="{{ 'Gross Weight (kg)' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true"  formControlName="cargoVolume" labelText="{{ 'Volume (m3)' | translate }}"></form-number>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-3">
                                    <form-number [decimal]="true"  formControlName="vgm" labelText="{{ 'VGM (kg)' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="imdgNbr" labelText="{{ 'IMDG Nbr.' | translate }}" codeListName="ImdgClass"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="unNbr" labelText="{{ 'UN Nbr.' | translate }}" codeListName="UnNumber"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="packGroup" labelText="{{ 'Pack. Group' | translate }}" codeListName="ImdgPackingGroup"></form-dropdown>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-3">
                                    <form-textbox formControlName="flashpoint" labelText="{{ 'Flashpoint (in °C) ' | translate}}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="cargoTechnicalDescription" labelText="{{ 'Cargo Technical Description' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="locationOnBoard" labelText="{{ 'Location On Board ' | translate}}"></form-textbox>
                                </div>
                                <div class="col-1">
                                    <form-textbox formControlName="dgContact" labelText="{{ 'DG Contact' | translate}}"></form-textbox>
                                </div>
                                <div class="col-2">
                                    <form-number [decimal]="true" formControlName="temperatureFrom" labelText="{{ 'Temperature From' | translate }}"></form-number>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="temperatureTo" labelText="{{ 'Temperature To' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="cargoCustomsStatus" labelText="{{ 'Customs Status' | translate }}" codeListName="CustomsStatus"></form-dropdown>
                                </div>
                                <div class="col-2" style="margin-top: 10px">
                                    <form-checkbox labelText="{{ 'In Transit' | translate }}" formControlName="inTransit"></form-checkbox>
                                </div>
                                <div class="col-2">
                                    <form-dropdown formControlName="cargoOrigin" labelText="{{ 'Cargo Origin' | translate }}" codeListName="Flag"></form-dropdown>
                                </div>
                                <div class="col-2">
                                    <form-dropdown formControlName="pol" labelText="{{ 'POL' | translate }}" codeListName="Port"></form-dropdown>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-3">
                                    <form-dropdown formControlName="pod" labelText="{{ 'POD' | translate }}" codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="fpod" labelText="{{ 'FPOD' | translate }}" codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="shipper" labelText="{{ 'Shipper' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="consignee" labelText="{{ 'Consignee' | translate }}"></form-textbox>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-3">
                                    <form-textbox formControlName="notifier" labelText="{{ 'Notifier' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="payer" labelText="{{ 'Payer' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="customsOfficeCode" labelText="{{ 'Customs Office Code' | translate }}" codeListName="CodeListBGCustomsOffice"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="mrn" labelText="{{ 'Customs Declaration Number' | translate }}"></form-textbox>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!containerized" class="disabledLabels">
                            <div class="row">
                                <div class="col-3">
                                    <form-textbox formControlName="bookingNumber" labelText="{{ 'Booking Nbr.' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="billOfLadingNbr" labelText="{{ 'B/L Nbr.' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="taric" labelText="{{ 'HS Code' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="cargoDescription" labelText="{{ 'Cargo Description' | translate }}"></form-textbox>
                                </div>
                            </div>
                            <div class = "row">
                                <div class="col-3">
                                    <form-textbox formControlName="cargoReference" labelText="{{ 'Cargo Reference' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-number formControlName="pckNumber" labelText="{{ 'PCK. Number' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="pckType" labelText="{{ 'PCK. Type' | translate }}" codeListName="CodeListCargoPackagingTypePerSystemForManifest" [forTOSSystem]="'CUST'"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="cargoType" labelText="{{ 'Cargo Type' | translate }}" codeListName="CodeListCargoTypePerSystemForManifest" [forTOSSystem]="'MSW'"></form-dropdown>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="netWeight" labelText="{{ 'Net Weight (kg)' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="grossWeight" labelText="{{ 'Gross Weight (kg)' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true"  formControlName="cargoVolume" labelText="{{ 'Volume (m3)' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="mswCargoRegulation" labelText="{{ 'DG Classification' | translate }}" codeListName="MswCargoRegulation"></form-dropdown>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <form-dropdown formControlName="imdgNbr" labelText="{{ 'IMDG Nbr.' | translate }}" codeListName="ImdgClass"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="unNbr" labelText="{{ 'UN Nbr.' | translate }}" codeListName="UnNumber"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="packGroup" labelText="{{ 'Pack. Group' | translate }}" codeListName="ImdgPackingGroup"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="marpolCategory" labelText="{{ 'Marine Pollutant' | translate }}" codeListName="MarpolCategory"></form-dropdown>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <form-dropdown formControlName="ibcCode" labelText="{{ 'IBC Code' | translate }}" codeListName="IBCCode"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="imsbcCode" labelText="{{ 'IMSBC Code' | translate }}" codeListName="ImsbcCode"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="flashpoint" labelText="{{ 'Flashpoint (in °C) ' | translate}}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="cargoTechnicalDescription" labelText="{{ 'Cargo Technical Description' | translate }}"></form-textbox>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <form-textbox formControlName="locationOnBoard" labelText="{{ 'Location On Board ' | translate}}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="dgContact" labelText="{{ 'DG Contact' | translate}}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="temperatureFrom" labelText="{{ 'Temperature From' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="temperatureTo" labelText="{{ 'Temperature To' | translate }}"></form-number>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <form-dropdown formControlName="cargoCustomsStatus" labelText="{{ 'Customs Status' | translate }}" codeListName="CustomsStatus"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="sealNumber" labelText="{{ 'Seal Number' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="sealType" labelText="{{ 'Seal Type' | translate }}" codeListName="SealType"></form-dropdown>
                                </div>
                                <div class="col-2" style="margin-top: 10px">
                                    <form-checkbox labelText="{{ 'In Transit' | translate }}" formControlName="inTransit"></form-checkbox>
                                </div>
                                <div class="col-2">
                                    <form-dropdown formControlName="cargoOrigin" labelText="{{ 'Cargo Origin' | translate }}" codeListName="Flag"></form-dropdown>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <form-dropdown formControlName="pol" labelText="{{ 'POL' | translate }}" codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="pod" labelText="{{ 'POD' | translate }}" codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="fpod" labelText="{{ 'FPOD' | translate }}" codeListName="Port"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="shipper" labelText="{{ 'Shipper' | translate }}"></form-textbox>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <form-textbox formControlName="consignee" labelText="{{ 'Consignee' | translate }}"></form-textbox>
                                </div>
                                 <div class="col-3">
                                    <form-textbox formControlName="notifier" labelText="{{ 'Notifier' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="payer" labelText="{{ 'Payer' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="customsOfficeCode" labelText="{{ 'Customs Office Code' | translate }}" codeListName="CodeListBGCustomsOffice"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="mrn" labelText="{{ 'Customs Declaration Number' | translate }}"></form-textbox>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </app-loader>
        </div>
        <div class="modal-footer">
            <button class="btn btn-sm btn-secondary" (click)="activeModal.close(false)">
                <fa-icon [icon]="['fas', 'times']"></fa-icon>&nbsp;&nbsp;{{ 'Cancel' | translate }}
            </button>
            <button class="btn btn-sm btn-primary" (click)="addNewCargoItem()" [disabled]="getIsFormDisabled">
                <fa-icon [icon]="['fas', 'check']"></fa-icon>&nbsp;&nbsp;{{ 'Save' | translate }}
            </button>
        </div>
    </ng-template>
</app-loader>
