import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { currentUserSubject } from '@common/services/user.service';
import { UserModel } from '@common/models/User.model';
import { ToastrService } from 'ngx-toastr';
import { AglLoggingService } from '@common/services/agl-logging.service';
import { AglCategoryTypes } from '@common/knownTypes/AglCategoryTypes';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationGuard implements CanActivateChild {
    user: UserModel;
    constructor(
        private router: Router,
        private location: Location,
        private toastyService: ToastrService,
        private aglLoggingService: AglLoggingService
    ) {
        currentUserSubject.subscribe((user) => {
            this.user = user;
        });
    }
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (!this.user) return false; // in theory this should never happen

        if (childRoute.data.permission && !this.user.hasPermission(childRoute.data.permission)) {
            this.logAndNotifyUnauthorizedAccess(state);
            return false;
        }

        if (childRoute.data.permissions && !this.user.hasAtLeastOnePermission(childRoute.data.permissions)) {
            this.logAndNotifyUnauthorizedAccess(state);
            return false;
        }

        return true;

        // let currentRoute = this.navigationFactoryService.getAllPossibleRoutes().filter((x) => x.route == state.url);

        // if (!currentRoute || currentRoute.length != 1) {
        //     // alert ambiguous route request
        //     // return false;
        //     return true;
        // }

        // let result = currentRoute[0].permissions.every((x) => this.user.hasPermission(x));

        // return true;
    }

    logAndNotifyUnauthorizedAccess(state: RouterStateSnapshot) {
        const pastedOrReturnUrl = !this.router.routerState.snapshot.url || this.router.routerState.snapshot.url.includes('login?returnUrl');

        this.toastyService.error('Error: ' + 'Unauthorized access');

        this.aglLoggingService.logAglUserAction(`Unauthorized navigation`, `${state.url}`, '', '', AglCategoryTypes.AuthorizationError);

        if (pastedOrReturnUrl) this.router.navigate(['/home']);
    }
}
