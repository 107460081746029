<div class="row mt-5 text-center">
    <div class="col"></div>
    <div class="col-auto">
        <h2>
            <fa-icon [icon]="['fas', 'exclamation-circle']" [size]="'2x'" class="text-danger mr-2"></fa-icon>
            <br/><br/>
            <span *ngIf="showErrorNumber">{{errorNumber}} - {{'Error ' + errorNumber|translate}}</span>
            <span *ngIf="!showErrorNumber">{{'Error ' + errorNumber|translate}}</span>
        </h2>
        <br/>
        <p>            
            <a class="btn btn-sm btn-primary" href="" (click)="reload()">{{'Reload'|translate}}</a>
        </p>
    </div>
    <div class="col"></div>
</div>