<div class="d-flex justify-content-center h-100">
    <div class="card card-border-top bg-white mt-3">
        <div class="p-1">
           
        </div>

        <div class="col-12" *ngIf="!isBusy">
            <div class="row">
                <div class="col-12 text-center">
                    <p>{{ userRegistrationText.completeUserRegistration | translate }}</p>
                </div>

                <div class="col-12 text-center pb-4">
                    <button class="btn btn-sm btn-info mr-2" type="button" [routerLink]="['/login']">
                        <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon> {{ 'Return to login' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
