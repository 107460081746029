import { Component, Input, TemplateRef, ContentChild } from '@angular/core';

@Component({
    selector: 'app-loader',
    styleUrls: ['loader.component.scss'],
    template: `
        <div class="row" *ngIf="isBusy">
            <div class="loader-container">
                <div class="sk-rotating-plane"></div>
            </div>
        </div>
        <div *ngIf="!isBusy">
            <ng-container *ngTemplateOutlet="detailRef"></ng-container>
        </div>
    `
})
export class LoaderComponent {
    @ContentChild(TemplateRef, { static: true }) detailRef;
    @Input() isBusy = true;
}
