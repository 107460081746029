import { UntypedFormControl, UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';
import { BaseDisplayModel } from '@common/models/baseDisplayModel';
import { requiredValidator } from '@common/validators/required-validator';
import { OrganizationDisplayModel } from './Organization.display.model';

export class UserDisplayModel extends BaseDisplayModel {
    id: number;
    userName: string;
    fullName: string;
    firstName: string;
    lastName: string;
    password: string;
    name: string;
    organizationId: string;
    userPerTerminalRuleActive: boolean;
    phoneNumber: string;
    email: string;
    academicTitle: string;
    professionalTitle: string;
    roles: any;
    active: boolean;
    emptyFormId: number;
    filledFormId: number;
    initialActivationFulfilled: boolean;
    certificateNumber: string;
    base64Content: string;
    base64ContentName: string;
    activationDate : any;
    userAttachments : any[];
    selectedLanguage: string;
    tac : boolean;
    isCU: boolean;
    iddNumber: string;
    twoFactorPreferenceId : string;
    relationSystemToOrganizationToTerminal: any[];

    constructor(data = null) {
        super();
        if (data) {
            Object.assign(this, data);
        }
    }

    generateGroup() {
        const form = new UntypedFormGroup({
            id: new UntypedFormControl({ value: null, disabled: true }),
            userName: new UntypedFormControl({ value: '', disabled: true }, requiredValidator()),
            firstName: new UntypedFormControl({ value: '', disabled: true }, requiredValidator()),
            middleName: new UntypedFormControl({ value: '', disabled: true }),
            lastName: new UntypedFormControl({ value: '', disabled: true }, requiredValidator()),
            password: new UntypedFormControl({ value: null, disabled: true }, requiredValidator()),
            confirmPassword: new UntypedFormControl({ value: null, disabled: true }, requiredValidator()),
            name: new UntypedFormControl({ value: null, disabled: true }),
            organizationId: new UntypedFormControl({ value: null, disabled: true }, requiredValidator()),
            phoneNumber: new UntypedFormControl({ value: null, disabled: true }),
            email: new UntypedFormControl({ value: null, disabled: true }, requiredValidator()),
            academicTitle: new UntypedFormControl({ value: null, disabled: true }),
            professionalTitle: new UntypedFormControl({ value: null, disabled: true }),
            roles: new UntypedFormControl({ value: null, disabled: true }),
            active: new UntypedFormControl({ value: false, disabled: true }),
            requestedUserAccessLevelId: new UntypedFormControl({ value: null, disabled: true }),
            userCategory: new UntypedFormControl({ value: null, disabled: true }),
            accessRequestReason: new UntypedFormControl({ value: null, disabled: true }),
            twoFactorPreferenceId: new UntypedFormControl({ value: null, disabled: true }, requiredValidator()),
            certificateNumber: new UntypedFormControl({ value: null, disabled: true }),
            selectedLanguage: new UntypedFormControl({value: null, disabled: true}, requiredValidator()),
            tac: new UntypedFormControl({value : false, disabled : true}),
            iddNumber: new UntypedFormControl({value: null, disabled : true})
        });
        form.patchValue(this);
        return form;
    }
}
