<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title">Delivery Order</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.close(null)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <app-loader [isBusy]="false">
                <ng-template>
                    <form [formGroup]="form" class="p-3">
                            <div class="row">
                                <div class="col">
                                    <form-dropdown formControlName="terminalId" labelText="{{ 'Terminal' | translate }}" [observableInput]="terminals$"></form-dropdown>
                                </div>
                                <div class="col">
                                    <form-dropdown formControlName="terminalOperatorId" labelText="{{ 'Terminal Operator' | translate }}" [observableInput]="terminalOperators$"
                                    [automaticPopulateWithFirstValue]="true"></form-dropdown>
                                </div>
                                <div class="col">
                                    <form-dropdown formControlName="berthId" labelText="{{ 'Berth' | translate }}" [observableInput]="berths$"></form-dropdown>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-3">
                                    <form-dropdown formControlName="typeId" labelText="{{ 'Type' | translate }}" codeListName="DeliveryOrderType"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="serviceProviderId" labelText="{{ 'Serivce Provider' | translate }}" codeListName="OrganizationsForDeliveryOrder"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-dropdown formControlName="launchOperatorId" labelText="{{ 'Launch Boat Operator' | translate }}" codeListName="LBOOrganization"></form-dropdown>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="meanOfTransport" labelText="{{ 'Mean Of Transport' | translate }}"></form-textbox>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-3">
                                    <form-datetime formControlName="deliveryTime" labelText="{{ 'Expected Delivery Time' | translate }}" ></form-datetime>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="mrn" labelText="{{ 'MRN' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="vesselMaster" labelText="{{ 'Vessel Master' | translate }}"></form-textbox>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <form-textbox formControlName="remarks" labelText="{{ 'Remarks' | translate }}"></form-textbox>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <h5>Item</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <form-textbox formControlName="name" labelText="{{ 'Name' | translate }}"></form-textbox>
                                </div>
                                <div class="col-9">
                                    <form-textbox formControlName="description" labelText="{{ 'Description' | translate }}"></form-textbox>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <form-number formControlName="quantity" labelText="{{ 'Quantity' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="grossWeight" labelText="{{ 'Gross Weight (kg)' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="netWeight" labelText="{{ 'Net Weight (kg)' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="volume" labelText="{{ 'Volume' | translate }}"></form-number>
                                </div>
                            </div>
                    </form>
                </ng-template>
            </app-loader>
        </div>

        <div class="modal-footer">
            <button class="btn btn-sm btn-danger" (click)="activeModal.close(false)" translate>Cancel</button>
            <button [disabled]="form.invalid" class="btn btn-sm btn-primary" (click)="addNewDeliveryOrder()" translate>Save</button>
        </div>
    </ng-template>
</app-loader>
