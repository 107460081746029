import { ControlValueAccessor, ValidationErrors, NgControl, FormControl } from '@angular/forms';
import { Input, OnInit, Directive } from '@angular/core';
import { ValidationMessageService } from '@common/services/validation-message.service';

@Directive()
export class AbstractFormControlComponent implements ControlValueAccessor {
    @Input()
    labelText: string;

    public value: any;
    touched: boolean;
    isDisabled: boolean;
    initialValue = null;
    initialValueExists = false;
    errors: ValidationErrors = [];

    onValidated = () => {};

    onChange = (delta: any) => {};

    onTouched = () => {
        this.touched = true;
    };

    constructor(public controlDir: NgControl, private validationMessageService: ValidationMessageService) {
        if (this.controlDir) {
            this.controlDir.valueAccessor = this;

            if (this.controlDir.control) {
                this.controlDir.control.updateValueAndValidity();
            }
        }
    }

    get isInvalid(): boolean {
        return !this.isDisabled && this.controlDir && !this.controlDir?.control?.valid;
    }

    get getFormControlValidators() {
        if(this.controlDir){
            return this.validationMessageService.getInterpolatedMessage(this.controlDir?.control?.errors);
        }
    }

    changeValue($event) {
        if (this.initialValueExists && this.initialValue === $event){
            this.touched = false;
            this.onChange($event);
            return;
        }
        if(!this.initialValueExists && !$event){
            this.touched = false;
            this.onChange($event);
            return;
        }
        if (this.initialValueExists && this.initialValue === null) {
            // when value set initialy it triggers change. this ignores first ever change
            this.initialValue = $event;
            this.onChange($event);
            return;
        }

        this.markTouched();
        this.onChange($event);
    }

    markTouched() {
        this.touched = true;
        // this.controlDir.control.parent.markAllAsTouched();
        this.onTouched();
    }

    writeValue(value: any) {
        if(value){
            this.initialValueExists = true;
        }
        this.value = value;
        this.onChange(value);
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    registerOnChange(fn: (v: any) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
}
