import { Injectable } from '@angular/core';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { templateSettings, template } from 'lodash-es';

templateSettings.interpolate = /{{([\s\S]+?)}}/g;

@Injectable({
  providedIn: 'root'
})
export class ValidationMessageService {

  constructor(private translateService: TranslateService) { }

  validationMessages = [
    {
      code: 'rfv01',
      text: this.translateService.instant(marker('Must not be empty.'))
    },
    {
      code: 'rfv02',
      text: this.translateService.instant(marker(`The length must be at least {{minLength}} characters. You entered {{enteredChars}} characters.`))
    },
    {
      code: 'rfv03',
      text: this.translateService.instant(marker(`The length must be {{maxLength}} characters or fewer. You entered {{enteredChars}} characters.`))
    },
    {
      code: 'rfv04',
      text: this.translateService.instant(marker(`Must be a positive number`))
    },
    {
      code: 'rfv05',
      text: this.translateService.instant(marker('Must be a number greater than 0.'))
    },
    {
      code: 'rfv06',
      text: this.translateService.instant(marker('The value must be greater than the value in field {{comparator}} '))
    },
    {
      code: 'rfv07',
      text: this.translateService.instant(marker('Entered number must be between the values of -2147483648 and 2147483647.'))
    },
    {
      code: 'rfv08',
      text: this.translateService.instant(marker('Entered text must be in valid format.'))
    }
  ];

  getInterpolatedMessage(messageValidators: any){
    if(messageValidators){

      var translatedValidationMessages = {}; // object with translated messages that are forwarded to ngbTooltip

      // looping through validator messages
      for(var key in messageValidators){
        if(messageValidators.hasOwnProperty(key)){
          var currentMessage = this.validationMessages.filter(x => x.code == messageValidators[key]['code']); // getting message based on code (ex. 'rfv01', 'rfv02', 'rfv03')

          var translatedText = this.translateMessage(currentMessage, messageValidators, key); // translating message based on key (ex. 'required', 'minLength', 'maxLength')

          translatedValidationMessages[key] = translatedText;
        }
      }

      return translatedValidationMessages;
    }
  }

  private translateMessage(currentMessage: any, messageValidators: any, key: string){
    var compiled = template(currentMessage[0]['text']);

    switch(key){
      case 'required':
        return currentMessage[0]['text'];
      case 'minLength':
        return compiled({ 'minLength': messageValidators[key].minLengthCharacters, 'enteredChars': messageValidators[key].enteredCharacters });
      case 'maxLength':
        return compiled({ 'maxLength': messageValidators[key].maxLengthCharacters, 'enteredChars': messageValidators[key].enteredCharacters });
      case 'valueNotGreaterThan':
        return compiled({ 'comparator': messageValidators[key].comparator });
      default:
        return currentMessage[0]['text'];
    }
  }
}
