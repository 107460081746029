import { Component } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
    template: `
        <div class="modal-header">
            <h4 class="modal-title">{{ title }}</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" [innerHTML]="message">
        </div>
        <div class="modal-footer" *ngIf="yesButtonName || noButtonName || cancelButtonName; else defaultModal">
            <button class="btn btn-sm btn-primary"  (click)="activeModal.close(true)" *ngIf="yesButtonName" >{{yesButtonName}}</button>
            <button class="btn btn-sm btn-secondary" (click)="activeModal.close(false)" *ngIf="noButtonName" >{{noButtonName}}</button>
            <button class="btn btn-sm btn-secondary" (click)="activeModal.close(false)" *ngIf="cancelButtonName" >{{cancelButtonName}}</button>
        </div>
        <ng-template #defaultModal>
            <div class="modal-footer">
                <button class="btn btn-sm btn-primary"  (click)="activeModal.close(true)" translate>Ok</button>
                <button class="btn btn-sm btn-secondary" (click)="activeModal.close(false)" translate>Cancel</button>
            </div>
        </ng-template>
    `,
})
export class ConfirmDialogComponent {
    public title: string;
    public message: string;
    public yesButtonName: string;
    public noButtonName: string;
    public cancelButtonName: string;

    constructor(public activeModal: DialogRef) {

    }
}
