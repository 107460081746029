import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

/**
 * Component for open id redirect. Special component is required without guards.
 */
@Component({
    template:''
})
export class LoginOidcCallbackComponent implements OnInit {

    constructor(private router: Router) {

    }

    ngOnInit(): void {
        this.router.navigate(["/home"]);
    }
    
}