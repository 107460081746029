<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>

    <button type="button" class="close" aria-label="Close" [disabled]="isBusy" (click)="activeModal.close(null)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>


<div>
    <kendo-fileselect [restrictions]="attachmentFileUploadRestrictions" [multiple]="true"
        (select)="fileSelected($event)" (remove)="fileRemoved($event)">
    </kendo-fileselect>
</div>


<div class="modal-footer">
    <button class="btn btn-sm btn-primary" *ngIf="files.length > 0" [disabled]="isBusy"
        (click)="finish()">{{ yesButtonName }}</button>
    <button class="btn btn-sm btn-secondary" [disabled]="isBusy" (click)="activeModal.close(null)" translate>Cancel</button>
</div>