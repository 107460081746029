import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    NgModule,
    ErrorHandler,
    LOCALE_ID,
    APP_INITIALIZER,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    RouterModule,
    Router,
    NavigationStart,
    NavigationError,
    NavigationEnd,
    NavigationCancel,
} from '@angular/router';
import {
    TranslateModule,
    TranslateLoader,
    TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CommonModule } from './common/common.module';
import { AuthGuard } from './common/guards/auth.guard';
import { AuthorizationGuard } from './common/guards/authorization.guard';

import { AppComponent } from './app.component';
import { HomeComponent } from './home.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ROUTES } from './app.routing';

import localeSl from '@angular/common/locales/sl';
import { registerLocaleData } from '@angular/common';
import { GlobalErrorHandler } from './common/global-error-handler';
import { UploadModule } from '@progress/kendo-angular-upload';

import { IntlModule } from '@progress/kendo-angular-intl';
import '@progress/kendo-angular-intl/locales/sl/all';

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { UserService } from '@common/services/user.service';

import {
    AuthModule,
    LogLevel,
    OidcConfigService,
} from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';
import { StartupService } from '@common/services/startup.service';
import { MessageService } from '@progress/kendo-angular-l10n';
import { KendoMessageService } from '@common/services/kendoMessage.service';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {DatetimerangepickerModule} from 'angular-datetimerangepicker';
import { UserIdleModule } from 'angular-user-idle';
import { DialogRef } from '@progress/kendo-angular-dialog';

registerLocaleData(localeSl);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

export function appInitFactory(
    oidcConfigService: OidcConfigService,
    startupService: StartupService
): Function {
    return () => {
        startupService.init();
    };
}

@NgModule({
    declarations: [AppComponent, HomeComponent],
    imports: [
        BrowserModule,
        DatetimerangepickerModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LoadingBarModule,
        RouterModule.forRoot(ROUTES, {
            initialNavigation: 'disabled',
            relativeLinkResolution: 'legacy',
        }),

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),

        CommonModule,
        UploadModule,

        DateInputsModule,
        InputsModule,

        IntlModule,
        AuthModule.forRoot(),
        NgbModule,
        NgbDropdownModule,
        UserIdleModule.forRoot({idle: 900, timeout: 900, ping: 100})
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        LoadingBarModule,
        BrowserAnimationsModule,
        RouterModule,
        DateInputsModule,
        InputsModule,
    ],
    providers: [
        UserService,
        { provide: LOCALE_ID, useValue: 'sl-SI' }, // provide this based on users locale? if not possible put observable here? or not use this variable at all and do this differently
        { provide: MessageService, useClass: KendoMessageService },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        OidcConfigService,
        StartupService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitFactory,
            deps: [OidcConfigService, StartupService],
            multi: true,
        },
        DialogRef
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        private authGuard: AuthGuard,
        private authorizationGuard: AuthorizationGuard,
        private router: Router,
        private translate: TranslateService,
        private http: HttpClient,
        private loadingBarService: LoadingBarService
    ) {
        const routerRef = this.loadingBarService.useRef('router');
        router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                routerRef.start();
            } else if (event instanceof NavigationError) {
                routerRef.complete();
                console.error(event.error);
            } else if (event instanceof NavigationEnd) {
                routerRef.complete();
            } else if (event instanceof NavigationCancel) {
                routerRef.complete();
            }
        });

        const defaultLang = 'en';
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang(defaultLang);

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use(defaultLang);
    }
}
