import { AfterViewInit, Directive, ElementRef, ViewChild } from "@angular/core";

@Directive({
    selector: "[autofocus]",
})
export class AutofocusDirective implements AfterViewInit {
    @ViewChild('myInput') input: ElementRef;

    constructor(private element: ElementRef) {}

    ngAfterViewInit() {

        setTimeout(() => {
            this.element.nativeElement.querySelector("input")?.focus();
        });
    }
}
