import { VesselVisitService } from './../../services/vessel-visit.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { CodelistService } from '@common/services/codelist.service';
import { DeliveryOrderService } from '@common/services/delivery-order.service';
import { ModalService } from '@common/services/modal.service';
import { NominatedAgentService } from '@common/services/nominated-agent.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { DeliveryOrderListDisplayModel } from 'app/vessel-visit/delivery-order-list/models/DeliveryOrders.display.model';
import { ToastrService } from 'ngx-toastr';
import { filter, Observable, takeUntil } from 'rxjs';

@Component({
  selector: 'app-edit-delivery-order',
  templateUrl: './edit-delivery-order.component.html',
  styleUrls: ['./edit-delivery-order.component.scss']
})
export class EditDeliveryOrderComponent implements OnInit {

    isBusy: boolean;
    deliveryOrderModel: DeliveryOrderListDisplayModel = new DeliveryOrderListDisplayModel();
    vesselVisitModel: any;
    form: UntypedFormGroup;
    deliveryOrderId = 0;
    vesselVisitId = 0;
    terminals$: Observable<any>;
    terminalOperators$: Observable<any>;
    berths$: Observable<any>;
    systems$: Observable<any>;
    constructor(
        public dialogService: ModalService,
        public activeModal: DialogRef,
        private translateService: TranslateService,
        private toastyService: ToastrService,
        public service: DeliveryOrderService,
        private vesselVisitService: VesselVisitService,
        private activatedRoute: ActivatedRoute,
        public codelistService: CodelistService
    ) { }

    ngOnInit() {
        this.getSingleVisit();
        this.form = this.deliveryOrderModel.generateGroup();
        this.form.get('vesselVisitId').setValue(this.vesselVisitId);
        this.form.get('id').setValue(this.deliveryOrderId);

    }


    addNewDeliveryOrder() {
        this.dialogService.confirm(
            this.translateService.instant(marker("Confirm")) as string, 
            this.translateService.instant(marker("Are you sure you want to add the delivery order item?")) as string,
            this.translateService.instant(marker("YES")) as string,
            this.translateService.instant(marker("No")) as string
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)))
            .subscribe(res => {
                const body = this.form.getRawValue();
                this.service.addDeliveryOrder(body).subscribe(() => {
                    this.toastyService.success(this.translateService.instant(marker('Delivery order created')));
                    this.activeModal.close(body);
                });
            });
    }

    getSingleVisit() {
        this.vesselVisitService.getSingleVisit(this.vesselVisitId).subscribe((res) => {
            this.vesselVisitModel = res;
            this.trackFromChanges();
        });
    }

    private trackFromChanges() {
        if (this.vesselVisitModel.portFacilityTerminalId) this.form.get('terminalId').setValue(this.vesselVisitModel.portFacilityTerminalId);
        this.terminals$ = this.service.getTerminalsWithinSamePort(this.vesselVisitModel.portName.id);
        this.form.get('terminalId').valueChanges.subscribe(res => {
            this.terminalOperators$ = this.service.getTerminalOperatorsBasedOnTerminal(res);
            if (res && res.length === 1) {
                this.form.get('terminalOperatorId').setValue(res[0].id);
            }

            this.berths$ = this.service.getBerthsBasedOnTerminal(res);
        })
        if (this.vesselVisitModel.vesselMaster) this.form.get('vesselMaster').setValue(this.vesselVisitModel.vesselMaster);
        if (this.vesselVisitModel.berthId) this.form.get('berthId').setValue(this.vesselVisitModel.berthId);
    }
}
