<div class="form-group" [ngClass]="{'has-value' : hasValue && AppControlType.Boolean !== type, 'mb-0':!margin}">
    <div *ngIf="contextual">
        <tooltip orientation="left" text="{{ contextual | translate }}"></tooltip>
    </div>
    <label class="col-form-label" *ngIf="label && type!== AppControlType.Boolean && !(type=== AppControlType.CodeList && multi)"
    [ngClass]="{ 'label-star': !isDisabled && nullable === false, 'label-bg' : type === AppControlType.TextArea, 'disabled-bg' : isDisabled }" [for]="property">
        {{label}}
    </label>

    <input
        *ngIf="!static && type === AppControlType.String"
        kendoTextBox
        type="text"
        class="k-textbox form-control form-control-sm"
        [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
        [disabled]="isDisabled"
        [(ngModel)]="value"
        placement="top"
        placeholder="{{placeholder}}"
        tooltipClass="error-tooltip"
        (keydown)="handleTextboxKeydown($event)"
        (focus)="multiSelectFocusInHandler($event)"
        (blur)="multiSelectFocusOutHandler($event)"
    />

    <textarea
        *ngIf="!static && type === AppControlType.Textarea"
        kendoTextArea
        [autoSize]="true"
        type="text"
        class="k-textarea form-control form-control-sm"
        [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
        [disabled]="isDisabled"
        [(ngModel)]="value"
        placement="top"
        placeholder="{{placeholder}}"
        tooltipClass="error-tooltip"
        (focus)="multiSelectFocusInHandler($event)"
        (blur)="multiSelectFocusOutHandler($event)"
    ></textarea>

    <div *ngIf="!static && type === AppControlType.Password" class="input-group">
        <input
            *ngIf="!static && type === AppControlType.Password"
            kendoTextBox
            [type]="fieldTextType ? 'text' : 'password'"
            placeholder="{{placeholder}}"
            class="k-textbox form-control  form-control-sm"
            [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
            [disabled]="isDisabled"
            [(ngModel)]="value"
            placement="top"
            tooltipClass="error-tooltip"
            [autocomplete]="passwordAutocomplete"
            (focus)="multiSelectFocusInHandler($event)"
            (blur)="multiSelectFocusOutHandler($event)"
        />
        <span class = "eye-icon" *ngIf="!static && type === AppControlType.Password" tabindex="0">
            <i class="fa"
                [ngClass]="{'fa-eye-slash': !fieldTextType,'fa-eye': fieldTextType}"
                (click)="toggleFieldTextType()" >
            </i>
        </span>
    </div>

    <input
        *ngIf="!static && type === AppControlType.Number && decimal === false && forFilter === false"
        type="text"
        class="form-control form-control-sm"
        [(ngModel)]="value"
        placeholder="{{placeholder}}"
        [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
        [disabled]="isDisabled"
        placement="top"
        pattern="[0-9]"
        tooltipClass="error-tooltip"
        (focus)="multiSelectFocusInHandler($event)"
        (blur)="multiSelectFocusOutHandler($event)"
        mask="separator.0" thousandSeparator="."
    />

    <input
        *ngIf="!static && type === AppControlType.Number && decimal === true && forFilter === false"
        type="text"
        class="form-control form-control-sm"
        [(ngModel)]="value"
        [min]="min"
        placeholder="{{placeholder}}"
        [max]="max"
        [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
        [disabled]="isDisabled"
        step="0.01"
        placement="top"
        tooltipClass="error-tooltip"
        (focus)="multiSelectFocusInHandler($event)"
        (blur)="multiSelectFocusOutHandler($event)"
        mask="separator.3" thousandSeparator="." decimalMarker=","
    />

    <input
        *ngIf="!static && type === AppControlType.Number && forFilter === true && decimal === false"
        type="number"
        class="form-control form-control-sm"
        [(ngModel)]="value"
        placeholder="{{placeholder}}"
        [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
        [disabled]="isDisabled"
        placement="top"
        tooltipClass="error-tooltip"
        (focus)="multiSelectFocusInHandler($event)"
        (blur)="multiSelectFocusOutHandler($event)"
    />

    <div *ngIf="!static && type === AppControlType.DateTime && kendoType === 'date'">
        <i
            placement="bottom"
            *ngIf="dateValue && !isDisabled"
            (click)="dateValue = null"
            [ngClass]="{ 'date-time-close-table': isInTable }"
            class="k-icon k-clear-value k-i-close date-time-close"
        ></i>
        <kendo-datepicker
            class="form-control form-control-sm noborder pcs-control"
            #datePicker
            [navigation]="false"
            [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
            [disabled]="isDisabled"
            [(ngModel)]="dateValue"
            placeholder=""
            placement="top"
            tooltipClass="error-tooltip"
            format="dd.MM.yyyy"
            [formatPlaceholder]="dateFormatPlaceholder"
            (valueChange)="dateTimePickerValueChange($event)"
            (focus)="multiSelectFocusInHandler($event)"
            (blur)="multiSelectFocusOutHandler($event)"
        >
        </kendo-datepicker>
    </div>

    <div *ngIf="!static && type === AppControlType.DateTime && kendoType === 'datetime'">
        <i
            placement="bottom"
            *ngIf="dateValue && !isDisabled"
            (click)="dateValue = null"
            [ngClass]="{ 'date-time-close-table': isInTable }"
            class="k-icon k-clear-value k-i-close date-time-close noborder"
        ></i>
        <kendo-datetimepicker
            #dateTimePicker
            class="k-datetimepicker noborder form-control form-control-sm pcs-control"
            [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
            [disabled]="isDisabled"
            [(ngModel)]="dateValue"
            placeholder=""
            placement="top"
            tooltipClass="error-tooltip"
            format="dd.MM.yyyy HH:mm"
            [formatPlaceholder]="dateTimeFormatPlaceholder"
            (valueChange)="dateTimePickerValueChange($event)"
            (focus)="multiSelectFocusInHandler($event)"
            (blur)="multiSelectFocusOutHandler($event)"
        >
        </kendo-datetimepicker>
    </div>

    <div *ngIf="!static && type === AppControlType.DateTime && kendoType === 'time'">
        <i
            placement="bottom"
            *ngIf="dateValue && !isDisabled"
            (click)="dateValue = null"
            [ngClass]="{ 'date-time-close-table': isInTable }"
            class="k-icon k-clear-value k-i-close date-time-close noborder"
        ></i>
        <kendo-timepicker
            class="noborder form-control form-control-sm pcs-control"
            [ngClass]="{ 'is-invalid': errors.length > 0 || isInvalid }"
            [disabled]="isDisabled"
            [(ngModel)]="dateValue"
            placeholder=""
            placement="top"
            tooltipClass="error-tooltip"
            format="HH:mm"
            [formatPlaceholder]="timeFormatPlaceholder"
            (focus)="multiSelectFocusInHandler($event)"
            (blur)="multiSelectFocusOutHandler($event)"
        >
        </kendo-timepicker>
    </div>

    <!-- <app-checkbox
        *ngIf="!static && type === AppControlType.Boolean"
        [size]="size"
        [(ngModel)]="value"
        [disabled]="isDisabled"
    ></app-checkbox> -->
    <app-checkbox *ngIf="type === AppControlType.Boolean" (keyup.enter)="onKeyUp($event)" [label]="label"
    [size]="size" [(ngModel)]="value" [disabled]="isDisabled" [click]="click"></app-checkbox>
    <div
        *ngIf="type === AppControlType.Static"
        class="form-control-plaintext form-control-sm"
    >
        {{ value }}
    </div>

    <div class="invalid-feedback" *ngIf="showFeedback && errors.length > 0">
        {{ errors[0].errorMessage }}
    </div>
</div>

