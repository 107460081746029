import { Injectable } from '@angular/core';
import { ChangesDialogComponent } from '@common/components/changes-dialog.component';
import { PdfSignerModalComponent } from '@common/components/pdf-viewer/pdf-signer-modal.component';
import { ServiceRequestItemModalTypes } from '@common/knownTypes/ServiceRequestItemModalTypes';
import { ViewMode } from '@common/models/view-mode';
import { TranslateService } from '@ngx-translate/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { LandServiceRequestEditDisplayModel } from 'app/tos-operations/models/LandServiceRequestEdit.display.model';
import { firstValueFrom, Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../components/confirm-dialog';
import { AddDtsComponent } from '@common/modals/add-dts/add-dts.component';
import { UpdateDtsComponent } from '@common/modals/update-dts/update-dts.component';
import {AddCargoItemComponent} from '@common/modals/add-cargo-item/add-cargo-item.component';
import {EditCargoItemComponent} from '@common/modals/edit-cargo-item/edit-cargo-item.component';
import { FileUploadGenericModalComponent } from '@common/components/file-upload-generic-modal/file-upload-generic-modal-component';
import { EditDeliveryOrderComponent } from '@common/modals/edit-delivery-order/edit-delivery-order.component';
import { AddDeliveryOrderItemComponent } from '@common/modals/add-delivery-order-item/add-delivery-order-item.component';
import { ErrorDialogComponent } from '@common/error-dialog.component';
import { ServiceRequestsDeclarationTabComponent } from 'app/customs/tabs/service-requests-declaration-tab/service-requests-declaration-tab.component';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { AddVesselComponent } from 'app/vessel/modals/add-vessel/add-vessel.component';
import { AuditLogModalComponent } from '@administration/audit-logs/modals/audit-log-modal/audit-log-modal.component';
import { AddFaqModalComponent } from '@administration/help/modals/add-faq-modal/add-faq-modal.component';
import { AddDocumentModalComponent } from '@administration/help/modals/add-document-modal/add-document-modal.component';

/**
 * Kendo dialog does not have s/lg/xl modal sizes like bootstrap, width and height need to be specified
 */
export enum ModalWidth {
    sm = '30%',
    lg = '50%',
    xl = '70%',
    xxl = '90%'
}
@Injectable({
    providedIn: 'root',
})
export class ModalService {
    constructor(private modalService: DialogService, private translateService: TranslateService) {}

    public confirm(title: string, message: string, yesButtonName?: string, noButtonName?: string, cancelButtonName?: string): Observable<any> {
        const dialogRef = this.modalService.open({content: ConfirmDialogComponent, width: ModalWidth.sm});
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public changes(title: string, message: string): Observable<any> {
        const dialogRef = this.modalService.open({content: ChangesDialogComponent});
        dialogRef.content.instance.title = title;
        dialogRef.content.instance.message = message;

        return dialogRef.result;
    }

    public signDocument(file: any, title: string, id: number, selectedCertificate: string) {
        const dialogRef = this.modalService.open({content: PdfSignerModalComponent, width: ModalWidth.xl});
        dialogRef.content.instance.file = file;
        dialogRef.content.instance.id = id;
        dialogRef.content.instance.selectedCertificate = selectedCertificate;

        this.setCommonProperties(dialogRef, title, '', this.translateService.instant(marker("Sign")), null, this.translateService.instant(marker("Cancel")));

        return dialogRef.result;
    }

    public addNominatedAgent(
        modalComponent: any,
        vesselVisitId: number,
        agentsCurrentlyInTab: any[],
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xl})
        dialogRef.content.instance.agentsCurrentlyInTab = agentsCurrentlyInTab;
        dialogRef.content.instance.vesselVisitId = vesselVisitId;

        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public addDtsItem(): Observable<any> {
        const dialogRef = this.modalService.open({content: AddDtsComponent, width: ModalWidth.xl});

        this.setCommonProperties(dialogRef, this.translateService.instant(marker("Add DTS Item")), '', this.translateService.instant(marker("Add")), null, this.translateService.instant(marker("Cancel")));

        return dialogRef.result;
    }

    public addCargoItem(manifestId, containerized, forTosSystem, isArrival): Observable<any> {
        const dialogRef = this.modalService.open({content: AddCargoItemComponent, width: ModalWidth.xl});
        dialogRef.content.instance.containerized = containerized;
        dialogRef.content.instance.manifestId = manifestId;
        dialogRef.content.instance.isArrival = isArrival;
        dialogRef.content.instance.forTosSystem = forTosSystem;
        // dialogRef.content.instance.codeLists = codeLists;

        this.setCommonProperties(dialogRef, this.translateService.instant(marker("Add cargo Item")), '', this.translateService.instant(marker("Add")), null, this.translateService.instant(marker("Cancel")));

        return dialogRef.result;
    }

    public editCargoItem(containerized, saveModel, forTosSystem, isArrival): Observable<any> {
        const dialogRef = this.modalService.open({content: EditCargoItemComponent, width: ModalWidth.xl});
        dialogRef.content.instance.containerized = containerized;
        dialogRef.content.instance.saveModel = saveModel;
        dialogRef.content.instance.isArrival = isArrival;
        dialogRef.content.instance.forTosSystem = forTosSystem;
        // dialogRef.content.instance.codeLists = codeLists;

        this.setCommonProperties(dialogRef, this.translateService.instant(marker("Edit cargo Item")), '', this.translateService.instant(marker("Add")), null, this.translateService.instant(marker("Cancel")));

        return dialogRef.result;
    }

    public editDtsItem(id: number, model: any): Observable<any> {
        const dialogRef = this.modalService.open({content: UpdateDtsComponent, width: ModalWidth.xl});

        this.setCommonProperties(dialogRef, this.translateService.instant(marker("Edit DTS Item")), '', this.translateService.instant(marker("Edit")), null, this.translateService.instant(marker("Cancel")));
        dialogRef.content.instance.dtsId = id;
        dialogRef.content.instance.saveModel = model;
        return dialogRef.result;
    }

    public addTosToNominatedAgent(
        modalComponent: any,
        nominatedAgentId: number,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})

        const d = this.modalService.open({content: modalComponent, width: ModalWidth.lg});
        dialogRef.content.instance.nominatedAgentId = nominatedAgentId;

        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public addTosVoyageNumber(
        modalComponent: any,
        nominatedAgentId: number,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.nominatedAgentId = nominatedAgentId;

        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public navisLoginModal(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})

        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public changeNavisPasswordModal(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})

        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public CompareChangesCargoListModal(
        modalComponent: any,
        unmatchingCargoList: any[],
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Promise<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.unmatchingCargoList = unmatchingCargoList;

        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return firstValueFrom(dialogRef.result);
    }

    public cargoListContainerSumModal(
        modalComponent: any,
        agentType: string,
        agentTypeId: number,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Promise<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.agentType = agentType;
        dialogRef.content.instance.agentTypeId = agentTypeId;

        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return firstValueFrom(dialogRef.result);
    }

    public approveOrderedInsertCustomsCtrNbr(
        modalComponent: any,
        title: string,
        message: string,
        serviceRequestId?: number,
        id?: number,
        type?: any,
        srSum?: any,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.id = id;
        dialogRef.content.instance.type = type;
        dialogRef.content.instance.srSum = srSum;

        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public addServiceRequestMotItem(
        modalComponent: any,
        title: string,
        message: string,
        serviceRequestId: number,
        lastSequenceNumber: number,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.lastSequenceNumber = lastSequenceNumber;

        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public addLandServiceRequest(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        return dialogRef.result;
    }

    public sendServiceRequestToTerminal(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        confirmationText?: string,
        serviceRequestId?: any,
        errors?: string[],
        warnings?: string[]
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.errors = errors;
        dialogRef.content.instance.warnings = warnings;
        dialogRef.content.instance.confirmationText = confirmationText;
        return dialogRef.result;
    }

    public acceptServiceRequest(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        serviceRequestId?: any,
        requestedStartDateTime?: any
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.requestedStartDateTime = requestedStartDateTime;
        return dialogRef.result;
    }

    public completeServiceRequest(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        serviceRequestId?: any,
        serviceRequestVersionShipments?: any[],
        serviceRequestVersionContainers?: any[],
        allowCompletingFromAPTStatus?: boolean,
        showSummary?: boolean
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.serviceRequestVersionShipments = serviceRequestVersionShipments;
        dialogRef.content.instance.serviceRequestVersionContainers = serviceRequestVersionContainers;
        dialogRef.content.instance.allowCompletingFromAPTStatus = allowCompletingFromAPTStatus;
        dialogRef.content.instance.showSummary = showSummary;
        return dialogRef.result;
    }

    public declineServiceRequest(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        serviceRequestId?: any
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        return dialogRef.result;
    }

    public cancelServiceRequest(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        serviceRequestId?: any
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        return dialogRef.result;
    }

    public holdServiceRequestItems(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        serviceRequestVersionId?: number,
        serviceRequestItemIds?: any[],
        shipmentItemIds?: any[],
        containerItemIds?: any[],
        containerVisitIds?: any[],
        shipmentPoolIds?: any[],
        shipmentPoolItemIds?: any[],
        isUnholdAction?: boolean,
        shipmentType?: string,
        activeHoldTypeIds?: any[],
        isUnholdRoleIdAvailable?: boolean
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.serviceRequestVersionId = serviceRequestVersionId;
        dialogRef.content.instance.serviceRequestItemIds = serviceRequestItemIds;
        dialogRef.content.instance.shipmentItemIds = shipmentItemIds;
        dialogRef.content.instance.containerItemIds = containerItemIds;
        dialogRef.content.instance.containerVisitIds = containerVisitIds;
        dialogRef.content.instance.shipmentPoolIds = shipmentPoolIds;
        dialogRef.content.instance.shipmentPoolItemIds = shipmentPoolItemIds;
        dialogRef.content.instance.isUnholdAction = isUnholdAction;
        dialogRef.content.instance.shipmentType = shipmentType;
        dialogRef.content.instance.activeHoldTypeIds = activeHoldTypeIds;
        dialogRef.content.instance.isUnholdRoleIdAvailable = isUnholdRoleIdAvailable;

        return dialogRef.result;
    }

    public confirmMoveServiceRequestItems(
        modalComponent: any,
        title: string,
        message: string,
        containerItemIds: any[],
        serviceRequestId: string,
        manipulation: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.sm})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.containerItemIds = containerItemIds;
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.manipulation = manipulation;

        return dialogRef.result;
    }

    public createCustomInspection(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        serviceRequestItemId?: any[]
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.serviceRequestItemId = serviceRequestItemId;
        return dialogRef.result;
    }

    public cancelStorageDocument(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        storageDocumentId?: any
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.storageDocumentId = storageDocumentId;
        return dialogRef.result;
    }

    public cancelAmendmentDocument(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        amendmentDocumentId?: any
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.amendmentDocumentId = amendmentDocumentId;
        return dialogRef.result;
    }

    public editCargoDamageRemark(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        remark?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.remark = remark;
        return dialogRef.result;
    }

    public userActivate(
        modalComponent: any,
        userId: number,
        title: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xl})
        dialogRef.content.instance.userId = userId;
        this.setCommonProperties(dialogRef, title, '', yesButtonName, noButtonName, cancelButtonName);
        return dialogRef.result;
    }

    public addLandServiceRequestRelation(
        modalComponent: any,
        title: string,
        srManipulation: string,
        fullContainers: boolean,
        forTOSSystem: any,
        serviceRequestType: string,
        serviceRequestId: number,
        message: string,
        relationData?: any,
        mode?: ServiceRequestItemModalTypes,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xl})
        dialogRef.content.instance.srManipulation = srManipulation;
        dialogRef.content.instance.fullContainers = fullContainers;
        dialogRef.content.instance.forTOSSystem = forTOSSystem;
        dialogRef.content.instance.serviceRequestType = serviceRequestType;
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.dataForEditing = relationData;
        dialogRef.content.instance.mode = mode;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        return dialogRef.result;
    }

    public addOnExistingCargoListFromContainerVisitModal(
        modalComponent: any,
        agentType: string,
        agentTypeId: number,
        full: boolean,
        cargoDirection: string,
        tosServiceRequestNumber: string,
        serviceRequestId: number,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Promise<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xl})
        dialogRef.content.instance.agentType = agentType;
        dialogRef.content.instance.agentTypeId = agentTypeId;
        dialogRef.content.instance.full = full;
        dialogRef.content.instance.cargoDirection = cargoDirection;
        dialogRef.content.instance.tosServiceRequestNumber = tosServiceRequestNumber;
        dialogRef.content.instance.serviceRequestId = serviceRequestId;

        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return firstValueFrom(dialogRef.result);
    }

    public removeContainerFromCLAndSrModal(
        modalComponent: any,
        cargoDirection: string,
        tosServiceRequestNumber: string,
        cargoListGridView: any,
        serviceRequestId: number,
        isAdmin: boolean,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Promise<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.cargoDirection = cargoDirection;
        dialogRef.content.instance.tosServiceRequestNumber = tosServiceRequestNumber;
        dialogRef.content.instance.cargoListGridView = cargoListGridView;
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.isAdmin = isAdmin;

        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return firstValueFrom(dialogRef.result);
    }

    public updateAppointmentModal(
        modalComponent: any,
        serviceRequest: LandServiceRequestEditDisplayModel,
        serviceRequestRelation: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xl})

        dialogRef.content.instance.serviceRequest = serviceRequest;
        dialogRef.content.instance.serviceRequestRelation = serviceRequestRelation;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public editVesselServiceRequestRelation(
        modalComponent: any,
        title: string,
        forTOSSystem: any,
        serviceRequestType: string,
        serviceRequestId: number,
        message: string,
        dataForEditing?: any,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xl})
        dialogRef.content.instance.forTOSSystem = forTOSSystem;
        dialogRef.content.instance.serviceRequestType = serviceRequestType;
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.dataForEditing = dataForEditing;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        return dialogRef.result;
    }

    public updateServiceOrderModal(
        modalComponent: any,
        serviceRequest: LandServiceRequestEditDisplayModel,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})

        dialogRef.content.instance.serviceRequest = serviceRequest;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public addManifestAttachmentModal(
        modalComponent: any,
        manifest: string,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})

        dialogRef.content.instance.manifest = manifest;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public editCustomsClearingOnTerminalData(
        modalComponent: any,
        serviceRequest: LandServiceRequestEditDisplayModel,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xl})

        dialogRef.content.instance.serviceRequest = serviceRequest;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public customsControlNumberOnManifestModal(
        modalComponent: any,
        customsControlNbrType: string,
        customsControlNumber: string,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})

        dialogRef.content.instance.customsControlNbrType = customsControlNbrType;
        dialogRef.content.instance.customsControlNumber = customsControlNumber;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public changeLanguage(
        currentLanguage: any,
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.sm})

        dialogRef.content.instance.currentLanguage = currentLanguage;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public containerBlockUnblock(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})

        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public updateMrn(
        modalComponent: any,
        title: string,
        message: string,
        currentMrn: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.currentMrn = currentMrn;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public updateCustomsStatus(
        modalComponent: any,
        title: string,
        message: string,
        currentCustomsStatus: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.currentCustomsStatus = currentCustomsStatus;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public openNavisConsigneeListModal(modalComponent: any, title: string, message: string, navisUser: any): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.navisUser = navisUser;

        this.setCommonProperties(dialogRef, title, message);

        return dialogRef.result;
    }

    public openSrlistModal(mrnId: number, mrn: string): Observable<any> {
    const dialogRef = this.modalService.open({content: ServiceRequestsDeclarationTabComponent, width: ModalWidth.xl})
        dialogRef.content.instance.mrnId = mrnId;
        dialogRef.content.instance.mrn = mrn;
        dialogRef.content.instance.isModal = true;
        this.setCommonProperties(dialogRef, this.translateService.instant(marker("SR List")),  '', this.translateService.instant(marker("Add")), null, this.translateService.instant(marker("Cancel")));
        return dialogRef.result;
    }

    public approveRealizedTrainServiceRequest(
        modalComponent: any,
        title: string,
        message: string,
        srSum?: any,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.srSum = srSum;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public openMotOnServiceRequestModal(
        modalComponent: any,
        dataForEditing: any,
        mode: string,
        title: string,
        message: string,
        motType: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.dataForEditing = dataForEditing;
        dialogRef.content.instance.mode = mode;
        dialogRef.content.instance.motType = motType;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public selectShipmentPoolModal(
        modalComponent: any,
        title: string,
        message: string,
        serviceRequestId: number,
        manipulation: string,
        shipmentTypeId: string,
        serviceRequestServiceTypeId: string,
        serviceRequestTosSystemId: string,
        serviceRequestSecondaryTosSystemId: string,
        customer: string,
        containUnitSpecification: boolean,
        containsRepackAdditionalService: boolean,
        containsTramakAdditionalService: boolean,
        containsCuswhAdditionalService: boolean,
        isAddsDeconCase: boolean,
        showCargoMeasureProperties: boolean,
        showSaveAndLinkMOTButton: boolean,
        lrn: string,
        mrn: number,
        mrnString: string,
        mrnItem: number,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xl})
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.manipulation = manipulation;
        dialogRef.content.instance.shipmentTypeId = shipmentTypeId;
        dialogRef.content.instance.serviceRequestServiceTypeId = serviceRequestServiceTypeId;
        dialogRef.content.instance.serviceRequestTosSystemId = serviceRequestTosSystemId;
        dialogRef.content.instance.serviceRequestSecondaryTosSystemId = serviceRequestSecondaryTosSystemId;
        dialogRef.content.instance.customer = customer;
        dialogRef.content.instance.containUnitSpecification = containUnitSpecification;
        dialogRef.content.instance.containsRepackAdditionalService = containsRepackAdditionalService;
        dialogRef.content.instance.containsTramakAdditionalService = containsTramakAdditionalService;
        dialogRef.content.instance.containsCuswhAdditionalService = containsCuswhAdditionalService;
        dialogRef.content.instance.isAddsDeconCase = isAddsDeconCase;
        dialogRef.content.instance.showCargoMeasureProperties = showCargoMeasureProperties;
        dialogRef.content.instance.showSaveAndLinkMOTButton = showSaveAndLinkMOTButton;
        dialogRef.content.instance.lrn = lrn;
        dialogRef.content.instance.mrn = mrn;
        dialogRef.content.instance.mrnString = mrnString;
        dialogRef.content.instance.mrnItem = mrnItem;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public createShipmentPoolModal(
        modalComponent: any,
        title: string,
        message: string,
        serviceRequestId: number,
        manipulation: string,
        shipmentTypeId: string,
        serviceRequestTosSystemId: string,
        serviceRequestSecondaryTosSystemId: string,
        serviceRequestServiceTypeId: string,
        customer: string,
        mode: ViewMode,
        containUnitSpecification: boolean,
        containsRepackAdditionalService: boolean,
        containsTramakAdditionalService: boolean,
        containsCuswhAdditionalService: boolean,
        isAddsDeconCase: boolean,
        showCargoMeasureProperties: boolean,
        showSaveAndLinkMOTButton: boolean,
        lrn: string,
        mrn: number,
        mrnString: string,
        mrnItem: number,
        shipmentPoolId?: number,
        serviceRequestVersionShipmentId?: number,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xl})
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.manipulation = manipulation;
        dialogRef.content.instance.shipmentTypeId = shipmentTypeId;
        dialogRef.content.instance.serviceRequestTosSystemId = serviceRequestTosSystemId;
        dialogRef.content.instance.serviceRequestSecondaryTosSystemId = serviceRequestSecondaryTosSystemId;
        dialogRef.content.instance.serviceRequestServiceTypeId = serviceRequestServiceTypeId;
        dialogRef.content.instance.customer = customer;
        dialogRef.content.instance.mode = mode;
        dialogRef.content.instance.containUnitSpecification = containUnitSpecification;
        dialogRef.content.instance.containsRepackAdditionalService = containsRepackAdditionalService;
        dialogRef.content.instance.containsTramakAdditionalService = containsTramakAdditionalService;
        dialogRef.content.instance.containsCuswhAdditionalService = containsCuswhAdditionalService;
        dialogRef.content.instance.isAddsDeconCase = isAddsDeconCase;
        dialogRef.content.instance.showCargoMeasureProperties = showCargoMeasureProperties;
        dialogRef.content.instance.showSaveAndLinkMOTButton = showSaveAndLinkMOTButton;
        dialogRef.content.instance.lrn = lrn;
        dialogRef.content.instance.mrn = mrn;
        dialogRef.content.instance.mrnString = mrnString;
        dialogRef.content.instance.mrnItem = mrnItem;
        dialogRef.content.instance.shipmentPoolId = shipmentPoolId;
        dialogRef.content.instance.serviceRequestVersionShipmentId = serviceRequestVersionShipmentId;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public linkMotNonContainersModal(
        modalComponent: any,
        title: string,
        message: string,
        motItems: any[],
        motType: string,
        serviceRequestVersionShipmentId: any,
        serviceRequestId: number,
        serviceRequestTosSystemId: string,
        serviceRequestServiceTypeId: string,
        shipmentPoolId: number,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.motItems = motItems;
        dialogRef.content.instance.motType = motType;
        dialogRef.content.instance.serviceRequestVersionShipmentId = serviceRequestVersionShipmentId;
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.serviceRequestTosSystemId = serviceRequestTosSystemId;
        dialogRef.content.instance.serviceRequestServiceTypeId = serviceRequestServiceTypeId;
        dialogRef.content.instance.shipmentPoolId = shipmentPoolId;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public linkMotContainersModal(
        modalComponent: any,
        title: string,
        message: string,
        motItems: any[],
        containers: any[],
        serviceRequestVersionId: number,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.motItems = motItems;
        dialogRef.content.instance.containers = containers;
        dialogRef.content.instance.serviceRequestVersionId = serviceRequestVersionId;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public openSelectedListWithMotsModal(
        modalComponent: any,
        title: string,
        message: string,
        selectedMotItemIds: any[],
        serviceRequestVersionShipmentId: number,
        serviceRequestId: number,
        serviceRequestTosSystemId: string,
        serviceRequestServiceTypeId: string,
        shipmentPoolId: number,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xxl})
        dialogRef.content.instance.selectedMotItemIds = selectedMotItemIds;
        dialogRef.content.instance.serviceRequestVersionShipmentId = serviceRequestVersionShipmentId;
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.serviceRequestTosSystemId = serviceRequestTosSystemId;
        dialogRef.content.instance.serviceRequestServiceTypeId = serviceRequestServiceTypeId;
        dialogRef.content.instance.shipmentPoolId = shipmentPoolId;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public editLinkToMotDataModal(
        modalComponent: any,
        title: string,
        message: string,
        dataForEditing: any,
        serviceRequestId: number,
        serviceRequestTosSystemId: string,
        serviceRequestServiceTypeId: string,
        shipmentPoolId: number,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xxl})
        dialogRef.content.instance.dataForEditing = dataForEditing;
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.serviceRequestTosSystemId = serviceRequestTosSystemId;
        dialogRef.content.instance.serviceRequestServiceTypeId = serviceRequestServiceTypeId;
        dialogRef.content.instance.shipmentPoolId = shipmentPoolId;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public editStorageDocumentItemModal(
        modalComponent: any,
        title: string,
        message: string,
        dataForEditing: any,
        storageDocumentId: number,
        storageDocumentTypeId: string,
        serviceRequestTosSystemId: string,
        mode: ViewMode,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xxl})
        dialogRef.content.instance.dataForEditing = dataForEditing;
        dialogRef.content.instance.storageDocumentId = storageDocumentId;
        dialogRef.content.instance.storageDocumentTypeId = storageDocumentTypeId;
        dialogRef.content.instance.serviceRequestTosSystemId = serviceRequestTosSystemId;
        dialogRef.content.instance.mode = mode;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public createStorageDocumentDIRM(
        modalComponent: any,
        title: string,
        message: string,
        serviceRequestVersionShipmentId: number,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.serviceRequestVersionShipmentId = serviceRequestVersionShipmentId;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public assignWarehouseModal(
        modalComponent: any,
        title: string,
        message: string,
        warehouses: any,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: '40%'})
        dialogRef.content.instance.warehouses = warehouses;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public getContainersFromTOS(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        cargoManifestId?: number,
        notificationTypeId?: string,
        manipulationId?: string,
        fromServiceRequest?: boolean,
        shipmentType?: string,
        tosVoyageNumber?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xxl})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.cargoManifestId = cargoManifestId;
        dialogRef.content.instance.notificationTypeId = notificationTypeId;
        dialogRef.content.instance.manipulationId = manipulationId;
        dialogRef.content.instance.fromServiceRequest = fromServiceRequest;
        dialogRef.content.instance.shipmentType = shipmentType;
        dialogRef.content.instance.tosVoyageNumber = tosVoyageNumber;
        return dialogRef.result;
    }

    public addAdditionalServiceOnServiceRequestModal(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public createAmendmentDocumentModal(
        modalComponent: any,
        title: string,
        message: string,
        shipmentPoolItemId?: number,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        nextButtonName?: string,
        saveButtonName?: string,
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.shipmentPoolItemId = shipmentPoolItemId;
        dialogRef.content.instance.nextButtonName = nextButtonName;
        dialogRef.content.instance.saveButtonName = saveButtonName;
        return dialogRef.result;
    }

    public editAmendmentDocumentModal(
        modalComponent: any,
        title: string,
        message: string,
        id?: number,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        saveButtonName?: string,
        isForSubmitOnly?: boolean
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.id = id;
        dialogRef.content.instance.saveButtonName = saveButtonName;
        dialogRef.content.instance.isForSubmitOnly = isForSubmitOnly;
        return dialogRef.result;
    }

    public editServiceRequestAdditionalDataModal(
        modalComponent: any,
        title: string,
        message: string,
        serviceRequestId: number,
        manipulation: string,
        manipulationServiceRequestType: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xxl})
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.manipulation = manipulation;
        dialogRef.content.instance.manipulationServiceRequestType = manipulationServiceRequestType;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        return dialogRef.result;
    }

    public editServiceReuqestVersionContainerModal(
        modalComponent: any,
        title: string,
        message: string,
        dataForEditing: any,
        serviceReuestVersionId: number,
        unNamedContainers: boolean,
        fullOrEmptyId: string,
        mode: ViewMode,
        manipulation: string,
        serviceRequestServiceType: string,
        hasAdditionalServices: boolean,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xxl})
        dialogRef.content.instance.dataForEditing = dataForEditing;
        dialogRef.content.instance.serviceReuestVersionId = serviceReuestVersionId;
        dialogRef.content.instance.fullOrEmptyId = fullOrEmptyId;
        dialogRef.content.instance.unNamedContainers = unNamedContainers;
        dialogRef.content.instance.mode = mode;
        dialogRef.content.instance.manipulation = manipulation;
        dialogRef.content.instance.serviceRequestServiceType = serviceRequestServiceType;
        dialogRef.content.instance.hasAdditionalServices = hasAdditionalServices;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public linkContainersModal(
        modalComponent: any,
        title: string,
        message: string,
        containerItems: any[],
        additionalServices: any[],
        serviceRequestVersionShipmentId: any,
        serviceRequestId: number,
        serviceRequestTosSystemId: string,
        serviceRequestServiceTypeId: string,
        shipmentTypeId: string,
        shipmentPoolId: number,
        isMotRequired: boolean,
        isAddsDeconCase: boolean,
        motItems: any[],
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        dialogRef.content.instance.containerItems = containerItems;
        dialogRef.content.instance.additionalServices = additionalServices;
        dialogRef.content.instance.serviceRequestVersionShipmentId = serviceRequestVersionShipmentId;
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.serviceRequestTosSystemId = serviceRequestTosSystemId;
        dialogRef.content.instance.shipmentPoolId = shipmentPoolId;
        dialogRef.content.instance.serviceRequestServiceTypeId = serviceRequestServiceTypeId;
        dialogRef.content.instance.shipmentTypeId = shipmentTypeId;
        dialogRef.content.instance.isMotRequired = isMotRequired;
        dialogRef.content.instance.isAddsDeconCase = isAddsDeconCase;
        dialogRef.content.instance.motItems = motItems;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public openSelectedListWithContainersModal(
        modalComponent: any,
        title: string,
        message: string,
        selectedContainerItemIds: any[],
        additionalServices: any[],
        serviceRequestVersionShipmentId: number,
        serviceRequestId: number,
        serviceRequestTosSystemId: string,
        serviceRequestServiceTypeId: string,
        shipmentTypeId: string,
        shipmentPoolId: number,
        isMotRequired: boolean,
        isAddsDeconCase: boolean,
        motItems: any[],
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xxl})
        dialogRef.content.instance.selectedContainerItemIds = selectedContainerItemIds;
        dialogRef.content.instance.additionalServices = additionalServices;
        dialogRef.content.instance.serviceRequestVersionShipmentId = serviceRequestVersionShipmentId;
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.serviceRequestTosSystemId = serviceRequestTosSystemId;
        dialogRef.content.instance.serviceRequestServiceTypeId = serviceRequestServiceTypeId;
        dialogRef.content.instance.shipmentTypeId = shipmentTypeId;
        dialogRef.content.instance.shipmentPoolId = shipmentPoolId;
        dialogRef.content.instance.isMotRequired = isMotRequired;
        dialogRef.content.instance.isAddsDeconCase = isAddsDeconCase;
        dialogRef.content.instance.motItems = motItems;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public editLinkToContainerDataModal(
        modalComponent: any,
        title: string,
        message: string,
        dataForEditing: any,
        additionalServices: any[],
        serviceRequestId: number,
        serviceRequestTosSystemId: string,
        serviceRequestServiceTypeId: string,
        shipmentTypeId: string,
        shipmentPoolId: number,
        isMotRequired: boolean,
        isAddsDeconCase: boolean,
        motItems: any[],
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.xxl})
        dialogRef.content.instance.dataForEditing = dataForEditing;
        dialogRef.content.instance.additionalServices = additionalServices;
        dialogRef.content.instance.serviceRequestId = serviceRequestId;
        dialogRef.content.instance.serviceRequestTosSystemId = serviceRequestTosSystemId;
        dialogRef.content.instance.serviceRequestServiceTypeId = serviceRequestServiceTypeId;
        dialogRef.content.instance.shipmentTypeId = shipmentTypeId;
        dialogRef.content.instance.shipmentPoolId = shipmentPoolId;
        dialogRef.content.instance.isMotRequired = isMotRequired;
        dialogRef.content.instance.isAddsDeconCase = isAddsDeconCase;
        dialogRef.content.instance.motItems = motItems;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    public openContainerInfoModal(
        modalComponent: any,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string,
        containerId?: number
    ): Observable<any> {
        const dialogRef = this.modalService.open({content: modalComponent, width: ModalWidth.lg})
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);
        dialogRef.content.instance.containerId = containerId;

        return dialogRef.result;
    }

    public openSimpleDialog(
        modalComponent: any,
        title: string,
        message: string,
        width: ModalWidth,
        additionalData?: any,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): Observable<any> {
        const dialogRef = this.modalService.open({content :modalComponent, width: width});
        dialogRef.content.instance.additionalData = additionalData;
        this.setCommonProperties(dialogRef, title, message, yesButtonName, noButtonName, cancelButtonName);

        return dialogRef.result;
    }

    //TODO - use typed version of modal data where possible
    // Typed version of openSimpleDialog
    public openSimpleDialogWithData<T>(
        modalComponent: any,
        title: string,
        message: string,
        width: ModalWidth,
        additionalData?: T
    ): Observable<any> {
        const dialogRef = this.modalService.open({content :modalComponent, width: width});
        dialogRef.content.instance.additionalData = additionalData;
        this.setCommonProperties(dialogRef, title, message);

        return dialogRef.result;
    }

    public openFileSelectorDialog(
        title: string,
        message: string,
        yesButtonName?: string,
        allowedExtensions?: string[]
    ): Observable<any> {
        const dialogRef = this.modalService.open({content :FileUploadGenericModalComponent, width: ModalWidth.lg});

        dialogRef.content.instance.yesButtonName = yesButtonName;
        if (allowedExtensions)
            dialogRef.content.instance.attachmentFileUploadRestrictions = {
                maxFileSize: 5242880, allowedExtensions
            };
        this.setCommonProperties(dialogRef, title, message);

        return dialogRef.result;
    }

    private setCommonProperties(
        dialogRef: DialogRef,
        title: string,
        message: string,
        yesButtonName?: string,
        noButtonName?: string,
        cancelButtonName?: string
    ): void {
        dialogRef.content.instance.title = title;
        dialogRef.content.instance.message = message;
        dialogRef.content.instance.yesButtonName = yesButtonName;
        dialogRef.content.instance.noButtonName = noButtonName;
        dialogRef.content.instance.cancelButtonName = cancelButtonName;
    }

    public addDeliveryOrder(vesselVisitId): Observable<any> {
        const dialogRef = this.modalService.open({content: EditDeliveryOrderComponent, width: ModalWidth.lg});
        dialogRef.content.instance.vesselVisitId = vesselVisitId;
        this.setCommonProperties(dialogRef, this.translateService.instant(marker("Add Delivery Order")), '', this.translateService.instant(marker("Add")), null, this.translateService.instant(marker("Cancel")));

        return dialogRef.result;
    }

    public addDeliveryOrderItem(deliveryOrderId, deliveryOrderItemId?: number): Observable<any> {
        const dialogRef = this.modalService.open({content: AddDeliveryOrderItemComponent, width: ModalWidth.lg});
        dialogRef.content.instance.deliveryOrderId = deliveryOrderId;
        dialogRef.content.instance.deliveryOrderItemId = deliveryOrderItemId;
        this.setCommonProperties(dialogRef, this.translateService.instant(marker("Add Delivery Order")), '', this.translateService.instant(marker("Add")), null, this.translateService.instant(marker("Cancel")));
        return dialogRef.result;
    }

    public showErrorDialog(error: any) {
        console.log(error.error);
        const valError = error?.httpResponse?.data || error?.error || error;
        const dialogRef = this.modalService.open({ content: ErrorDialogComponent, width: ModalWidth.lg });
        dialogRef.content.instance.titleText = 'An error has occurred';
        dialogRef.content.instance.isServerSideError = true;
        dialogRef.content.instance.setMessage(valError.errorMessage, valError.exception);
    }

    public addVessel(vesselId?: number): Observable<any> {
        const dialogRef = this.modalService.open({ content: AddVesselComponent, width: ModalWidth.sm });
        dialogRef.content.instance.vesselId = vesselId;
        this.setCommonProperties(dialogRef, this.translateService.instant(marker('Temporary New Vessel')), '', this.translateService.instant(marker("Create")), null, this.translateService.instant(marker("Cancel")));
        return dialogRef.result;
    }

    public openAuditModal(auditLog: any) {
        const dialogRef = this.modalService.open({ content: AuditLogModalComponent, width: ModalWidth.lg });
        dialogRef.content.instance.auditLog = auditLog;
        this.setCommonProperties(dialogRef, this.translateService.instant(marker('Audit Log Preview')), '');
        return dialogRef.result;
    }

    public addQuestion(question?: any): Observable<any> {
        const dialogRef = this.modalService.open({ content: AddFaqModalComponent, width: ModalWidth.lg });
        dialogRef.content.instance.question = question;
        this.setCommonProperties(dialogRef, this.translateService.instant(marker("FAQ")), '', this.translateService.instant(marker("Add")), null, this.translateService.instant(marker("Cancel")));
        return dialogRef.result;
    }

    public addDocument(document?: any): Observable<any> {
        const dialogRef = this.modalService.open({ content: AddDocumentModalComponent, width: ModalWidth.lg });
        dialogRef.content.instance.document = document;
        this.setCommonProperties(dialogRef, this.translateService.instant(marker("User Manual")), '', this.translateService.instant(marker("Add")), null, this.translateService.instant(marker("Cancel")));
        return dialogRef.result;
    }
}
