import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toastyService: ToastrService, private translateService: TranslateService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse) {
                    // not found
                    if (error.status == 404 || error.status == 403) {
                        this.toastyService.error(this.translateService.instant(marker('Error: Requested resource is not accessible')));
                        this.router.navigate(['/home']);
                        return;
                    }

                    // intercept refresh token expire error
                    if (error?.error?.error === 'invalid_grant') {
                        window.location.reload();
                    }

                    if(error.status == 401 && error.url.indexOf('authentication/login') != -1)
                    {
                        return throwError(error);
                    }

                    // unaouthorized but not token expired and not current user
                    // expired tokens are handled in http-interceptor
                    // current user is checked in startup
                    if (error.status == 401 && !error.headers.has('Token-Expired') && error.url.indexOf('authentication/getCurrentUser') == -1) {
                        // reload app, it will check user again
                        window.location.reload();
                    }
                }

                return throwError(error);
            })
        );
    }
}
