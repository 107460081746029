export const getUrlQueryParams = () => {

    let search = window.location.search;
    if(search != null && search.length > 0 && search.startsWith("?")) {

        let params = {};
        let len = 0;
        search.substring(1).split("&").filter(x => {
            var keyVal = x.split("=");
            return keyVal.length >= 2 && keyVal[0]!="" && keyVal[1]!="";
        }).forEach(x => {
            var keyVal = x.split("=");
            var key = decodeURIComponent(keyVal[0]);
            var val = decodeURIComponent(keyVal[1]);
            params[key] = val;
            len++;
        });

        return len > 0 ? params : null;
    }
    return null;
    
};