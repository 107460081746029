<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title">Delivery Order Item</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.close(null)">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <app-loader [isBusy]="false">
                <ng-template>
                    <form [formGroup]="form" class="p-3">
                            <div class="row">
                                <div class="col-3">
                                    <form-textbox formControlName="name" labelText="{{ 'Name' | translate }}"></form-textbox>
                                </div>
                                <div class="col-3">
                                    <form-textbox formControlName="description" labelText="{{ 'Description' | translate }}"></form-textbox>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <form-number formControlName="quantity" labelText="{{ 'Quantity' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="grossWeight" labelText="{{ 'Gross Weight (kg)' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="netWeight" labelText="{{ 'Net Weight (kg)' | translate }}"></form-number>
                                </div>
                                <div class="col-3">
                                    <form-number [decimal]="true" formControlName="volume" labelText="{{ 'Volume' | translate }}"></form-number>
                                </div>
                            </div>
                    </form>
                </ng-template>
            </app-loader>
        </div>

        <div class="modal-footer">
            <button class="btn btn-sm btn-danger" (click)="activeModal.close(false)" translate>Cancel</button>
            <button class="btn btn-sm btn-primary" (click)="addNewDeliveryOrder()" translate>Save</button>
        </div>
    </ng-template>
</app-loader>
