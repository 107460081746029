import { AbstractControl, ValidatorFn } from "@angular/forms";

export function betweenNumberValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        var valErr = control.errors;

        // no value, so we hide the message in the tooltip
        if(control.value == null && valErr && valErr['betweenNumber']){
            delete valErr['betweenNumber'];
            if(valErr && Object.keys(valErr).length == 0) valErr = null;
        }

        // entered value is not in the correct interval
        if ((control.value != null && (control.value < -2147483648 || control.value > Number.MAX_SAFE_INTEGER))){
            if(valErr == null) {
                valErr = {};
            }

            if(control.value != null){
                valErr['betweenNumber'] = {
                    code: 'rfv07'
                };
            }

        } else {
            if (valErr && valErr['betweenNumber']) delete valErr['betweenNumber'];
            if(valErr && Object.keys(valErr).length == 0) valErr = null;
        }

        return valErr;
    };
}
