<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="row justify-content-md-center">
            <div class="col-sm-auto">
                <div class="card">
                    <div class="card-body">
                        <h6 class="text-center">{{ 'Reset Password' | translate }}</h6>
                        <form [formGroup]="form" class="form-reset-pass" (ngSubmit)="resetPassword()">
                            <form-textbox labelText="{{ 'New Password' | translate }}" [textboxType]="'password'"
                                formControlName="newPassword"></form-textbox>
                            <form-textbox labelText="{{ 'Confirm password' | translate }}" [textboxType]="'password'"
                                formControlName="confirmPassword"></form-textbox>
                            <button class="btn btn-md btn-primary btn-block mt-3" type="submit"
                                [disabled]="!form.valid">
                                {{ 'Reset Password' | translate }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</app-loader>