<div class="model-header">
  <h4 class="modal-title">{{ 'Add New DTS' | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <app-loader [isBusy]="false">
    <ng-template>
      <form [formGroup]="form" class="p-3">
        <div class="row">
          <div class="col">
            <form-textbox formControlName="mrn" labelText="{{ 'MRN' | translate }}"></form-textbox>
          </div>
          <div class="col">
            <form-date formControlName="acceptanceDate" labelText="{{ 'Acceptance Date' | translate }}"></form-date>
          </div>
          <div class="col">
            <form-dropdown formControlName="lodgingOfficeId" labelText="{{ 'Lodging Office' | translate }}" codeListName="CodeListBGCustomsOffice"></form-dropdown>
          </div>
          <div class="col">
            <form-textbox formControlName="warehouse" labelText="{{ 'Warehouse' | translate }}"></form-textbox>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <form-textbox formControlName="goodsDescription" labelText="{{ 'Goods Description' | translate }}"></form-textbox>
          </div>
          <div class="col">
            <form-number [decimal]="true" formControlName="netWeight" labelText="{{ 'Net Weight (kg)' | translate }}"></form-number>
          </div>
          <div class="col">
            <form-number [decimal]="true" formControlName="grossWeight" labelText="{{ 'Gross Weight (kg)' | translate }}"></form-number>
          </div>
          <div class="col">
            <form-number formControlName="quantity" labelText="{{ 'Quantity' | translate }}"></form-number>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <form-dropdown formControlName="packagingTypeId" labelText="{{ 'Packaging Type' | translate }}" [forTOSSystem]="'CUST'"  codeListName="CodeListCargoPackagingTypePerSystem"></form-dropdown>
          </div>
          <div class="col">
            <form-number [decimal]="true" formControlName="volume" labelText="{{ 'Volume' | translate }}"></form-number>
          </div>
          <div class="col">
            <form-textbox formControlName="declarant" labelText="{{ 'Declarant' | translate }}"></form-textbox>
          </div>
          <div class="col">
            <form-dropdown formControlName="portId" labelText="{{ 'Port' | translate }}" codeListName="CustomPort" ></form-dropdown>
          </div>
        </div>

      </form>
    </ng-template>
  </app-loader>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-danger" (click)="activeModal.close(false)" translate>Cancel</button>
  <button class="btn btn-sm btn-primary" (click)="addNewDts()" translate>Save</button>
</div>
