import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NominatedAgentService } from '@services/nominated-agent.service';
import { CodelistService } from '@services/codelist.service';
import { requiredValidator } from '@common/validators/required-validator';
import { ModalService } from '@services/modal.service';
import { filter } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
    selector: 'app-edit-cargo-item',
    templateUrl: './edit-cargo-item.component.html',
    styleUrls: ['./edit-cargo-item.component.scss']
})
export class EditCargoItemComponent implements OnInit {
    form: UntypedFormGroup;
    customPortCodelist: any = [];
    containerized: boolean;
    forTosSystem: string;
    saveModel: any;
    isBusy: boolean;
    isArrival: boolean;

    // codeLists: any;

    constructor(
        public dialogService: ModalService,
        public activeModal: DialogRef,
        private translateService: TranslateService,
        private toastyService: ToastrService,
        public service: NominatedAgentService,
        public codelistService: CodelistService
    ) { }

    ngOnInit(): void {
        this.createFormGroup();
        if (!this.containerized) {
            const dgClassificationBasedControls = ['marpolCategory', 'imdgNbr', 'unNbr', 'packGroup', 'ibcCode', 'imsbcCode', 'flashpoint', 'cargoTechnicalDescription', 'locationOnBoard', 'dgContact']
            const mswCargoRegulation = this.saveModel.mswCargoRegulation?.id;

            if (mswCargoRegulation === 'MARPOL_ANNEX1') {
                this.form.controls['marpolCategory'].updateValueAndValidity();
                ['imdgNbr', 'unNbr', 'packGroup', 'ibcCode', 'imsbcCode'].forEach((control) => {
                    this.form.get(control).disable()
                });
            }
            else if (mswCargoRegulation === 'IMDG') {
                this.form.controls['marpolCategory'].updateValueAndValidity();
                ['marpolCategory', 'ibcCode', 'imsbcCode'].forEach((control) => {
                    this.form.get(control).disable()
                });
            } else if (mswCargoRegulation === 'IBC') {
                ['unNbr', 'packGroup', 'imdgNbr', 'imsbcCode'].forEach((control) => {
                    this.form.get(control).disable()
                });
            } else if (mswCargoRegulation === 'IMSBC') {
                ['unNbr', 'packGroup', 'imdgNbr', 'ibcCode'].forEach((control) => {
                    this.form.get(control).disable()
                });
            } else if (!mswCargoRegulation) {
                dgClassificationBasedControls.forEach((control) => {
                    this.form.get(control).disable()
                });
            }

            this.form.get('mswCargoRegulation').valueChanges.subscribe((value) => {
                if (value === 'MARPOL_ANNEX1') {
                    this.form.get('marpolCategory').enable();
                    ['marpolCategory', 'dgContact'].forEach((control) => {
                        this.form.get(control).enable();
                    });
                    this.form.get('marpolCategory').setValidators(requiredValidator());
                    this.form.controls['marpolCategory'].updateValueAndValidity();
                    ['imdgNbr', 'unNbr', 'packGroup', 'ibcCode', 'imsbcCode'].forEach((control) => {
                        this.form.get(control).disable();
                        this.form.get(control).setValue(null);
                    });
                }
                else if (value === 'IMDG') {
                    this.form.get('marpolCategory').setValidators(null);
                    ['imdgNbr', 'unNbr', 'packGroup', 'dgContact'].forEach((control) => {
                        this.form.get(control).enable();
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.controls[control].updateValueAndValidity();
                    });
                    this.form.controls['marpolCategory'].updateValueAndValidity();
                    ['marpolCategory', 'ibcCode', 'imsbcCode'].forEach((control) => {
                        this.form.get(control).disable()
                        this.form.get(control).setValue(null);
                    });
                } else if (value === 'IBC') {
                    ['marpolCategory', 'ibcCode', 'dgContact'].forEach((control) => {
                        this.form.get(control).enable();
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.get('dgContact').setValidators(null);
                        this.form.controls[control].updateValueAndValidity();
                    });
                    ['unNbr', 'packGroup', 'imdgNbr', 'imsbcCode'].forEach((control) => {
                        this.form.get(control).disable()
                        this.form.get(control).setValue(null);
                    });
                } else if (value === 'IMSBC') {
                    ['marpolCategory', 'imsbcCode', 'dgContact'].forEach((control) => {
                        this.form.get(control).enable();
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.controls[control].updateValueAndValidity();
                    });
                    ['unNbr', 'packGroup', 'imdgNbr', 'ibcCode'].forEach((control) => {
                        this.form.get(control).disable();
                        this.form.get(control).setValue(null);
                    });
                } else if (!value) {
                    dgClassificationBasedControls.forEach((control) => {
                        this.form.get(control).disable();
                        this.form.get(control).setValue(null);
                        this.form.get(control).setValidators(null);
                    });
                }
                else {
                    dgClassificationBasedControls.forEach((control) => {
                        this.form.get(control).enable();
                        this.form.get(control).setValidators(null);
                        this.form.controls[control].updateValueAndValidity();
                    });
                }
            });
        }

        if (this.containerized) {
            const disabledControls = ['loadType', 'cargoDescription', 'cargoReference', 'cargoVolume', 'flashpoint', 
                'cargoTechnicalDescription', 'temperatureFrom', 'temperatureTo', 'cargoOrigin', 'grossWeight'];
            const requiredControls=['netWeight', 'pckNumber'];
            this.form.get('imdgNbr').valueChanges.subscribe((value) => {
                if (value) {
                    ['unNbr', 'packGroup'].forEach((control) => {
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.controls[control].updateValueAndValidity();
                    });
                }
                else {
                    ['unNbr', 'packGroup'].forEach((control) => {
                        this.form.get(control).setValidators(null);
                        this.form.controls[control].updateValueAndValidity();
                    });
                }
            });
            const statusId = this.saveModel.status?.id;
            if (statusId === 'E') {
                disabledControls.forEach((control) => {
                    this.form.get(control).disable();
                    this.form.get(control).setValue(null);
                });
                requiredControls.forEach((control) => {
                    this.form.get(control).setValue("0");
                });
            }
            this.form.get('status').valueChanges.subscribe((value) => {
                const disabled = value === 'E';
                disabledControls.forEach((control) => {
                    if (disabled) {
                        this.form.get(control).disable();
                    }
                    else {
                        this.form.get(control).enable();
                    }
                });
            });
        }
    }

    createFormGroup() {
        this.form = new UntypedFormGroup({
            manifestRowId: new UntypedFormControl(this.saveModel.id),
            manifestId: new UntypedFormControl(null),
            bookingNumber: new UntypedFormControl(this.saveModel.bookingNumber),
            billOfLadingNbr: new UntypedFormControl(this.saveModel.billOfLadingNbr),
            container: new UntypedFormControl(this.saveModel.container),
            shippingLine: new UntypedFormControl(+this.saveModel.shippingLine?.id),
            usingLine: new UntypedFormControl(+this.saveModel.usingLine?.id),
            loadType: new UntypedFormControl(this.saveModel.loadType?.id),
            isoCode: new UntypedFormControl(this.saveModel.isoCode?.id),
            status: new UntypedFormControl(this.saveModel.status?.id),
            customsDirection: new UntypedFormControl(this.saveModel.customsDirection?.id),
            sealNumber: new UntypedFormControl(this.saveModel.sealNumber),
            sealType: new UntypedFormControl(this.saveModel.sealType?.id),
            tareWeight: new UntypedFormControl(this.saveModel.tareWeight),
            netWeight: new UntypedFormControl(this.saveModel.netWeight, requiredValidator()),
            grossWeight: new UntypedFormControl(this.saveModel.grossWeight),
            vgm: new UntypedFormControl(this.saveModel.vgm),
            cargoVolume: new UntypedFormControl(this.saveModel.cargoVolume),
            pckType: new UntypedFormControl(+this.saveModel.pckType?.id),
            pckNumber: new UntypedFormControl(this.saveModel.pckNumber, requiredValidator()),
            cargoOrigin: new UntypedFormControl(this.saveModel.cargoOrigin?.id),
            pol: new UntypedFormControl(this.saveModel.pol?.id),
            pod: new UntypedFormControl(this.saveModel.pod?.id),
            pot: new UntypedFormControl(this.saveModel.pot?.id),
            fpod: new UntypedFormControl(this.saveModel.fpod?.id),
            cargoType: new UntypedFormControl(+this.saveModel.cargoType?.id),
            cargoReference: new UntypedFormControl(this.saveModel.cargoReference),
            cargoDescription: new UntypedFormControl(this.saveModel.cargoDescription),
            shipper: new UntypedFormControl(this.saveModel.shipper),
            notifier: new UntypedFormControl(this.saveModel.notifier),
            consignee: new UntypedFormControl(this.saveModel.consignee),
            payer: new UntypedFormControl(this.saveModel.payer),
            mrn: new UntypedFormControl(this.saveModel.mrn),
            customsOfficeCode: new UntypedFormControl(this.saveModel.customsOfficeCode?.id),
            taric: new UntypedFormControl(this.saveModel.taric),
            imdgNbr: new UntypedFormControl(this.saveModel.imdgNbr?.id),
            unNbr: new UntypedFormControl(this.saveModel.unNbr?.id),
            packGroup: new UntypedFormControl(this.saveModel.packGroup?.id),
            temperatureFrom: new UntypedFormControl(this.saveModel.temperatureFrom),
            temperatureTo: new UntypedFormControl(this.saveModel.temperatureTo),
            customsDuties: new UntypedFormControl(this.saveModel.customsDuties),
            paymentType: new UntypedFormControl(this.saveModel.paymentType?.id),
            cargoTechnicalDescription: new UntypedFormControl(this.saveModel.cargoTechnicalDescription),
            dgContact: new UntypedFormControl(this.saveModel.dgContact),
            mswCargoRegulation: new UntypedFormControl(this.saveModel.mswCargoRegulation?.id),
            marpolCategory: new UntypedFormControl(this.saveModel.marpolCategory?.id),
            ibcCode: new UntypedFormControl(this.saveModel.ibcCode?.id),
            imsbcCode: new UntypedFormControl(this.saveModel.imsbcCode?.id),
            cargoCustomsStatus: new UntypedFormControl(this.saveModel.cargoCustomsStatus?.id),
            inTransit: new UntypedFormControl(this.saveModel.inTransit),
            flashpoint: new UntypedFormControl(this.saveModel.flashpoint),
            locationOnBoard: new UntypedFormControl(this.saveModel.locationOnBoard),
        });
    }

    public get getIsFormDisabled() {
        return this.isBusy || (!this.form.valid && this.form.dirty);
    }

    addNewCargoItem() {
        this.dialogService.confirm(
            this.translateService.instant(marker('Confirm')),
            this.translateService.instant(marker('Are you sure you want to update Cargo List item?')),
            this.translateService.instant(marker('YES')),
            this.translateService.instant(marker('No'))
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)))
            .subscribe(res => {
                if (res) {
                    const body = this.form.getRawValue();
                    this.service.saveCargoManifestItem(body).subscribe(() => {
                        this.toastyService.success(this.translateService.instant(marker('Cargo item updated')));
                        this.activeModal.close(body);
                    });
                }
            });
    }

}
