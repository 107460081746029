import { Component, OnInit } from '@angular/core';
import { FileDownloadService } from '@common/services/file-download.service';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Subject } from 'rxjs';

@Component({
    selector: 'pdf-signer-modal',
    templateUrl: './pdf-signer-modal.component.html',
})
export class PdfSignerModalComponent implements OnInit {
    public title: string;
   
    public file: any;
    public selectedCertificate: string;
    public id: number;
    public blobFile: any;
    public signedFile: any;

    refreshDocumentSubject: Subject<any> = new Subject<any>();

    constructor(
        public activeModal: DialogRef,
        public fileService: FileDownloadService
    ) {}

    ngOnInit() {}

    sign($event) {
        this.signedFile = $event;
    }

    reloadFile() {
        this.refreshDocumentSubject.next(this.file);
    }

 
    persistPdf(sendToCimis: boolean) {
       
        this.fileService.handleAttachmentDownload({ base64Content: this.signedFile, name:"signed.pdf"});

    }
}
