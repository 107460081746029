<form [formGroup]="form" autofocus (ngSubmit)="save()">
    <div class="modal-header">
        <h4 class="modal-title">{{ title }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.close(null)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form-dropdown formControlName="languageId" labelText="{{ 'Language' | translate }}" codeListName="language">
        </form-dropdown>
        <br>
        <label>{{ 'Changing the language will sign you out of the application. Continue?' | translate }}</label>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-primary br-2" type="submit" [disabled]="!(form.valid)" translate>Save</button>
        <button class="btn btn-sm btn-secondary" type="button" (click)="activeModal.close(null)" translate>Cancel</button>       
    </div>
</form>