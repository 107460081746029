import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { currentUserSubject } from '../services/user.service';
import { UserModel } from '@common/models/User.model';

@Injectable()
export class UserCreateGuard implements CanActivate {
    private currentUser: UserModel;

    constructor(private router: Router) {
        currentUserSubject.subscribe((user) => {
            this.currentUser = user;
        });
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        let status = !this.currentUser;

        // only not logged in users can access this route
        if (status) {
            return true;
        }
        else {
            this.router.navigate(['/']);
        }

        return status
    }
}
