import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DialogCloseResult, DialogRef} from '@progress/kendo-angular-dialog';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {DtsService} from '@services/dts.service';
import {CodelistService} from '@services/codelist.service';
import {NominatedAgentService} from '@services/nominated-agent.service';
import { requiredValidator } from '@common/validators/required-validator';
import {filter} from 'rxjs/operators';
import {ModalService} from '@services/modal.service';
import { isThisHour } from 'date-fns';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-add-cargo-item',
  templateUrl: './add-cargo-item.component.html',
  styleUrls: ['./add-cargo-item.component.scss']
})
export class AddCargoItemComponent implements OnInit {

    form: UntypedFormGroup;
    customPortCodelist: any = [];
    manifestId = 0;
    containerized: boolean;
    forTosSystem: string;
    isArrival: boolean;
    isBusy: boolean;

    // codeLists: any;

    constructor(
        public dialogService: ModalService,
        public activeModal: DialogRef,
        private translateService: TranslateService,
        private toastyService: ToastrService,
        public service: NominatedAgentService,
        public codelistService: CodelistService
    ) { }

    ngOnInit(): void {
        this.form = this.createFormGroup();

        if (!this.containerized) {
            const dgClassificationBasedControls =  ['marpolCategory', 'imdgNbr', 'unNbr', 'packGroup', 'ibcCode', 'imsbcCode', 'flashpoint', 'cargoTechnicalDescription', 'locationOnBoard', 'dgContact'];
            const enableAgainControls = ['cargoTechnicalDescription', 'flashpoint', 'locationOnBoard', 'dgContact'];
            dgClassificationBasedControls.forEach((control) => {
                this.form.get(control).disable();
            });
            this.form.get('mswCargoRegulation').valueChanges.subscribe((value) => {
                if (value === 'MARPOL_ANNEX1') {
                    ['marpolCategory', 'dgContact'].forEach((control) => {
                        this.form.get(control).enable();
                    });
                    this.form.get('marpolCategory').setValidators(requiredValidator());
                    enableAgainControls.forEach((control) => {
                        this.form.get(control).enable();
                    });
                    this.form.controls['marpolCategory'].updateValueAndValidity();
                    ['imdgNbr', 'unNbr', 'packGroup', 'ibcCode', 'imsbcCode'].forEach((control) => {
                        this.form.get(control).disable();
                        this.form.get(control).setValue(null);
                    });
                }
                else if (value === 'IMDG') {
                    this.form.get('marpolCategory').setValidators(null);
                    enableAgainControls.forEach((control) => {
                        this.form.get(control).enable();
                    });
                    ['imdgNbr', 'unNbr', 'packGroup', 'dgContact'].forEach((control) => {
                        this.form.get(control).enable();
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.controls[control].updateValueAndValidity();
                    });
                    this.form.controls['marpolCategory'].updateValueAndValidity();
                    ['marpolCategory', 'ibcCode', 'imsbcCode'].forEach((control) => {
                        this.form.get(control).disable()
                        this.form.get(control).setValue(null);
                    });
                } else if (value === 'IBC') {
                    enableAgainControls.forEach((control) => {
                        this.form.get(control).enable();
                    });
                    ['marpolCategory', 'ibcCode', 'dgContact'].forEach((control) => {
                        this.form.get(control).enable();
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.get('dgContact').setValidators(null);
                        this.form.controls[control].updateValueAndValidity();
                    });
                    ['unNbr', 'packGroup', 'imdgNbr', 'imsbcCode'].forEach((control) => {
                        this.form.get(control).disable()
                        this.form.get(control).setValue(null);
                    });
                } else if (value === 'IMSBC') {
                    enableAgainControls.forEach((control) => {
                        this.form.get(control).enable();
                    });
                    ['marpolCategory', 'imsbcCode', 'dgContact'].forEach((control) => {
                        this.form.get(control).enable();
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.controls[control].updateValueAndValidity();
                    });
                    ['unNbr', 'packGroup', 'imdgNbr', 'ibcCode'].forEach((control) => {
                        this.form.get(control).disable();
                        this.form.get(control).setValue(null);
                    });
                } else if (!value) {
                    dgClassificationBasedControls.forEach((control) => {
                        this.form.get(control).disable();
                        this.form.get(control).setValue(null);
                    });
                }
                else {
                    dgClassificationBasedControls.forEach((control) => {
                        this.form.get(control).enable();
                        this.form.get(control).setValidators(null);
                        this.form.controls[control].updateValueAndValidity();
                    });
                }
            });
        }

        if (this.containerized) {
            const disabledControls = ['loadType', 'cargoDescription', 'cargoReference', 'cargoVolume', 'flashpoint', 'cargoTechnicalDescription', 'temperatureFrom', 'temperatureTo', 'cargoOrigin', 'grossWeight'];
            const requiredControls=['netWeight', 'pckNumber'];
            this.form.get('imdgNbr').valueChanges.subscribe((value) => {
                if (value) {
                    ['unNbr', 'packGroup'].forEach((control) => {
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.controls[control].updateValueAndValidity();
                    });
                }
                else {
                    ['unNbr', 'packGroup'].forEach((control) => {
                        this.form.get(control).setValidators(null);
                        this.form.controls[control].updateValueAndValidity();
                    });
                }
            });
            this.form.get('status').valueChanges.subscribe((value) => {
                const disabled = value === 'E';

                    if (disabled) {
                        disabledControls.forEach((control) => {
                        this.form.get(control).disable();
                        });
                        requiredControls.forEach((control) => {
                            this.form.get(control).setValue("0");
                        });
                    }
                    else if (value === 'F') {
                        disabledControls.forEach((control) => {
                            this.form.get(control).enable();
                        });
                        requiredControls.forEach((control) => {
                            this.form.get(control).setValue(null);
                            this.form.get('grossWeight').setValue(null);
                        });
                        this.form.get('loadType').setValidators(requiredValidator());
                        this.form.controls['loadType'].updateValueAndValidity();
                    }
                    else {
                        disabledControls.forEach((control) => {
                            this.form.get(control).enable();
                        });
                        requiredControls.forEach((control) => {
                            this.form.get(control).setValue(null);
                            this.form.get('grossWeight').setValue(null);
                        });
                        this.form.get('loadType').setValidators(null);
                        this.form.controls['loadType'].updateValueAndValidity();
                    }
            });
            };
            this.form.get('container').valueChanges.subscribe((value) => {
                if (value) {
                    ['status', 'isoCode'].forEach((control) => {
                        this.form.get(control).setValidators(requiredValidator());
                        this.form.controls[control].updateValueAndValidity();
                    });
                }
                else {
                    ['status', 'isoCode'].forEach((control) => {
                        this.form.get(control).setValidators(null);
                        this.form.controls[control].updateValueAndValidity();
                    });
                }
            });
        }


    createFormGroup() {
        const form = new UntypedFormGroup({
            bookingNumber: new UntypedFormControl(),
            billOfLadingNbr: new UntypedFormControl(),
            container: new UntypedFormControl(),
            shippingLine: new UntypedFormControl(),
            usingLine: new UntypedFormControl(),
            loadType: new UntypedFormControl(),
            isoCode: new UntypedFormControl(),
            status: new UntypedFormControl(),
            customsDirection: new UntypedFormControl(),
            sealNumber: new UntypedFormControl(),
            sealType: new UntypedFormControl(),
            tareWeight: new UntypedFormControl(),
            netWeight: new UntypedFormControl(null, requiredValidator()),
            grossWeight: new UntypedFormControl(),
            vgm: new UntypedFormControl(),
            cargoVolume: new UntypedFormControl(),
            pckType: new UntypedFormControl(),
            pckNumber: new UntypedFormControl(null, requiredValidator()),
            cargoOrigin: new UntypedFormControl(),
            pol: new UntypedFormControl(),
            pod: new UntypedFormControl(),
            pot: new UntypedFormControl(),
            fpod: new UntypedFormControl(),
            cargoType: new UntypedFormControl(),
            cargoReference: new UntypedFormControl(),
            cargoDescription: new UntypedFormControl(),
            shipper: new UntypedFormControl(),
            notifier: new UntypedFormControl(),
            consignee: new UntypedFormControl(),
            payer: new UntypedFormControl(),
            mrn: new UntypedFormControl(),
            customsOfficeCode: new UntypedFormControl(),
            taric: new UntypedFormControl(),
            imdgNbr: new UntypedFormControl(),
            mswCargoRegulation: new UntypedFormControl(),
            unNbr: new UntypedFormControl(),
            packGroup: new UntypedFormControl(),
            temperatureFrom: new UntypedFormControl(),
            temperatureTo: new UntypedFormControl(),
            paymentType: new UntypedFormControl(),
            customsDuties: new UntypedFormControl(),
            cargoTechnicalDescription: new UntypedFormControl(),
            dgContact: new UntypedFormControl(),
            manifestId: new UntypedFormControl(this.manifestId),
            manifestRowId: new UntypedFormControl(),
            marpolCategory: new UntypedFormControl(),
            ibcCode: new UntypedFormControl(),
            imsbcCode: new UntypedFormControl(),
            flashpoint: new UntypedFormControl(),
            locationOnBoard: new UntypedFormControl(),
            cargoCustomsStatus: new UntypedFormControl(),
            inTransit: new UntypedFormControl(),
        });
        // ['boookingNumber', 'billOfLading'].forEach((x) => form.addControl(x, new FormControl()));
        form.patchValue(this);
        return form;
    }

    public get getIsFormDisabled(){
        return this.isBusy || (!this.form.valid && this.form.dirty);
    }

    addNewCargoItem() {
        this.dialogService.confirm(
            this.translateService.instant(marker('Confirm')),
            this.translateService.instant(marker('Are you sure you want to add Cargo List item?')),
            this.translateService.instant(marker('YES')),
            this.translateService.instant(marker('No'))
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)))
            .subscribe(res => {
                // make the confirm button disabled until the validation is done

                const body = this.form.getRawValue();

                // make save button disabled until the validation is done

                this.service.saveCargoManifestItem(body).subscribe(() => {
                    this.toastyService.success(this.translateService.instant(marker('Cargo item added')));
                    this.activeModal.close(body);
                    window.location.reload();
                });
            });
    }

}
