
<div id="imageDiv" class="fixed-background">
    <div class="login-wrap">
        <div class="login-html" [ngClass]="{'login-html-size': isMobile}">
            <div class="row">
                <div class="col">
                    <img src="../../../../assets/img/eu.svg" alt="EU"  *ngIf="translateService.currentLang === 'en'">
                    <img src="../../../../assets/img/eu2.svg" alt="EU"  *ngIf="translateService.currentLang === 'bg'">
                </div>
                <div class="col">
                    <img src="../../../../assets/img/trasnport2.svg" alt="Transport"  *ngIf="translateService.currentLang === 'en'">
                    <img src="../../../../assets/img/transport.svg" alt="Transport"  *ngIf="translateService.currentLang === 'bg'">
                </div>
            </div>
            <div class="row text-center bottom-text" *ngIf="translateService.currentLang === 'en'">
                The web portal is developed and maintained under project Development and implementation of a system for electronic exchange of information in the Bulgarian ports (Port Community System – PCS)
            </div>
            <div class="row text-center bottom-text" *ngIf="translateService.currentLang === 'bg'">
                Тази интернет страница е разработена по проект
                Разработване и внедряване на система за електронен обмен на информация в българските пристанища (Port Community System – PCS)
            </div>

            <div class="login-form tab">
                <app-loader [isBusy]="false">
                    <ng-template>
                        <form [formGroup]="form" (ngSubmit)="reset()" class="form-forgot-pass" [ngClass]="{ 'login-mobile-width': isMobile }">
                            <div class="sign-in-htm" [ngClass]="{ 'login-mobile-width': isMobile }">
                                <p class="mt-1">{{'Please enter the email address associated with your account. We will send you a link to reset your password.' | translate }}</p>
                                <div class="group">
                                    <label for="pass" class="label">{{'Email' | translate}}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-envelope"></i></span>
                                        <form-textbox class="input-width" formControlName="email"></form-textbox>
                                    </div>
                                </div>

                                <div class="group">
                                    <button class="button" type="submit" [disabled]="!form.valid" translate>
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </form>
                    </ng-template>
                </app-loader>
            </div>
        </div>
    </div>
</div>
