import { ApiInfo } from '@actual-dev/pdf-signer/lib/Models/apiInfo';
import { SelectionModel } from '@actual-dev/pdf-signer/lib/Models/selectionModel';
import { DisplayEngine } from '@actual-dev/pdf-signer/lib/Viewer/displayEngine';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { BaseFullHeightComponent } from '@common/classes/base-full-height-component';
import { PdfSignatureService } from '@common/services/pdf-signature.service';
import { Observable, Subscription } from 'rxjs';

@Component({
    selector: 'pdf-viewer',
    templateUrl: './pdf-viewer.component.html',
})
export class PdfViewerComponent extends BaseFullHeightComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild('frame') frameElement;

    oldIframe: HTMLIFrameElement;

    @Input()
    public selectedCertificate: string;

    @Input()
    public file: any;

    @Input()
    public blobFile: Blob;

    @Input()
    public title: any;

    @Input()
    public mode: any = 'view';

    @Input()
    public minimumHeight: number;

    @Input()
    public height: number;

    @Input()
    public refreshDocument: Observable<any>;
    refreshSubjectSubscription: Subscription;

    @Output() signed = new EventEmitter<any[]>();

    @Output() selected = new EventEmitter<any[]>();

    isBusy = true;
    showIframe = true;

    displayEngine: DisplayEngine;
    selection: any;

    constructor(private pdfSignatureService: PdfSignatureService, private renderer: Renderer2) {
        super();
        this.displayEngine = new DisplayEngine(new ApiInfo());
    }
    ngOnChanges() {}
    ngOnInit() {
        this.refreshSubjectSubscription = this.refreshDocument.subscribe((file) => {
            this.b64toBlob(file);
            this.displayEngine.replacePdf(this.blobFile);
        });
    }

    ngOnDestroy() {
        this.refreshSubjectSubscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.b64toBlob(this.file);
        this.displayEngine.getPdfSignatureSelectionView(this.blobFile, this.cb, this.selectionCb);
    }

    cb = (viewer: HTMLDivElement) => {
        // viewer.onchange = (e: any) => this.onSelectionChange(e);
        this.renderer.appendChild(this.frameElement.nativeElement, viewer); // todo: clear previous screen and load new one
        this.isBusy = false;
    };

    selectionCb = async (selection: SelectionModel) => {
        this.isBusy = true;
        this.selection = selection;
        this.selection.file = this.file;

        const signRes = await this.pdfSignatureService.signPdfImplementation(this.file, this.selectedCertificate, selection);

        if (signRes) {
            this.b64toBlob(signRes);
            this.displayEngine.replacePdf(this.blobFile);
            this.signed.emit(this.file);
            this.isBusy = false;
        }
    };

    sign() {
        this.signed.emit(this.file);
    }

    private b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        this.file = b64Data;

        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        this.blobFile = new Blob(byteArrays, { type: contentType });
    }
}
