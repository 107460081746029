import { Injectable } from '@angular/core';
import { MessageService } from '@progress/kendo-angular-l10n';

const data = {
    bg: {
        rtl: false,
        messages: {
            'kendo.grid.noRecords': 'Няма налични записи.',
            'kendo.grid.groupPanelEmpty': 'Списъкът с групи е празен',
            'kendo.grid.pagerFirstPage': 'Първа страница',
            'kendo.grid.pagerPreviousPage': 'Предишна страница',
            'kendo.grid.pagerNextPage': 'Следваща страница',
            'kendo.grid.pagerLastPage': 'Последна страница',
            'kendo.grid.pagerPage': 'Страница',
            'kendo.grid.pagerOf': 'от',
            'kendo.grid.pagerItems': 'елементи',
            'kendo.grid.pagerPageNumberInputTitle': 'Номер на страница',
            'kendo.grid.pagerItemsPerPage': 'елементи на страница',
            'kendo.grid.filter': 'Филтър',
            'kendo.grid.filterEqOperator': 'Равно',
            'kendo.grid.filterNotEqOperator': 'Не е равно',
            'kendo.grid.filterIsNullOperator': 'Е нула',
            'kendo.grid.filterIsNotNullOperator': 'Не е нула',
            'kendo.grid.filterIsEmptyOperator': 'Празно е',
            'kendo.grid.filterIsNotEmptyOperator': 'Не е празно',
            'kendo.grid.filterStartsWithOperator': 'Започва с',
            'kendo.grid.filterContainsOperator': 'Съдържа',
            'kendo.grid.filterNotContainsOperator': 'Не съдържа',
            'kendo.grid.filterEndsWithOperator': 'Завършва с',
            'kendo.grid.filterGteOperator': 'Е по-голямо или равно на',
            'kendo.grid.filterGtOperator': 'Е по-голямо от',
            'kendo.grid.filterLteOperator': 'Е по-малко или равно на',
            'kendo.grid.filterLtOperator': 'Е по-малко от',
            'kendo.grid.filterIsTrue': 'вярно е',
            'kendo.grid.filterIsFalse': 'е невярно',
            'kendo.grid.filterBooleanAll': '(Всички)',
            'kendo.grid.filterAfterOrEqualOperator': 'Е след или равно на',
            'kendo.grid.filterAfterOperator': 'Е след',
            'kendo.grid.filterBeforeOperator': 'Е преди',
            'kendo.grid.filterBeforeOrEqualOperator': 'Е преди или равно',
            'kendo.grid.filterFilterButton': 'Търсене',
            'kendo.grid.filterClearButton': 'Изчистване',
            'kendo.grid.filterAndLogic': 'И',
            'kendo.grid.filterOrLogic': 'Или',
            'kendo.grid.loading': 'Зареждане',
            'kendo.grid.columnMenu': 'Меню с колони',
            'kendo.grid.columns': 'Колони',
            'kendo.grid.lock': 'Заключено',
            'kendo.grid.unlock': 'Отключен',
            'kendo.grid.sortable': 'Сортируемо',
            'kendo.grid.sortAscending': 'Сортиране във възходящ ред',
            'kendo.grid.sortDescending': 'Сортиране в низходящ ред',
            'kendo.grid.sortedAscending': 'Сортирано възходящо',
            'kendo.grid.sortedDescending': 'Сортирано в низходящ ред',
            'kendo.grid.sortedDefault': 'По подразбиране',
            'kendo.grid.columnsApply': 'Прилагане',
            'kendo.grid.columnsReset': 'Нулиране',
            'kendo.grid.detailExpand': 'Разгъване',
            'kendo.grid.detailCollapse': 'Свиване',
            'kendo.grid.filterDateToday': 'Днес',
            'kendo.grid.filterDateToggle': 'Превключване',
            'kendo.grid.filterNumericDecrement': 'Намаляваща стойност',
            'kendo.grid.filterNumericIncrement': 'Нарастваща стойност',


            'kendo.window.closeTitle': 'Затваряне',
            'kendo.window.restoreTitle': 'Възстановяване',
            'kendo.window.maximizeTitle': 'Увеличаване',
            'kendo.window.minimizeTitle': 'Намаляване',

            'kendo.upload.cancel': 'Отказ',
            'kendo.upload.clearSelectedFiles': 'Изчистване',
            'kendo.upload.dropFilesHere': 'Пуснете файлове тук',
            'kendo.upload.headerStatusUploaded': 'Качен',
            'kendo.upload.headerStatusUploading': 'Качване',
            'kendo.upload.invalidFileExtension': 'Невалиден файлов тип',
            'kendo.upload.invalidMaxFileSize': 'Размерът на файла е твърде голям',
            'kendo.upload.invalidMinFileSize': 'Размерът на файла е твърде малък',
            'kendo.upload.remove': 'Премахване',
            'kendo.upload.retry': 'Повторен опит',
            'kendo.upload.select': 'Избор',
            'kendo.upload.uploadSelectedFiles': 'Качване на файлове',
            'kendo.upload.externalDropFilesHere': 'За да качите файлове с плъзгане и пускане тук',
            'kendo.upload.filesBatchStatus': 'Файлове',
            'kendo.upload.filesBatchStatusFailed': 'Неуспешно качване на файлове',
            'kendo.upload.filesBatchStatusUploaded': 'Файловете са успешно качени',
            'kendo.upload.fileStatusFailed': 'Неуспешно качване на файл',
            'kendo.upload.fileStatusUploaded': 'Файлът е качен успешно',
            'kendo.upload.headerStatusPaused': 'Качването е на пауза',

            'kendo.textbox.clearTitle': 'Изчистване',

            'kendo.timepicker.accept': 'Задаване',
            'kendo.timepicker.acceptLabel': 'Задаване',
            'kendo.timepicker.cancel': 'Отказ',
            'kendo.timepicker.cancelLabel': 'Отказ',
            'kendo.timepicker.now': 'Сега',
            'kendo.timepicker.nowLabel': 'Сега',
            'kendo.timepicker.toggle': 'Превключване',

            'kendo.pager.firstPage': 'Първа страница',
            'kendo.pager.previousPage': 'Предишна страница',
            'kendo.pager.nextPage': 'Следваща страница',
            'kendo.pager.lastPage': 'Последна страница',
            'kendo.pager.page': 'Страница',
            'kendo.pager.of': 'от',
            'kendo.pager.items': 'артикули',
            'kendo.pager.itemsPerPage': 'елементи на страница',
            'kendo.pager.pageNumberInputTitle': 'Номер на страница',

            'kendo.datetimepicker.dateTab': 'Дата',
            'kendo.datetimepicker.dateTabLabel': 'Дата',
            'kendo.datetimepicker.timeTab': 'Час',
            'kendo.datetimepicker.timeTabLabel': 'Час',
            'kendo.datetimepicker.toggle': 'Включване/изключване',
            'kendo.datetimepicker.accept': 'Задаване',
            'kendo.datetimepicker.acceptLabel': 'Задаване',
            'kendo.datetimepicker.cancel': 'Отказ',
            'kendo.datetimepicker.cancelLabel': 'Отказ',
            'kendo.datetimepicker.now': 'СЕГА',
            'kendo.datetimepicker.nowLabel': 'СЕГА',
            'kendo.datetimepicker.today': 'ДНЕС',
            'kendo.datetimepicker.prevButtonTitle': 'Предишен',
            'kendo.datetimepicker.nextButtonTitle': 'Напред',

            'kendo.datepicker.today': 'Днес',
            'kendo.datepicker.toggle': 'Включване/изключване',
            'kendo.datepicker.prevButtonTitle': 'Предишен',
            'kendo.datepicker.nextButtonTitle': 'Напред',

            'kendo.dateinput.increment': 'Увеличение',
            'kendo.dateinput.decrement': 'Намаление',

            'kendo.calendar.today': 'Днес',
            'kendo.calendar.prevButtonTitle': 'Предишен',
            'kendo.calendar.nextButtonTitle': 'Напред',

            'kendo.dialog.closeTitle': 'Затваряне',
        },
    },
    en: {
        rtl: true,
        messages: {},
    },
};

@Injectable()

export class KendoMessageService extends MessageService {
    private localeId = 'en';
    public get language(): string {
        return this.localeId;
    }
    public set language(value: string) {
        const lang = data[value];
        if (lang) {
            this.localeId = value;
            this.notify(lang.rtl);
        }
    }

    private get messages(): any {
        const lang = data[this.localeId];

        if (lang) {
            return lang.messages;
        }
    }

    public get(key: string): string {
        return this.messages[key];
    }
}
