import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {

    constructor(private http: HttpClient) { }

    getThirdPartyOrganizations(filter: any): Observable<any> {
        return this.http.post<any>('api:///ThirdPartyOrganization/ThirdPartyOrganizationPerSystemList', filter);
    }

    getThirdPartyOrganizationData(organizationId: number): Observable<any> {
        const organization = {
            id: organizationId
        };
        return this.http.post<any>('api:///ThirdPartyOrganization/GetThirdPartyOrganizationData', organization);
    }

    saveThirdPartyOrganization(organizationData): Observable<any> {
        return this.http.post<any>('api:///ThirdPartyOrganization/SaveThirdPartyOrganization', organizationData);
    }

    getOrganizationList(filter: any): Observable<any> {
        return this.http.post<any>('api:///Organization/OrganizationList', filter);
    }

    removeOrganization(organizationId: number): Observable<any> {
        return this.http.post<any>('api:///Organization/RemoveOrganization', organizationId, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        });
    }

    saveEditChanges(organization: any): Observable<any> {
        return this.http.post<any>('api:///Organization/SaveEditChanges', organization);
    }

    createNewOrganization(organization: any): Observable<any> {
        return this.http.post<any>('api:///Organization/CreateOrganization', organization);
    }

    addRoleToOrganization(roleId: number, organizationId: number): Observable<any> {
        const body = {
            roleId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/AddRoleToOrganization', body);
    }

    removeRoleFromOrgnaization(roleId: number, organizationId: number): Observable<any> {
        const body = {
            roleId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/RemoveRole', body);
    }

    addUserToOrganization(userId: number, organizationId: number): Observable<any> {
        const body = {
            userId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/AddUserToOrganization', body);
    }

    removeUserFromOrganization(userId: number, organizationId: number): Observable<any> {
        const body = {
            userId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/RemoveUser', body);
    }

    addOrganizationPerSystem(systemId: string, organizationId: number): Observable<any> {
        const body = {
            systemId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/AddOrganizationPerSystem', body);
    }

    removeOrganizationPerSystem(systemId: string, organizationId: number): Observable<any> {
        const body = {
            systemId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/RemoveOrganizationPerSystem', body);
    }

    addSystemRelation(systemId: string, organizationId: number, terminalId: string, tosQueue: string, createServiceRequestInPCS: boolean) {
        const body = {
            systemId,
            organizationId,
            terminalId,
            tosQueue,
            createServiceRequestInPCS
        };

        return this.http.post<any>('api:///Organization/AddSystemRelation', body);
    }

    removeSystemRelation(relationId: number, organizationId: number, terminalId: string, systemId: string) {
        const body = {
            relationId,
            organizationId,
            terminalId,
            systemId
        };

        return this.http.post<any>('api:///Organization/RemoveSystemRelation', body);
    }

    addPortToOrganization(portId: number, organizationId: number): Observable<any> {
        const body = {
            portId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/AddPortToOrganization', body);
    }

    activatePort(portId: number, organizationId: number): Observable<any> {
        const body = {
            portId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/ActivatePort', body);
    }

    removePortFromOrganization(portId: number, organizationId: number): Observable<any> {
        const body = {
            portId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/RemovePort', body);
    }

    addOrganizationTypeToOrganization(organizationTypeId: number, organizationId: number): Observable<any> {
        const body = {
            organizationTypeId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/AddOrganizationTypeToOrganization', body);
    }

    activateOrganizationType(organizationTypeId: number, organizationId: number): Observable<any> {
        const body = {
            organizationTypeId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/ActivateOrganizationType', body);
    }

    removeOrganizationTypeFromOrganization(organizationTypeId: number, organizationId: number): Observable<any> {
        const body = {
            organizationTypeId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/RemoveOrganizationType', body);
    }

    getSingleOrganization(organizationId: number): Observable<any> {
        return this.http.get<any>(`api:///Organization/GetSingleOrganizationDetailed/${organizationId}`);
    }

    initializeOrganization(): Observable<any> {
        return this.http.get<any>('api:///Organization/InitializeOrganization');
    }

    addRepresentativeToOrganization(userId: number, organizationId: number): Observable<any> {
        const body = {
            userId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/AddRepresentativeToOrganization', body);
    }

    removeRepresentativeFromOrganization(userId: number, organizationId: number): Observable<any> {
        const body = {
            userId,
            organizationId
        };

        return this.http.post<any>('api:///Organization/RemoveRepresentative', body);
    }

    getEmailSubscriptions(organizationId: number): Observable<any> {
        return this.http.get(`api:///Organization/GetEmailSubscriptions/${organizationId}`);
    }
}
