import { AbstractControl, ValidatorFn } from "@angular/forms";

export function requiredValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
        let valErr = control.errors;

        if(control.value == null || control.value === ""){
            if(valErr == null) {
                valErr = {};
            }

            valErr['required'] = {
                code: 'rfv01'
            };
        } else {
            if(valErr && valErr['required']) delete valErr['required'];
            if(valErr && Object.keys(valErr).length == 0) valErr = null;
        }

        return valErr;
    };
}
