<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>

    <button type="button" class="close" aria-label="Close" (click)="activeModal.close(null)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <pdf-viewer [file]="file" [blobFile]="blobFile" [selectedCertificate]="selectedCertificate" (signed)="sign($event)" [refreshDocument]="refreshDocumentSubject.asObservable()" ></pdf-viewer>
</div>
<div class="modal-footer">
    <!-- <button class="btn btn-sm btn-primary" (click)="persistPdf(false)" translate>Save pdf</button> -->
    <button *ngIf="signedFile" class="btn btn-sm btn-primary" (click)="persistPdf(true)" translate>Download</button>
    <button class="btn btn-sm btn-primary" (click)="reloadFile()" translate>Reload</button>
    <button class="btn btn-sm btn-secondary" (click)="activeModal.close(null)" translate>Exit</button>
</div>
