<nav class="sidebar sidebar-top-padding d-flex flex-column pb-0" [ngStyle]="{ width: getSidebarWidth() }">
    <ng-template ngFor let-item [ngForOf]="navigation">
        <ul class="nav nav-pills flex-column" [ngClass]="{ active: selectedItem === item || isActive(item.route) }" >
            <li *ngIf="displaySidebarItem(item)" #anchor >
                <a
                    class="nav-section nav-link"
                    href="javascript:void(0)"
                    style="display: block"
                    (click)="selectItem(item)"
                    (mouseenter)="currentHover=item.title"
                    (mouseleave)="currentHover=null"

                >
                    <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon>
                    <span class="nav-title-padding" *ngIf="!isCollapsed">{{ item.title | translate }}</span>
                    <span class="pull-right text-muted" [hidden]="isCollapsed">
                            <i class="fa fa-caret-down" [ngClass]="{ 'fa-caret-left': item == selectedItem, 'fa-caret-down': item != selectedItem }"></i>
                    </span>
                </a>

                <kendo-popup
                    [anchor]="anchor"
                    [copyAnchorStyles]="true"
                    [anchorAlign]="anchorAlign"
                    (anchorViewportLeave)="show = false"
                    *ngIf="currentHover && currentHover == item.title && isCollapsed"
                >
                    <div class="px-2 py-2 h-100">
                        {{ item.title }}
                    </div>
                </kendo-popup>

                <ul class="nav nav-pills flex-column" [hidden]="isCollapsed || selectedItem !== item">
                    <ng-template ngFor let-child [ngForOf]="item.childs">
                        <li *ngIf="hasPermission(child)" class="nav-item pl-0" [ngClass]="{'active': isActive(child.route)}" [@openClose]="selectedItem  ? 'open' : 'closed'">
                            <a class="nav-link" (click)="changeRoute(child)" routerLinkActive="active-router-link" >
                                <fa-icon *ngIf="child.icon" [icon]="child.icon"></fa-icon>
                                <span class="textIcon" *ngIf="child.iconText">{{ child.iconText}}</span>
                                {{ child.title | translate }}
                                <i *ngIf="child.type == 'email' || child.type == 'link'" class="fa fa-arrow-right"></i>
                                <!-- <span class="badge badge-danger ml-2" *ngIf="child.route=='/chat'"><fa-icon [icon]="['fas', 'comment']"></fa-icon> 105</span> -->
                            </a>
                        </li>
                    </ng-template>
                </ul>
            </li>
        </ul>
    </ng-template>
</nav>
