import { Component, Input, isDevMode, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { TranslateService } from '@ngx-translate/core';
import { RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
    selector: 'app-customs-certificate',
    templateUrl: './certificate-selection.component.html',
})
export class CertificateSelectionDialog implements OnInit {
    @Input()
    availableCertificates;

    isBusy = false;
    selectedCertificate;

    selected: string[] = [];

    selectableSettings: SelectableSettings = {
        checkboxOnly: true,
        mode: 'single',
    };

    constructor(
        public activeModal: DialogRef,
        private translateService: TranslateService,
        private toastyService: ToastrService
    ) {}

    ngOnInit() {
        this.availableCertificates.forEach((element) => {
            const thisExpirationDate = new Date(element.expirationDate);
            element.expirationDate = thisExpirationDate.getDate() + '.' + thisExpirationDate.getMonth() + '.' + thisExpirationDate.getFullYear();
            /*
            if (element.issuerName.includes('Fina'))
            {
                element.selected = true;
            }
            else
            {
                element.selected = false;
            }*/
        });
    }

    /* Store selected item serialNumber into selected[] */
    selectionCallback(context: RowArgs): string {
        return context.dataItem.serialNumber;
    }

    signXML() {
        this.selected.length === 0
            ? this.toastyService.error(this.translateService.instant(marker('Certificate Must Be Selected')))
            : this.activeModal.close(this.selected[0]);
    }
}
