import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HelpModuleService {
    private selectedCategory = new BehaviorSubject(null);
    selectedCategory$ = this.selectedCategory.asObservable();

    constructor(private http: HttpClient) { }

    setSelectedCategory(category: any) {
        this.selectedCategory.next(category);
    }

    getFaq(helpCategoryId: number, parentCategoryId?: number) {
        return this.http.get<any>(`api:///HelpModule/GetFaq/${helpCategoryId}/${parentCategoryId}`);
    }

    addQuestion(body: any): Observable<any> {
        return this.http.post<any>("api:///HelpModule/AddQuestion", body);
    }

    removeQuestion(ids: any): Observable<any> {
        return this.http.post<any>("api:///HelpModule/RemoveQuestion/", ids);
    }

    archiveQuestion(ids: any) {
        return this.http.post<any>('api:///HelpModule/ArchiveQuestion/', ids);
    }

    getDocuments(helpCategoryId: number, parentCategoryId?: number) {
        return this.http.get<any>(`api:///HelpModule/GetDocuments/${helpCategoryId}/${parentCategoryId}`);
    }

    addDocument(body: any): Observable<any> {
        return this.http.post<any>("api:///HelpModule/AddDocument", body);
    }

    removeDocument(ids: any): Observable<any> {
        return this.http.post<any>("api:///HelpModule/RemoveDocument/", ids);
    }

    archiveDocument(ids: any) {
        return this.http.post<any>('api:///HelpModule/ArchiveDocument/', ids);
    }
}
