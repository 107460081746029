<div id="imageDiv" class="fixed-background login-page" [hidden]="!showBackgroundImage">
    <div class="login-wrap" [ngClass]="{ 'login-mobile-wrap': isMobile }">
        <div class="login-html" [ngClass]="{'login-html-size': isMobile}">
            <!-- <span class="row">
                <button class="btn btn-sm btn-danger ml-3" *ngIf="usernameAndPasswordMode" (click)="toggleSignInMode()" type="button">
                </button>
            </span> -->
            <div class="row title" [ngClass]="{ 'login-image-padding': !isMobile }">
                <span *ngIf="!isPasswordExpired" class="col-12 d-flex align-items-center justify-content-center">
                    <span class="back-icon" (click)="toggleSignInMode()">
                        <fa-icon *ngIf="usernameAndPasswordMode" [icon]="['fas', 'arrow-left']"></fa-icon>
                    </span>
                    <span class="mx-auto">{{ 'Sign In' | translate }}</span>
                </span>
                <span *ngIf="isPasswordExpired" class="col-12 d-flex align-items-center justify-content-center">
                    <span class="back-icon" (click)="cancelResetPasswordMode()">
                        <fa-icon [icon]="['fas', 'arrow-left']"></fa-icon>
                    </span>
                    <span class="mx-auto">{{ 'Reset Password' | translate }}</span>
                </span>

            </div>
            <app-loader [isBusy]="false">
                <ng-template>
                    <div class="login-form" *ngIf="!oidcEnabled">
                        <form [formGroup]="firstFactorForm" (ngSubmit)="initiateLogin()" *ngIf="firstFactorStage">
                            <div class="sign-in-htm" *ngIf="usernameAndPasswordMode" [ngClass]="{ 'login-mobile-width': isMobile }">
                                <div class="group">
                                    <label for="user" class="label">{{ 'Username' | translate }}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-user"></i></span>
                                        <form-textbox class="input-width" formControlName="username"> </form-textbox>
                                    </div>
                                </div>
                                <div class="group">
                                    <label for="pass" class="label">{{'Password' | translate}}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-key"></i></span>
                                        <form-textbox class="input-width" formControlName="password"
                                            [textboxType]="'password'"> </form-textbox>
                                    </div>
                                </div>
                                <div class="group">
                                        <span>
                                            <input type="checkbox" formControlName="tac" #notification kendoCheckBox />
                                            {{'I have read and agree with the' | translate}}
                                            <a target="_blank" rel="noopener noreferrer" *ngIf="translateService.currentLang === 'en'" class="tac-checkbox" href="http://www.bgports.bg/en/page/78">{{'Terms & Conditions and the Privacy Policy' | translate}} </a>
                                            <a target="_blank" rel="noopener noreferrer" *ngIf="translateService.currentLang === 'bg'" class="tac-checkbox" href="http://www.bgports.bg/bg/page/78">{{'Terms & Conditions and the Privacy Policy' | translate}} </a>
                                        </span>
                                </div>

                                <div class="group">
                                    <button class="btn-md btn-primary btn-block button" type="submit"
                                        [disabled]="getIsFirstFactorDisabled" translate>
                                        Sign In
                                    </button>
                                </div>
                                <div class="hr"></div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-6 foot-lnk">
                                        <a [routerLink]="['/createUser']" translate>Sign up</a>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-6 float-right foot-lnk">
                                        <a [routerLink]="['/forgotPassword']" translate>Forgot Password?</a>
                                    </div>
                                </div>
                            </div>
                            <div class="sign-in-htm mt-5" *ngIf="certificateMode" [ngClass]="{ 'login-mobile-width': isMobile }">
                                <div class="group" *ngIf="false">
                                    <button class="btn-md btn-primary btn-block button" type="button" (click)="openBgSSO(true)" translate>
                                        SLO DEMO SSO
                                    </button>
                                </div>
                                <div class="group" *ngIf="certificateLoginEnabled">
                                    <button class="btn-md btn-primary btn-block button" type="button" (click)="openBgSSO()" translate>
                                        Login - Certificate
                                    </button>
                                </div>
                                <div class="group">
                                    <button class="btn-md btn-primary btn-block button" *ngIf="certificateMode" (click)="toggleSignInMode()" type="button" translate>
                                        Login - Username & Password
                                    </button>
                                </div>

                                <div class="hr"></div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-6 foot-lnk">
                                        <a [routerLink]="['/createUser']" translate>Sign up</a>
                                    </div>
                                    <div class="col-sm-12 col-md-6 col-lg-6 float-right foot-lnk">
                                        <a [routerLink]="['/forgotPassword']" translate>Forgot Password?</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()" *ngIf="resetPasswordStage">
                            <div class="sign-in-htm" *ngIf="isPasswordExpired" [ngClass]="{ 'login-mobile-width': isMobile }">
                                <p>{{'Your password has expired and needs to be changed.' | translate }}</p>
                                <div class="group">
                                    <label for="pass" class="label">{{'Current Password' | translate}}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-key"></i></span>
                                        <form-textbox class="input-width" formControlName="currentPassword" [textboxType]="'password'"> </form-textbox>
                                    </div>
                                </div>
                                <div class="group">
                                    <label for="pass" class="label">{{'New Password' | translate}}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-key"></i></span>
                                        <form-textbox class="input-width" formControlName="newPassword" [textboxType]="'password'"
                                        contextual="{{'Minimum of 9 characters including lower and capital letter, a number, and a special character.' | translate
                                        }}"> </form-textbox>
                                    </div>
                                </div>
                                <div class="group">
                                    <label for="pass" class="label">{{'Confirm password' | translate}}</label>
                                    <div class="input-group-prepend">
                                        <span class="input-group-text"><i class="fa fa-key"></i></span>
                                        <form-textbox class="input-width" formControlName="confirmPassword" [textboxType]="'password'"> </form-textbox>
                                    </div>
                                </div>
                                <div class="test">
                                    <div class="group">
                                        <button translate class="btn-md btn-primary btn-block button" type="submit" [disabled]="!resetPasswordForm.valid">
                                            Reset Password
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form [formGroup]="secondFactorForm" (ngSubmit)="logIn()" *ngIf="twoFactorStage">
                            <div class="form-signin" [ngClass]="{ 'login-mobile-width': isMobile }">
                                <form-number labelText="{{ '2-Factor code' | translate }}"
                                    formControlName="twoFactorCode" (paste)="onPaste($event)"></form-number>

                                <div class="group">
                                    <button class="btn btn-md btn-primary btn-block button mt-3" type="submit"
                                        [disabled]="isBusy || !secondFactorForm.valid" translate>
                                        Sign In
                                    </button>
                                    <button class="btn btn-md btn-primary btn-block button mt-3" type="button"
                                        (click)="setActiveAuthStage('ChooseTwoFactorPreference')" translate>
                                        Change 2-Factor Type
                                    </button>
                                </div>

                                <div class="text-center resend-code" *ngIf="showResendCodeButton">
                                    <a (click)="resendCode()" translate>Resend code</a>
                                </div>
                            </div>
                        </form>

                        <div *ngIf="chooseTwoFactorStage">
                            <form class="form-2fa" [ngClass]="{ 'login-mobile-width': isMobile }">

                                <div class="row two-factor-selection">
                                    <div class="col-md-8">
                                        <h2 translate>None</h2>
                                        <p translate>Two factor is disabled.</p>
                                    </div>

                                    <div class="col-md-4 text-center group -contjustifyent-center">
                                        <button class="btn btn-md btn-primary button select-2fa" type="button"
                                            (click)="setUser2FaPreference('DISABLED')" translate>
                                            Select
                                        </button>
                                    </div>
                                </div>

                                <div class="row two-factor-selection">
                                    <div class="col-md-8">
                                        <h2 translate>Email</h2>
                                        <p translate>Verification code will be sent via email.</p>
                                    </div>

                                    <div class="col-md-4 text-center group">
                                        <button class="btn btn-md btn-primary button select-2fa" type="button"
                                            (click)="setUser2FaPreference('EMAIL')" translate>
                                            Select
                                        </button>
                                    </div>
                                </div>

                                <div class="row two-factor-selection">
                                    <div class="col-md-8">
                                        <h2 translate>Certificate</h2>
                                        <p translate>User will be verified with personal certificate.</p>
                                    </div>

                                    <div class="col-md-4 text-center group -contjustifyent-center">
                                        <button class="btn btn-md btn-primary button select-2fa" type="button"
                                            (click)="setUser2FaPreference('CERTIFICATE')" translate>
                                            Select
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="login-form" *ngIf="oidcEnabled">
                        <div class="sign-in-htm" [ngClass]="{ 'login-mobile-width': isMobile }">
                            <div class="login-title" translate>Access PCS - Port Community System </div>
                            <div class="group">
                                <button class="btn-md btn-primary btn-block button" (click)="oidcLogin()" translate>
                                    Continue to login
                                </button>
                            </div>
                            <div class="hr"></div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 foot-lnk">
                                    <a [routerLink]="['/createUser']" translate>Sign up</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </app-loader>
                <div class="row">
                    <div class="col">
                        <img src="../../../../assets/img/eu.svg" alt="EU"  *ngIf="translateService.currentLang === 'en'">
                        <img src="../../../../assets/img/eu2.svg" alt="EU"  *ngIf="translateService.currentLang === 'bg'">
                    </div>
                    <div class="col">
                        <img src="../../../../assets/img/trasnport2.svg" alt="Transport"  *ngIf="translateService.currentLang === 'en'">
                        <img src="../../../../assets/img/transport.svg" alt="Transport"  *ngIf="translateService.currentLang === 'bg'">
                    </div>
                </div>
                <div class="row text-center bottom-text" *ngIf="translateService.currentLang === 'en'">
                    The web portal is developed and maintained under project Development and implementation of a system for electronic exchange of information in the Bulgarian ports (Port Community System – PCS)
                </div>
                <div class="row text-center bottom-text" *ngIf="translateService.currentLang === 'bg'">
                    Тази интернет страница е разработена по проект
                    Разработване и внедряване на система за електронен обмен на информация в българските пристанища (Port Community System – PCS)
                </div>
        </div>
    </div>
</div>
