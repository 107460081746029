import { Component, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { flatMap, every, some, find } from 'lodash-es';
import { UserModel } from '../../models/User.model';
import { currentUserSubject } from '../../services/user.service';
import { NavigationFactoryService } from '../../services/navigation-factory.service';
import { MenuItem } from '../../models/common/MenuItem';
import { TranslateService } from '@ngx-translate/core';
import { isMobile } from '@common/utils/isMobile';
import { Align } from '@progress/kendo-angular-popup';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    animations: [
        // ...
        trigger('openClose', [
            // ...
            state('open', style({
                height: '50px',
                opacity: 1,
            })),
            state('closed', style({
                height: '0px',
            })),
            transition('* => *', [
                animate('0.2s ease-in-out')
            ]),
        ]),
    ]
})
export class SidebarComponent {
    url: string;
    navigation: MenuItem[];
    isCollapsed = false;
    selectedItem: MenuItem;

    user: UserModel;
    initialUrl: string;
    isMobile = isMobile();

    currentHover: string;
    anchorAlign: Align = { horizontal: 'right', vertical: 'top' };

    constructor(private router: Router, private translateService: TranslateService, private navigationFactoryService: NavigationFactoryService) {
        if (this.isMobile) {
            this.isCollapsed = true;
        }

        this.navigation = this.navigationFactoryService.getMenuItems();

        currentUserSubject.subscribe((user) => {
            this.user = user;
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.url = this.router.url;
                this.initialUrl = event.url;

                if (this.url === '/' || this.url === '/home') {
                    this.selectedItem = this.navigation[0];
                }

                const splitUrl = this.url
                    .split('/')
                    .filter((x) => x)
                    .map((x) => x);
                const allRoutes = flatMap(this.navigation, (x) => x.childs)
                    .map((v) => v.route.split('/'))
                    .filter((x) => x);

                for (let i = 0; i < splitUrl.length; i++) {
                    const filteredRoutes = this.routeMatch(allRoutes, splitUrl[i]);
                    if (filteredRoutes.length === 1) {
                        const fullRoute = filteredRoutes[0].join('/');

                        const menuItem = find(this.navigation, { childs: [{ route: fullRoute }] });
                        this.selectedItem = menuItem ?? null;
                        return;
                    }
                }
            }
        });
    }

    routeMatch = (routes: string[][], url: string) => routes.filter((x) => x.includes(url));

    isActive(route: string) {
        return this.url && (this.url === route || this.url.indexOf(route) === 0);
    }

    selectItem(item) {

        if (this.selectedItem === item) {
            this.selectedItem = null;
            return;
        }

        this.isCollapsed = false;
        this.selectedItem = item;
    }

    toggle() {
        this.isCollapsed = !this.isCollapsed;
    }

    changeRoute(child: any) {
        if (child.type === 'email') {
            window.open('mailto:' + child.route);
            return;
        }
        if (child.type === 'link') {
            window.open(child.route);
            return;
        }

        if (this.isMobile) {
            this.isCollapsed = true;
        }

        this.router.navigate([child.route]);
    }

    hasPermission(menuItem: MenuItem) {
        if (!this.user) {
            return false;
        }

        if (!menuItem.permissions || menuItem.permissions.length === 0) {
            return true;
        }

        return this.user && some(menuItem.permissions, (permission) => this.user.hasPermission(permission));
    }

    displaySidebarItem(menuItem: MenuItem) {
        if (!this.user) {
            return false;
        }

        const currentModulePermissions = flatMap(menuItem.childs, (x) => x.permissions);

        if (currentModulePermissions.length < 1) {
            return true;
        }

        return this.user && some(currentModulePermissions, (permission) => this.user.hasPermission(permission));
    }

    getSidebarWidth() {
        if (this.isCollapsed) {
            if (this.isMobile) {
                return '0px';
            } else {
                return '60px';
            }
        } else {
            if (this.isMobile) {
                return '100%';
            } else {
                return '260px';
            }
        }
    }

    openHelpDoc() {
        window.open('http://213.202.126.132:8080/portal/kb/local/1');
    }
}
