<div class="modal-header">
    <h4 class="modal-title">{{ 'Choose Sign Certificate' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close(false)">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <app-loader [isBusy]="false">
        <ng-template>
            <div class="row">
                <div class="col">
                    <kendo-grid
                        [data]="availableCertificates"
                        [sortable]="false"
                        [selectable]="selectableSettings"
                        [kendoGridSelectBy]="selectionCallback"
                        [selectedKeys]="selected"
                        [height]="300"
                    >
                        <kendo-grid-column field="serialNumber" width="300" [title]="'Serial Number' | translate"> </kendo-grid-column>
                        <kendo-grid-column field="subjectName" width="150"  [title]="'Name' | translate"></kendo-grid-column>
                        <kendo-grid-column field="issuerName"  width="150" [title]="'Issuer' | translate"></kendo-grid-column>
                        <kendo-grid-column
                            width="175"
                            field="expirationDate"
                            [title]="'Expiration Date' | translate"
                            [format]="{ date: 'dd.MM.yyyy' }"
                        ></kendo-grid-column>
                        <kendo-grid-column width="100" title="{{ 'Is Valid' | translate }}">
                            <ng-template let-row kendoGridCellTemplate>
                                <input kendoCheckBox type="checkbox" [checked]="row.isValid" />
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-checkbox-column width="200"  title="{{ 'Select Certificate' | translate }}"></kendo-grid-checkbox-column>

                        <ng-template kendoGridNoRecordsTemplate>
                            <p>{{ 'No records available' | translate }}.</p>
                        </ng-template>
                    </kendo-grid>
                </div>
            </div>
        </ng-template>
    </app-loader>
</div>
<div class="modal-footer">
    <button class="btn btn-sm btn-secondary" (click)="activeModal.close(false)" [disabled]="isBusy" translate>Cancel</button>
    <button class="btn btn-sm btn-primary"  (click)="signXML()" [disabled]="isBusy || this.selected.length == 0" translate>Sign</button>
</div>
