import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from '../../models/User.model';
import { ModalService, ModalWidth } from '../../services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { some } from 'lodash-es';
import { UserService, currentUserSubject } from '../../services/user.service';
import { MenuItem } from '../../models/common/MenuItem';
import { EnvironmentNames } from '@common/knownTypes/EnvironmentNames';
import { environment } from 'environments/environment';
import { isMobile } from '@common/utils/isMobile';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { filter, tap } from 'rxjs/operators';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { ToastrService } from 'ngx-toastr';
import { ChangeLanguageHeaderComponent } from '../change-language-header/change-language-header.component';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Output() toggleSidebar = new EventEmitter();
    user: UserModel;
    dark: boolean;

    buildInfo = environment.name;
    displayBuildInfo = environment.name !== EnvironmentNames.PRODUCTION;

    isMobile = isMobile();

    profileMenuItems = [
        'account', 'sign-out'
    ];

    userLoggedOutMenuItems = [
        {language: 'en', text: marker('EN - English')},
        {language: 'bg', text: marker('BG - български')},

    ];

    constructor(
        private router: Router,
        private dialogService: ModalService,
        private translateService: TranslateService,
        private userService: UserService,
        private renderer: Renderer2,
        private kendoDialog: DialogService,
        private toastyService: ToastrService
    ) {
        currentUserSubject.subscribe((user) => {
            this.user = user;
        });
    }

    toggleDarkMode() {
        this.dark = !this.dark;
        if (this.dark) {
            this.renderer.removeClass(document.body, 'lightTheme');
            this.renderer.addClass(document.body, 'darkTheme');
            localStorage.setItem('dark', JSON.stringify(this.dark));
        } else {
            this.renderer.removeClass(document.body, 'darkTheme');
            this.renderer.addClass(document.body, 'lightTheme');
            localStorage.setItem('dark', JSON.stringify(this.dark));
        }
    }

    ngOnInit () {
        this.dark = false;
        this.translateService.use(localStorage.getItem('language') || 'en');
        if (environment.name === EnvironmentNames.STAGING) {
            this.buildInfo = 'TEST';
        }
    }

    get currentLanguage() {
        return this.translateService.currentLang;
    }

    onToggleSidebar() {
        this.toggleSidebar.emit();
    }

    login() {
        this.router.navigate(['/login']);
    }

    logout() {
        this.dialogService
            .confirm(
                this.translateService.instant(marker('Sign Out')) as string,
                this.translateService.instant(
                    marker('Are you sure you want to sign out?')
                ) as string
            )
            .pipe(filter((x) => !(x instanceof DialogCloseResult)))
            .subscribe((result) => {
                this.router.navigate(['/logout']);
            });
    }

    hasPermission(menuItem: MenuItem) {
        if (!this.user) {
            return false;
        }

        if (!menuItem.permissions || menuItem.permissions.length === 0) {
            return true;
        }

        return (
            this.user &&
            some(menuItem.permissions, (permission) =>
                this.user.hasPermission(permission)
            )
        );
    }

    async changeLanguage() {
        const changeLanguageRef = this.kendoDialog.open({
            content: ChangeLanguageHeaderComponent,
            width: ModalWidth.sm,
        });

        changeLanguageRef.content.instance.currentLanguage = this.currentLanguage;
        changeLanguageRef.content.instance.title = this.translateService.instant(marker('Change Language'));

        let languageId;

        changeLanguageRef.result
            .pipe(
                filter((res) => !(res instanceof DialogCloseResult)),
                tap((x) => (languageId = x)),
            )
            .subscribe(() => {
                this.userService.setCurrentLanguage(languageId);
                this.translateService.use(languageId.toLowerCase());
                this.toastyService.success('Language changed.');
                this.router.navigate(['/logout']);
            });
    }
}
