import { Injectable } from '@angular/core';
import { OidcSecurityService, OpenIdConfiguration, LogLevel, OidcConfigService } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';
import { UserService, currentUserSubject } from './user.service';
import { UserModel } from '@common/models/User.model';
import { isAfter, fromUnixTime } from 'date-fns';
import { BehaviorSubject, firstValueFrom, Observable, throwError } from 'rxjs';
import { HttpClient, HttpBackend, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map, tap } from 'rxjs/operators';
import { getStorageAllKeys } from '@common/utils/storage.util';
import { StateService } from 'app/singleton-services/state.service';
import { TranslateService } from '@ngx-translate/core';

export const isAuthenticatedSubject = new BehaviorSubject<boolean>(false);

@Injectable()
export class AuthenticationService {


    private _oidcEnabled = false;

    private rawHttp: HttpClient; // raw http client does not include interceptors

    constructor(
        private oidcSecurityService: OidcSecurityService,
        private oidcConfigService: OidcConfigService,
        private http: HttpClient,
        private handler: HttpBackend,
        private userService: UserService,
        private router: Router,
        private stateService: StateService,
        private translateService: TranslateService,
    ) {
        this.rawHttp = new HttpClient(handler);
    }

    public get oidcEnabled() {
        return this._oidcEnabled;
    }

    /**
     * Checks local authentication data, completes oidc authentication id state data present, check tokens.
     * @returns
     */
    async chekcAuthenticationData(): Promise<boolean> {

        // not authenticated
        let status = true;

        // oidc security check
        if(this._oidcEnabled) {
            status = await firstValueFrom(this.oidcSecurityService.checkAuth());
        }

        // check jwt status
        let jwtStatus = this.isJwtAuthenticated();

        // result status
        let result = status && jwtStatus;

        // update data to local storage and return;
        this.updateIsAuthenticatedSubject(result);
        return result;

    }

    private isJwtAuthenticated() {
        try {
            const payload = this.getAccessTokenData();

            if (!payload) {
                return false;
            }

            const expireDate = fromUnixTime(payload.exp);
            return isAfter(expireDate, new Date());
        } catch (ex) {
            console.error(ex);
            return false;
        }
    }

    private getAccessTokenData(): any {

        let token = this.getAccessToken();
        if(!!!token) {
            return null;
        }

        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    }

    attemptCertLogin(candidate: any){

        return this.http.post(`${environment.certApiUrl}/authentication/Login`, candidate);
    }

    /**
     * Loads current user from API service.
     * @returns
     */
    async loadCurrentUser(): Promise<any> {

        let signInType = this.stateService.getSignInType();
        try {
            const data = await firstValueFrom(this.http.get(`api:///authentication/getCurrentUser/${signInType}`));
            let user = null;
            if (data) {
                user = new UserModel(data);
                this.stateService.setSignInType(user.signInType);
                if (this.translateService.getBrowserLang().toLowerCase() != user.language.id.toLowerCase()){
                    this.userService.setCurrentLanguage(user.language.id);
                    this.translateService.use(user.language.id.toLowerCase());
                }
            }
            this.updateIsAuthenticatedSubject(!!data);
            this.updateCurrentUser(user);
            return user;
        } catch (error) {
            this.updateIsAuthenticatedSubject(false);
            this.updateCurrentUser(null);
            return null;
        }
    }

    async authenticateWithTokens(accessToken: string, refreshToken: string) {
        // oidc mode
        if(this._oidcEnabled) {
           throwError("Action not supported in OIDC mode.");
           return;
        }

        // local login
        this.userService.setAuthenticationTokens(accessToken, refreshToken);
        let status = await this.loadCurrentUser();
        if(!status) {
            await this.logout();
        }
        return status;

    }

    getAccessToken() {

        // oidc token
        if(this._oidcEnabled) {
            return this.oidcSecurityService.getToken();
        }

        // legacy token
        return UserService.getAccessToken();

    }

    refreshAccessToken() {

        // oidc
        if(this._oidcEnabled) {
            return this.oidcSecurityService.forceRefreshSession()
                .pipe(
                    map((response) => {
                        return response as any
                    }),
                    tap((response) => {
                        // issue, after refresh key must be cleared
                        // see: https://github.com/damienbod/angular-auth-oidc-client/issues/845
                        var refreshProgressKey = this.oidcSecurityService.configuration.configuration.clientId + "_storageSilentRenewRunning";
                        localStorage.removeItem(refreshProgressKey);
                        sessionStorage.removeItem(refreshProgressKey);
                    })
                );
        }

        // legacy
        return this.userService.refreshAccessToken();
    }

    async logout(returnUrl?: string, localLogout?: boolean) {

        // should not fail
        try {

            // execute logout
            if(this._oidcEnabled) {

                if(localLogout) {
                    this.oidcSecurityService.logoffLocal();
                } else {
                    this.oidcSecurityService.logoff();
                }

            } else {
                await this.userService.logout();
            }

        } catch(e) {}

        // clear tokens
        this.clearLocalTokens();

        // notify logout
        this.updateIsAuthenticatedSubject(false);
        this.updateCurrentUser(null);
    }

    async logoutAndRedirectToLogin(returnUrl?: string, localLogout = false) {

        await this.logout(returnUrl, localLogout);
        this.redirectToLogin(returnUrl);
        this.stateService.clearFilters();
    }

    clearUser() {
        this.clearLocalTokens();
        this.updateCurrentUser(null);
    }

    clearUserAndRedirectToLogin(returnUrl: string) {
        this.clearUser();
        this.redirectToLogin(returnUrl);
    }

    redirectToLogin(returnUrl: string) {

        if (returnUrl) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: returnUrl } });

        } else {
            this.router.navigate(['/login']);
        }
    }

    clearLocalTokens() {

        // pcs tokens
        this.userService.clearLocalTokens();

        // oidc tokens
        if(environment.oidcClient != null) {
            getStorageAllKeys().filter(x => x.startsWith(environment.oidcClient) && x != environment.oidcClient + "_authWellKnownEndPoints")
            .forEach(x => {
                localStorage.removeItem(x);
            });
        }

    }

    async configureOidc(): Promise<boolean> {

        try {

            // load basic configuration from server
            let data = await firstValueFrom(this.rawHttp.get(environment.apiUrl + '/authentication/oidc')) as any;
            if(data == null) {
                return false;
            }

            // setup oidc client
            let cfg = {
                stsServer: data.oidcAuthority,
                postLoginRoute: "/login/oidc/callback",
                redirectUrl: window.location.origin + "/login/oidc/callback",
                postLogoutRedirectUri: window.location.origin + "/login",
                ignoreNonceAfterRefresh: true,
                clientId: environment.oidcClient,
                disableRefreshIdTokenAuthTimeValidation: true,
                maxIdTokenIatOffsetAllowedInSeconds: 300,
                scope: environment.oidcScope,
                responseType: 'code',
                silentRenew: false,
                useRefreshToken: true,
                logLevel: LogLevel.Warn,
                storage: localStorage
            } as OpenIdConfiguration;
            this.oidcConfigService.withConfig(cfg);

            // set oidc enabled status
            this._oidcEnabled = true;

            return true;

        } catch (error) {
            if(error.status == 404) {
                return false;
            }
            throw error;
        }
    }

    public oidcAuthorize() {
        if(this.oidcEnabled) {
            this.oidcSecurityService.authorize();
        }
    }

    private updateIsAuthenticatedSubject(value) {
        if (isAuthenticatedSubject.getValue() !== value) {
            isAuthenticatedSubject.next(value);
        } else {
            isAuthenticatedSubject.next(value);
        }
    }

    private updateCurrentUser(user) {
        if (currentUserSubject.getValue() !== user) {
            currentUserSubject.next(user);
        } else {
            currentUserSubject.next(user);
        }
    }

}
