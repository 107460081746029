import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserModel } from '@common/models/User.model';
import { currentUserSubject } from '@common/services/user.service';
import { isMobile } from '@common/utils/isMobile';

@Component({
    template: `
        <div class="jumbotron" *ngIf="!isMobile">
            <div class="row">
                <div class="col-2">
                    <img src="../assets/img/Bulgarian-Port_logo.png" style="width: 80%;" />
                </div>
                <div class="col-4">
                    <h1 class="display-4" style="margin-top: 15px; margin-left: -30px">BulPCS</h1>
                </div>
            </div>
        </div>
        <div class="jumbotron" *ngIf="isMobile">
            <div class="row">
                <div class="col-3"><h1 class="display-4">BulPCS</h1></div>
            </div>
        </div>
    `,
})
export class HomeComponent implements OnInit {
    isMobile = isMobile();
    user: UserModel;
    
    constructor(
        private router: Router
    ) {

        currentUserSubject.subscribe((user) => {
            this.user = user;
        });

        if (this.user){
            if (!this.user.hasAnyPermission()) {
                this.router.navigate(['/profile']);
            } else {
                this.router.navigate(['/vessel-visit/visit/list']);
            }    
        } else {
            this.router.navigate(['/login']);
        }
    }

    ngOnInit() {}
}
