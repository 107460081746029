import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class UserRegistrationFormDownloadGuard implements CanActivate {
    constructor(private router: Router, private location: Location) {}
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // This route can only be reached from createUser component
        if(this.router.url === '/createUser') {
            return true
        }

        this.location.back()
        return false;
    }
}
