import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { catchError, first, debounceTime } from 'rxjs/operators';
import { throwError, Subscription, combineLatest, forkJoin, firstValueFrom } from 'rxjs';
import { UserService } from '../../services/user.service';
import { UntypedFormGroup } from '@angular/forms';
import { UserDisplayModel } from '../../../administration/models/user.display.model';
import { TranslateService } from '@ngx-translate/core';
import { UserRegistrationText } from '@common/classes/UserRegistrationText';
import { requiredValidator } from '@common/validators/required-validator';
import { CodelistDisplayModel } from '@common/models/common/CodelistDisplay.model';
import { FileRestrictions } from '@progress/kendo-angular-upload';
import _ from 'lodash';
import { AttachmentService } from '@common/services/attachment.service';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DropDownComponent } from '../form/dropdown.component';

@Component({
    selector: 'app-user-create',
    templateUrl: './user-create.component.html',
    styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent implements OnInit, OnDestroy {
    userModel: UserDisplayModel = new UserDisplayModel();
    isBusy = true;
    organizations: any = [];
    twoFactorPreferences: CodelistDisplayModel[] = [];
    languages: CodelistDisplayModel[] = [];

    roles: any = [];
    form: UntypedFormGroup;

    files: Array<any> = [];

    usernameValuesSub: Subscription;
    langSubscription: Subscription;

    userRegistrationText = new UserRegistrationText(this.translateService);

    public attachmentFileUploadRestrictions: FileRestrictions = {
        maxFileSize: 5_242_880,
        allowedExtensions: [".pdf"]
    };

    @ViewChild('organizationDropdown') organizationDropdown: DropDownComponent;

    constructor(
        private router: Router,
        private toastyService: ToastrService,
        private userService: UserService,
        private cd: ChangeDetectorRef,
        private location: Location,
        private translateService: TranslateService,
        private attachmentService: AttachmentService,
    ) {
        this.langSubscription = this.translateService.onLangChange.subscribe((event) => this.fetchOrganizations());
    }

    ngOnInit() {
        this.isBusy = true;
        this.loadAttachmentSettings();
        this.form = this.userModel.createFormGroup();
        this.form.enable();

        this.usernameValuesSub = combineLatest([
            this.form.get('firstName').valueChanges,
            this.form.get('middleName').valueChanges,
            this.form.get('lastName').valueChanges,
        ])
            .pipe(debounceTime(1000))
            .subscribe(() => this.createUserName());

        forkJoin({
            organizations: this.userService.getOrganizationsForUserCreate(),
            twoFactorPreferences: this.userService.getTwoFactorPreferences(),
            languages: this.userService.getLanguages()
        })
            .pipe(
                catchError((err) => {
                    this.isBusy = false;
                    return throwError(err);
                })
            )
            .subscribe(({ organizations, twoFactorPreferences, languages }) => {
                this.organizations = organizations;
                this.twoFactorPreferences = twoFactorPreferences;
                this.languages = languages;
                this.form.get("selectedLanguage").setValue(this.translateService?.currentLang?.toUpperCase());
            });

        this.form.get('certificateNumber').disable();
        this.form.get('twoFactorPreferenceId').valueChanges.subscribe((val) => {
            if (val == 'CERTIFICATE') {
                this.form.get('certificateNumber').enable();
                this.form.get('iddNumber').setValidators(requiredValidator());
                this.form.controls['iddNumber'].updateValueAndValidity();
                this.isBusy = false;
                return;
            }
            this.form.get('certificateNumber').disable();
            this.form.get('iddNumber').clearValidators();
            this.form.controls['iddNumber'].updateValueAndValidity();
        });

        this.form.get('requestedUserAccessLevelId').disable();
        this.form.get('organizationId').valueChanges.subscribe(async (val) => {
            if (val) {
                this.form.get('requestedUserAccessLevelId').enable();
                this.roles = [...await firstValueFrom(this.userService.getOrganizationUserRoles(val))];
            }
            else {
                this.form.get('requestedUserAccessLevelId').disable();
            }
        });
        this.isBusy = false;
    }

    fetchOrganizations() {
        this.userService.getOrganizationsForUserCreate().subscribe((organizations) => this.organizations = organizations);
        this.organizationDropdown.ngOnInit();
    }

    ngAfterContentChecked() {
        this.cd.detectChanges();
    }

    public get getIsFormDisabled() {
        return this.isBusy || this.form.value.tac == false || (!this.form.valid && this.form.dirty) || this.files.length == 0;
    }

    private loadAttachmentSettings(): void {
        this.attachmentService.getAttachmentSettingsForUpload().subscribe(response => {
            this.attachmentFileUploadRestrictions = (response as FileRestrictions);
        });
    }

    fileSelected($event) {

        if ($event.files.length > 10) {
            this.toastyService.error(this.translateService.instant(marker("Please select maximum of 10 files")));
            $event.preventDefault();
            return;
        }

        $event.files.forEach(element => {
            if (element.size > this.attachmentFileUploadRestrictions.maxFileSize) {
                this.toastyService.error(`${this.translateService.instant(marker("Attachment size invalid"))} - ${element.name}`);
                return;
            }
            if (!this.attachmentFileUploadRestrictions.allowedExtensions.some(ext => ext == element.extension)) {
                this.toastyService.error(`${this.translateService.instant(marker("Invalid attachment"))} - ${element.name}`);
                return;
            }
            if (element.extension != ".pdf") {
                this.toastyService.error("Please select only PDF files");
                $event.preventDefault();
                return;
            }
            const raw = element.rawFile;
            if (raw) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.documentOnLoad(e.target.result.toString(), raw.name);
                };
                reader.readAsDataURL(element.rawFile);
            }
        });
    }

    documentOnLoad(file: string, name: string) {

        this.files.push({ base64Content: file, base64ContentName: name });

    }

    fileRemoved($event) {

        $event.files.forEach(element => {

            const file = this.files.filter(x => x.base64ContentName == element.name);

            if (file == null || file.length == 0) return;

            this.files.splice(this.files.indexOf(file[0]), 1);
        });

    }

    save() {
        const userSaveData = {
            ...this.form.getRawValue()
        };

        if (this.files.length < 1) return;

        userSaveData.files = this.files;

        this.userService
            .createUser(userSaveData)
            .pipe(
                first(),
                catchError((err) => throwError(err))
            )
            .subscribe((data) => {
                this.toastyService.success(data.message);

                this.router.navigate(['/user-registration-form'], {
                    state: {
                        userId: data.userId,
                        firstName: this.form.get('firstName').value,
                        middleName: this.form.get('middleName').value,
                        lastName: this.form.get('lastName').value,
                        accessRequestReason: this.form.get('accessRequestReason').value,
                        requestedUserAccessLevelId: this.form.get('requestedUserAccessLevelId').value,
                    },
                });
            });
    }

    createUserName() {
        const firstName = this.form.get('firstName');
        const lastName = this.form.get('lastName');
        const middleName = this.form.get('middleName');

        if (firstName.dirty || middleName.dirty || lastName.dirty) {
            this.userService.crateUserName(firstName.value, middleName.value, lastName.value).subscribe((user) => {
                this.form.get('userName').setValue(user.userName);
            });
        }
    }

    goBack() {
        this.location.back();
    }

    ngOnDestroy() {
        this.usernameValuesSub?.unsubscribe();
        this.langSubscription?.unsubscribe();
    }
}
