import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class StateService {
  filterState = new Map<string, any>();
  signInTypeState: string;

  constructor() {}

  public updateFilter(name: string, filter: any){
    this.filterState.set(name, filter);
  }

  public setSignInType(signIntype: string){
    this.signInTypeState = signIntype;
  }

  public getFilter(name: string){
    return this.filterState.get(name);
  }

  public getSignInType(){
    return this.signInTypeState;
  }

  public clearFilters() {
    this.filterState.clear();
  }
}
