import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ModalService } from '@common/services/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-language-header',
  templateUrl: './change-language-header.component.html',
  styles: []
})
export class ChangeLanguageHeaderComponent implements OnInit {
  public title: string;
  public message: string;
  public yesButtonName: string;
  public noButtonName: string;
  public cancelButtonName: string;
  public serviceRequestId: number;
  currentLanguage: string;

  form: UntypedFormGroup = new UntypedFormGroup({
      languageId: new UntypedFormControl({ value: null, disabled: false }),
  });

  constructor(public activeModal: DialogRef, private dialogService: ModalService, private translateService: TranslateService) {}

  ngOnInit() {
      this.form.get('languageId').patchValue(this.currentLanguage?.toUpperCase() == 'EN' ? 'BG' : 'EN');
  }

  clear(data: any) {
      this.form.reset();
  }

  save() {
      const { languageId } = this.form.getRawValue();
      this.activeModal.close(languageId);

  }

}
