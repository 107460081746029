import { EnvironmentNames } from "@common/knownTypes/EnvironmentNames";
import { environment as base } from './environment.base';
import * as _ from 'lodash';

const baseEnv = _.cloneDeep(base);

export const environment = _.merge(baseEnv, {
    production: true,
    name: EnvironmentNames.DEVELOP,
});
